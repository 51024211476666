import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { history } from '../../_helpers';
import { authActions } from '../../_store';
import { login } from '../../actions/auth';
import FormHeader from '../../components/FormHeader';
import { Trans } from 'react-i18next';
import YupPassword from 'yup-password';
import { t } from 'i18next';
import ResetPAsswordModal from './ResetPasswordModal';
import { config } from '../../constants/config';
import axios from 'axios';

const Login = () => {
    const { isLoggedIn } = useSelector(state => state.auth);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const emailRef = useRef();
    YupPassword(Yup);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    let msg = searchParams.get("msg")
    useEffect(() => {
        // redirect to home if already logged in
        if (isLoggedIn) history.navigate('/dashboard');
    }, []);


    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Must be and valid email').required('Email is required'),
        password: Yup.string().password().required('Password is required')
    });



    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    // const { register, handleSubmit, formState } = useForm(formOptions);
    const { register, handleSubmit, formState } = useForm(formOptions);


    const { errors, isSubmitting } = formState;

    async function onSubmit({ email, password }) {
        setMessage("")

        return dispatch(login({ email, password })).then(() => {
            logInFntec();
            history.navigate("/user");
        })
            .catch((error) => {
                setSuccessful(false);
                setMessage(error);
            });
    }

    const logInFntec = async () => {
        try {
            // Les données à envoyer
            const data = {
                phone: config.fntec.phone,
                password: config.fntec.password
            };

            // Envoi de la requête POST
            const response = await axios.post(`${config.app.fntec_url}/auth/login`, data);

            // Récupération du access_token de la réponse
            const accessToken = response?.data?.access_token;
            // Sauvegarde du token dans le localStorage
            localStorage.setItem('fntec_token', accessToken);
            console.log('Access Token:', accessToken);

            // Vous pouvez maintenant utiliser le access_token comme nécessaire
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
        }
    };

    return (
        <>
            <Header />

            <div className="content p-0 m-md-3 ">
                <div className="container mb-5">

                    <FormHeader />



                    <div className="row justify-content-center ">

                        <div className="col-md-6 border shadow my-4 bg-forms py-3">
                            <div className="mb-4 text-center">
                                <h3><Trans i18nKey="forms.signIn" > {t('signIn')} </Trans></h3>
                                {message && (
                                    <div className="form-group">
                                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}


                            </div>
                            <hr></hr>

                            <form onSubmit={handleSubmit(onSubmit)} className=' p-2 p-md-3 '>
                                {msg === "successResetPwd" && (<div className='alert alert-success text-center shadow'> <i className='bi bi-info-circle'></i> {t('resetPasswordIndicator')}</div>)}
                                <div className="form-group first  my-2">
                                    <label>{t('forms.email')} </label>

                                    <input type="text" {...register('email')} placeholder={t('forms.email')} name="email" className={errors.email?.message ? "form-control border-danger" : "form-control"} id="email" />
                                    <div className="text-danger">{errors.email?.message}</div>
                                    {/* {errors.email && <p className="text-danger">Please {errors.email?.type === 'required' ? "This field is required" : "Max lenght =30 "}</p>} */}
                                </div>
                                <div className="form-group last mb-4 my-2">
                                    <label>{t('forms.pwd')} </label>

                                    <input type="password" {...register('password')} placeholder={t('forms.pwd')} name="password" className={errors.password?.message ? "form-control border-danger" : "form-control"} id="password" />

                                    <div className="text-danger">{errors.password?.message}</div>
                                    <div className='alert alert-warning my-2 rounded shadow text-center'> <i className="bi bi-info-circle fs-6"></i> {t('passwordIndication')}</div>
                                </div>

                                {isSubmitting && <div id="preloader"></div>}

                                <div className='row justify-content-center'>
                                    <div className='col-8 text-center my-4 '>
                                        <input type="submit" value={t('forms.login')} className={Object.values(errors).length === 0 ? "btn btn-block bg-warning text-white" : "btn btn-outline-danger disabled"} />

                                    </div>
                                </div>

                            </form>
                            <div className="d-flex mt-3 align-items-center ">
                                <ResetPAsswordModal />

                                <span className="ml-auto"><Link to="/register" className="forgot-pass text-white"> <Trans i18nKey="forms.notR">Not Register </Trans>  </Link></span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Footer />
        </>
    )
}

export default Login;