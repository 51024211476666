import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    VERIFY_SUCCESS,
    VERIFY_FAIL,
} from "./types";

import AuthService from "../services/auth.service";
import { history } from "../_helpers";
import { toast } from "react-hot-toast";
import {Mtoast} from "../components/utils/toast";

export const register = ({ name, email, password, confirm_password }) => (dispatch) => {
    return AuthService.register({ name, email, password, confirm_password }).then(
        (response) => {
            if (response.data.success) {
                // dispatch({
                //     type: REGISTER_SUCCESS
                // });

                // dispatch({
                //     type: SET_MESSAGE,
                //     payload: [response?.data?.message, true],
                // });

                return Promise.resolve(true);
            } else {
                dispatch({
                    type: REGISTER_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: [response?.data?.message, false],
                });

                return Promise.resolve(false);
            }
        },
        (error) => {
            console.log("ERROR ", error)
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: [msg, false],
            });

            dispatch({
                type: REGISTER_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const verify = ({ email, code }) => (dispatch) => {
    return AuthService.verify({ email, code }).then(
        (response) => {
            console.log('Response : ', response)
            if (response.data.success) {
                dispatch({
                    type: VERIFY_SUCCESS
                });
            } else {
                console.log(response?.data);
                dispatch({
                    type: VERIFY_FAIL,
                });
            }

            dispatch({
                type: SET_MESSAGE,
                payload: response?.data?.message,
            });

            return Promise.resolve();
        },
        (error) => {
            console.log(error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: VERIFY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const login = ({ email, password }) => (dispatch) => {
    return AuthService.login({ email, password }).then(
        (data) => {
            if (data.data.success) {
                Mtoast(`Welcome  ${data?.data?.data.user.name} !`)

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data?.data?.data },
                });
            } else {
                dispatch({
                    type: LOGIN_FAIL,
                });


                dispatch({
                    type: SET_MESSAGE,
                    payload: [data?.data?.message, false],
                });
            }

            return Promise.resolve();
        },
        (error) => {
            console.log('Response 1 : ', error.response);

            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: [message, false],
            });

            return Promise.reject(message);
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};