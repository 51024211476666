import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, NavLink, Link } from "react-router-dom";

import { Nav } from "react-bootstrap";

import { t } from "i18next"
import axios from "axios";
import { ACCOUNT_STATUS } from "../../GLOBAL_VARIABLE";


function Sidebar({ color, image, routes }) {
  const { user: currentUser } = useSelector((state) => state.auth);
  const location = useLocation();
  const [compte, setCompte] = useState({});
  const [links, setLinks] = useState([]);

  const API_URL = `${process.env.REACT_APP_API_URL}/`;

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const fetchCompte = () => {
    axios
      .get(API_URL + "comptes" + "/" + currentUser?.user?.compte_id, {
        headers: {
          "Authorization": `Bearer ${currentUser.token}`
        }
      })
      .then((response) => {

        if(response.data.data.status <= ACCOUNT_STATUS.SUBMITTED){
          setLinks(routes.filter((item)=> item.status === ACCOUNT_STATUS.SUBMITTED));
        }else{
          setLinks(routes)
        }
        setCompte(response.data.data);
        console.log("Compte ", response.data.data)
      })
      .catch((e) => {
        setCompte(null);
      });
  }

  useEffect(() => {
    fetchCompte();
  }, [currentUser]);

  return (
    // <div className="sidebar" data-image={image} data-color={color}>

    // <div className="sidebar" >
    <div className="sidebar" >

     
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <Link
            to="/"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              <img src={require("../../assets/img/logo.jpeg")} alt="..." />
            </div>
          </Link>
          <Link className=" h3 text-dark" to="/">
            eWorkPermit
          </Link>
        </div>
        <Nav>
          {links.map((prop, key) => {
            if ((prop.path != "/form" && prop.path != "/demandes-by-employee") && !prop.redirect)
              return (
                <li
                  // className={
                  //   prop.upgrade
                  //     ? "active active-pro"
                  //     : activeRoute(prop.path)
                  // }
                  className={prop?.tour}
                  key={key}
                >
                    <NavLink
                    to={prop.path}
                    className={ `${compte && compte.status <= 0 &&  (prop.name == "New Visa Application" || prop.name == "Dashboard") ? 'd-none' : 'nav-link'}`}
                    // className= 'nav-link text-dark'
                    activeClassName="active"
                    >
                      <i style={{color: 'black'}} className={`${prop.icon}`} />
                      <p >{t("dashboardRoute." + prop.name)}</p>
                    </NavLink>
                </li>
              );
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
