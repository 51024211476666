import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import dashboardRoutes from "../routes";

import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";
import UseCinetPay from "../hooks/useCinetPay";
import ModalFormStartPay from "./ModalFormStartPay";
import { ACCOUNT_STATUS, DEMANDE_STATUS, encrypt } from "../GLOBAL_VARIABLE";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import useDataFetching from "../hooks/useDataFetching";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
const API_URL = `${process.env.REACT_APP_API_URL}/`;

function Dashboard() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n?.language;

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [successful, setSuccessful] = React.useState(false);
  const mainPanel = React.useRef(null);
  const [demandes, setDemandes] = React.useState([]);
  const [demandesF, setDemandesF] = React.useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [compte, setCompte] = useState({});
  const [query, setQuery] = useState("");
  const [visa, setVisa] = useState(null);
  const navigate = useNavigate()
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    location?.state?.employer && setQuery(location?.state?.employer?.passportNumber?.trim())
  }, [location]);

  const fetchDemande = () => {
    setSuccessful(true);
    axios
      .get(API_URL + "demandeVisas" + "/user/" + currentUser?.user?.id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        // console.log("List of demandes : ", response.data.data.data)
        setSuccessful(false);
        setDemandes(response.data.data);
      })
      .catch((e) => {
        setSuccessful(false);
        setDemandes(null);
      });
  };

  React.useEffect(() => {
    fetchDemande();
  }, [currentUser]);

  const handleClick = (url, filename) => {
    console.log(url);
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "application.pdf";
        alink.click();
      });
    });
  };
  // const [loading, errorp, startPayment] = UseCinetPay();

  // const startPay = (item) => {
  //   startPayment({
  //     amount: item.salary * 2,
  //   });
  // };

  //   fetch visa by passportNumber
  const [loading, error, demandesVisas, fetchData] = useDataFetching(
    `${API_URL}/demandeVisas/${query}/demandes`
  );

  useEffect(() => {
    if (demandes) {
      // alert('yes')
      if (query) {

        setDemandesF(demandes?.filter((d) => { return d?.numeroPasseport?.toLowerCase()?.includes(query?.toLowerCase()) }))
      } else {
        setDemandesF(demandes)
      }

    }
  }, [query, demandes]);

  const fetchCompte = () => {
    axios
      .get(API_URL + "comptes" + "/" + currentUser?.user?.compte_id, {
        headers: {
          "Authorization": `Bearer ${currentUser.token}`
        }
      })
      .then((response) => {
        if (response.data.data.status != ACCOUNT_STATUS.VALIDATED) {
          navigate("/user");
        }
      })
      .catch((e) => {
        navigate("/user");
      });
  }

  useEffect(() => {
    fetchCompte();
  }, [currentUser]);

  const confirmCanceled = () => {
    if (visa != null) {
      let formdata = new FormData();
      formdata.append("demande_id", visa?.demande_id);
      setLoad(true);
      axios
        .post(API_URL + "demandeVisas/cancel/" + visa?.id, formdata, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${currentUser.token}`,
          },
        })
        .then((response) => {
          console.log("response", response);
          toast.success(response?.data?.message);
          setOpen(false)
          fetchDemande()
        })
        .catch((e) => {
          toast.error(e?.code == 'ERR_NETWORK' ? t('Problème de conexion') : "Sorry and error occured. Please try against");
        })
        .finally(() => setLoad(false))
        ;
    } else {
      toast.error("Please choose a application");
    }
  }


  return (
    <>
      <div className="wrapper">
        <Sidebar
          color={color}
          image={hasImage ? image : ""}
          routes={dashboardRoutes}
        />
        <div className="main-panel" ref={mainPanel}>
          <Header />
          {successful ? <div id="preloader"></div> : <> </>}
          <div className="content">
            <Container fluid style={{ overflow: "auto", minHeight: "" }}>
              {/* <ModalFormStartPay/> */}
              <div className="d-flex flex-row justify-content-end">
                <TextField
                  autoFocus
                  margin="dense"
                  id="contratNumber"
                  name="contratNumber"
                  placeholder={t("Entrer le numéro de passeport")}
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  sx={{ width: '30%', backgroundColor: 'white' }}
                  size="small"
                  variant="outlined"
                // {...register("contratNumber")}
                // error={errors.contratNumber?.message ? true : false}
                />
                {/* {console.log("demandes ==> ", demandesVisas)} */}
              </div>
              <h4 className="text-center text-uppercase">
                {t("dashboard.title")} <span className="badge bg-info">{demandesF?.length}</span>
              </h4>

              <div class="table-responsive ">
                <table
                  id="example"
                  className="table align-items-center mb-0 shadow rounded"
                // style={{ border: "3px black" }}
                >
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {" "}
                        {t("dashboard.table.appID")}{" "}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {" "}
                        {t("dashboard.table.lastName")}{" "}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.firstName")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.pn")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.nat")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.sDate")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.status")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.action")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        Word
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {demandesF &&
                      demandesF.map((visa) => {
                        return (
                          <tr>
                            <td>{visa.demande_id}</td>
                            <td className="text-xs font-weight-bold mb-0">
                              {visa.nom}
                            </td>
                            <td>{visa.prenom}</td>
                            <td>{visa.numeroPasseport}</td>
                            <td>{visa.nationalite}</td>
                            <td>
                              {new Date(visa.created_at)
                                .toISOString()
                                .replace(/T/, " ")
                                .replace(/\..+/, "")}
                            </td>
                            <td className="text-uppercase">

                              {visa.status ==
                                DEMANDE_STATUS.ICOMPLETE.value ?
                                <Button className="btn-danger" onClick={() => navigate("/form/" + encrypt(visa?.id))}>

                                  {currentLanguage == 'en' ? DEMANDE_STATUS.ICOMPLETE.labelEn : DEMANDE_STATUS.ICOMPLETE.label}
                                </Button>
                                :

                                Object.keys(DEMANDE_STATUS).map((item) => {
                                  return (
                                    visa.status !==
                                    DEMANDE_STATUS.VALIDATED.value &&
                                    visa.status ===
                                    DEMANDE_STATUS[item].value && (
                                      <span
                                        className={DEMANDE_STATUS[item].className}
                                      >
                                        {" "}
                                        {currentLanguage == 'en' ? DEMANDE_STATUS[item].labelEn : DEMANDE_STATUS[item].label}
                                      </span>
                                    )
                                  );
                                })}
                              {visa.status ===
                                DEMANDE_STATUS.VALIDATED.value && (
                                  <ModalFormStartPay data={visa} endFetch={fetchDemande} />
                                )}
                            </td>
                            <td>
                              <Link to={`/demandes/${encrypt(visa.id)}`}>
                                <Button
                                  className="btn-fill pull-right  shadow border-0"
                                  type="submit"
                                  variant="warning"
                                >
                                  <i className="bi bi-eye"></i>
                                </Button>
                              </Link>
                            </td>
                            <td>
                              {visa.status != DEMANDE_STATUS.ICOMPLETE.value && visa.status != DEMANDE_STATUS.CANCELED.value ?
                                <a
                                  className="btn btn-fill pull-right  shadow border-0"
                                  variant="info"
                                  target="_blank"
                                  href={visa?.doc}
                                  download={visa?.demande_id}
                                >
                                  <i className="bi bi-download"></i>
                                </a>
                                : 
                                (<></>)
                              }

                              {visa.status == DEMANDE_STATUS.ICOMPLETE.value &&
                                <Button className="btn-danger" onClick={() => {
                                  setVisa(visa);
                                  setOpen(true);
                                }}>
                                  {currentLanguage == 'en' ? DEMANDE_STATUS.CANCELED.labelEn : DEMANDE_STATUS.CANCELED.label}
                                </Button>
                              }
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr></tr>
                  </tfoot>
                </table>
              </div>
            </Container>
          </div>
          <Footer />
        </div>
      </div>
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("dashboard.table.canceled.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("dashboard.table.canceled.text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("dashboard.table.canceled.disagree")}</Button>
          <Button onClick={confirmCanceled} disabled={load}>
            {!load ? t("dashboard.table.canceled.agree") : "Loading..."}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Dashboard;
