import React, { Component, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../actions/auth";
import { authActions, userActions } from "../_store";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { t } from "i18next";


const Header = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

 
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const changeLanguage = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
    window.location.reload(false);
  };

  React.useEffect(()=>{
    
      const mobileNavShow = document.querySelector('.mobile-nav-show');
      const mobileNavHide = document.querySelector('.mobile-nav-hide');
      
      document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
    el.addEventListener('click', function (event) {
      event.preventDefault();
      mobileNavToogle2(event);
    })
  });

  function  mobileNavToogle2(e){
if(e.target.classList[1] ==='mobile-nav-show' ){
    document.querySelectorAll('.navbar ul')[0].style.display='block'
    // mobileNavShow.style.display='none'
    mobileNavShow.classList.add('d-none')
    // console.log("la croix : ", mobileNavHide)
    mobileNavHide.classList.remove('d-none')

}else{
    document.querySelectorAll('.navbar ul')[0].style.display='none'
    // mobileNavShow.style.display='block'
    mobileNavShow.classList.remove('d-none')
    // mobileNavHide.style.display='none'
    mobileNavHide.classList.add('d-none')


}
  }

  

  /**
   * Hide mobile nav on same-page/hash links
   */
  document.querySelectorAll('#navbar a').forEach(navbarlink => {

    if (!navbarlink.hash) return;

    let section = document.querySelector(navbarlink.hash);
    if (!section) return;

    navbarlink.addEventListener('click', () => {
      if (document.querySelector('.mobile-nav-active')) {
        // mobileNavToogle();
      }
    });

  });

  /**
   * Toggle mobile nav dropdowns
   */
  const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');

  navDropdowns.forEach(el => {
    el.addEventListener('click', function (event) {
      if (document.querySelector('.mobile-nav-active')) {
        event.preventDefault();
        this.classList.toggle('active');
        this.nextElementSibling.classList.toggle('dropdown-active');

        let dropDownIndicator = this.querySelector('.dropdown-indicator');
        dropDownIndicator.classList.toggle('bi-chevron-up');
        dropDownIndicator.classList.toggle('bi-chevron-down');
      }
    })
  });
  })

  return (
    <header id="header" class="header d-flex align-items-center">

    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
    
        <Link to="/" className="logo d-flex align-items-center"> 
          <img src="assets/img/logo.jpeg" alt="" />
          <h1 className="text-left fs-6 text-left">
            {/* eWorkPermit<span>.</span> */}
            <Trans i18nKey="header.title1">
              MINISTRY OF EMPLOYMENT
              <p style={{ fontWeight: "bold" }}> AND VOCATIONAL TRAINING </p>
            </Trans>
            {/* {t("header.title1")} */} 
          </h1> 
        </Link> 

        <nav id="navbar" class="navbar">  
          <ul>
            <li>
              <Link to="/">
                <Trans i18nKey="links.home"> Home </Trans>
              </Link>
            </li>
            <li>
              <Link to="/news">
                <Trans i18nKey="links.blog">Blog </Trans>{" "}
              </Link>
            </li>
            <li>
              <Link to="/contactUs">
                {" "}
                <Trans i18nKey="links.contact"> Contact </Trans>{" "}
              </Link>
            </li>
            {!isLoggedIn && (
              <>
               <li>
                <Link to="/login" className="btn-get-started step1">
                  {" "}
                  <Trans i18nKey="links.login"> Login </Trans>
                </Link>
              </li>
              <li>
                <Link to="/register" className="btn-get-started  step2">
                  {" "}
                  <Trans i18nKey="links.register"> Register </Trans>
                </Link>
              </li>
              </>
            )}
            {/* {isLoggedIn && <li><Link to="/dashboard" className="btn-get-started">{currentUser?.user?.name}</Link></li>} */}
            {isLoggedIn && (
              <li class="dropdown">
                <a href="#">
                  <span>{currentUser?.user?.name}</span>{" "}
                  <i class="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li>
                    <Link onClick={logOut} className="btn-get-started">
                      Log out
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <li>
              <select
                className="form-select shadow"
                onChange={changeLanguage}
                value={i18n.language}
              >
                <option value="en">En </option>
                <option value="fr">Fr</option>
              </select>
            </li>
       
          </ul>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list"> </i>
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
        </nav>
       
      </div>
    </header>
  );
};
export default Header;
