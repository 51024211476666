import { t } from "i18next";

export function checkIsValidPermit(emp) {
  // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", emp?.permitNumber?.length)
  // console.log("🚀 ~ checkIsValidPermit ~ getExpiredPermitDate(permitDate):", getExpiredPermitDate(permitDate))
  if (emp?.hasPermit === false) {
    return {isValid : false, message : t('Not Available')}
  }
  if (emp?.permitDate == null || emp?.permitDate == undefined || emp?.permitDate?.length==0) {
    return {isValid : false, message : t("Date d'obtention du permis non fourni")}
  }
  if( emp?.permitNumber==null || emp?.permitNumber==undefined || emp?.permitNumber?.length==0){
    return {isValid : false, message : t('Numéro de permis non fourni')}

  }
  return new Date() < getExpiredPermitDate(emp?.permitDate) ? {isValid : true , message : t('Valide')} : {isValid : false , message : t('Expiré')}
}

export function getExpiredPermitDate(permitDate) {
  return new Date(new Date(permitDate).setFullYear(new Date(permitDate).getFullYear() + 2))
}

export function formatMoney(number){

  return   number?.toLocaleString()
}