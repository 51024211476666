export const PREVIEW_VARIABLES = {

    HEADER: {
        HEADER1: "MINISTERE DE L’EMPLOI ET FORMATION PROFESSIONNELLE",
        HEADER2: "MINISTRY OF EMPLOYMENT AND VOCATIONAL TRAINING",
        HEADER3: "WorkPermit APPLICATION FORM",
    },

    // DETAILS DU PASSPORT, VISA ET DU VOYAGE / PASSPORT,VISA AND TRAVEL DETAILS
    DETAILS_DU_PASSPORT: {
        NUM_PASSPORT: {
            FR: "Numéro du Passport",
            EN: "Passport Number"
        },
        TYPE_PASSPORT: {
            FR: "Type de Passport",
            EN: "Passport Type"
        },
        DATE_EMIS: {
            FR: "Date d’émission",
            EN: "Date of issue"
        },
        LIEU_EMIS: {
            FR: "Lieu d’émission",
            EN: "Place of issue"
        },
        DATE_EXP: {
            FR: "Date d'expiration",
            EN: "Expiration Date"
        }
    },
    DETAILS_DU_VISA: {
        NUM_VISA: {
            FR: "Numéro du Visa",
            EN: "Visa Number"
        },
        VISA_PERPOSE: {
            FR: "Motif du Visa",
            EN: "Visa Perpose"
        },
        TYPE_VISA: {
            FR: "Type de Visa",
            EN: "Visa Type"
        },
        TYPE_ENTREE: {
            FR: "Type d'entrée",
            EN: "Type of Entry"
        },
        DATE_DEPART: {
            FR: "Date de délivrance",
            EN: "Visa issue Date"
        },
        DATE_RETOUR: {
            FR: "Date d’expiration",
            EN: "Visa Expiry date"
        },
    },
    DETAILS_DU_VOYAGE: {
        PORT_OF_ENTRY: {
            FR: "Moyen d'entré",
            EN: "Port of Entry"
        },
        DATE_ARRIVE: {
            FR: "Date d'arrivé",
            EN: "Arrival Date"
        },
        TEMPS_SEJOUR: {
            FR: "Temps de Séjour",
            EN: "Maximun Length of Stay"
        },
        ADDRESSE: {
            FR: "Adresse au Cameroun",
            EN: "Address in Cameroon"
        },
        DESTINATIONS: {
            FR: "Destinations",
            EN: "Destinations"
        },
        MODE_TRANSPORT: {
            FR: "Modes de Transport",
            EN: "Modes of Transportation"
        },



        numero_cs: {
            FR: "Numéro de la carte de séjour",
            EN: "Residence card number"
        },

        date_obtention_cs: {
            FR: "Date d'obtention  de la carte de séjour",
            EN: "Date of obtaining the residence permit"
        },

        date_expiration_cs: {
            FR: "Date d'expiration de la carte de séjour",
            EN: "Expiry date of the residence card"
        },
    },
    // ADDRESS, CONTACT AND EMERGENCY CONTACT DETAILS
    ADRESSE_PERMANANTE: {
        HOUSE_NUMBER: {
            FR: "No de Maison Permanente",
            EN: "Street"
        },
        ADRESSE1: {
            FR: "Adresse 1",
            EN: "Address 1"
        },
        ADRESSE2: {
            FR: "Adresse 2",
            EN: "Address 2"
        },
        VILLE: {
            FR: "Ville",
            EN: "City"
        },
        REGION: {
            FR: "Etat/Region ",
            EN: "State/Region"
        },
        CODE_POSTAL: {
            FR: "Zip / Code Postal",
            EN: "Zip / Postal Code"
        },
        PAYS: {
            FR: "Pays",
            EN: "Country"
        },
        NUM_TEL: {
            FR: "Numéro de Téléphone",
            EN: "Phone Number"
        }
    },
    DETAILS_DU_CONTACT: {
        EMAIL: {
            FR: "Email",
            EN: "Email"
        },
        NUM_TEL: {
            FR: "Numéro de Téléphone",
            EN: "Phone Number"
        }
    },
    CONTACT_URGENCE: {
        PRENOM: {
            FR: "Prenom",
            EN: "Full Name"
        },
        EMAIL: {
            FR: "Email",
            EN: "Email"
        },
        NUM_TEL: {
            FR: "Numéro de Téléphone",
            EN: "Phone Number"
        }
    },

    // PERSONAL, JOB'S AND SALARY DETAILS
    PERSONAL_INFORMATIONS: {
        PRENOM: {
            FR: "Prenom",
            EN: "Surname"
        },
        NOM: {
            FR: "Nom",
            EN: "Given Name"
        },
        DATE_NAISS: {
            FR: "Date de Naissance",
            EN: "Date Of Birth"
        },
        LIEU_NAISS: {
            FR: "Lieu de Naissance",
            EN: "Palce Of Birth"
        },
        GENRE: {
            FR: "Genre",
            EN: "Gender"
        },
        ETAT_CIVIL: {
            FR: "Etat Civil",
            EN: "Marial Status"
        },
        NATIONALITY: {
            FR: "Nationalité",
            EN: "Nationality"
        },
        NB_ENFANTS: {
            FR: "Nombre d'enfants",
            EN: "Number of Children"
        },
        LANG_MATERNELLE: {
            FR: "Langue Maternelle",
            EN: "Native Language"
        }
    },
    JOB_DETAILS: {
        INTITULE_DU_POSTE: {
            FR: "Intitulé du Poste",
            EN: "Job Title"
        },
        DUREE_CONTRAT: {
            FR: "Fin du Contrat",
            EN: "Contract End"
        },
        UNIT: {
            FR: "Unité de la Durée du Contrat",
            EN: 'Unit'
        }
    },
    SALARY_DETAILS: {
        DATE_EFFECT_CONTRAT: {
            FR: "Date Effective du Contrat",
            EN: "Contract Effective Date"
        },
        SALAIRE_BRUTE: {
            FR: "Salaire Brute Mensuel",
            EN: "Monthly Gross Salary"
        },
        CONFIRM_SALARY: {
            FR: "Confirmation du Salaire Brute Mensuel",
            EN: "Confirmation of Monthly Gross Salary"
        },
        DEVISE: {
            FR: "Devise",
            EN: "Currency"
        },
        BASE_SALARY: {
            FR: "Salaire de Base",
            EN: "Base Salary"
        },
        indLogement: {
            FR: "Indemnité de logement",
            EN: "Housing allowance"
        },
        indTransport: {
            FR: "Indemnité de transport",
            EN: "Transport allowance"
        },
        indResponsabilite: {
            FR: "Prime de reponsabilité",
            EN: "Responsibility bonus"
        },
        indRepresentation: {
            FR: "Prime de représentation",
            EN: "Representation bonus"
        },
        primeExploitation: {
            FR: "Prime d'expatriation",
            EN: "Expatriation bonus"
        },
        sursalaire: {
            FR: "Sursalaire",
            EN: "Surcharge "
        },
        primeSalissure: {
            FR: "Prime de salissure",
            EN: "Dirty bonus"
        },
        indCaisse: {
            FR: "Indenmité de caisse",
            EN: "Cash indemnity"
        },
        bonus: {
            FR: "Bonus",
            EN: "Bonus"
        },
        primePanier: {
            FR: "Prime de panier",
            EN: "Shopping cart bonus (in XAF)"
        },

        billetAvion: {
            FR: "Prime de panier",
            EN: "Billet d'avion "
        },
        autresPrimes: {
            FR: "Autres primes",
            EN: "Other primes (in XAF)"
        },

    },
    FOOTER:
    {
        FR: "Veuillez imprimer le formulaire de demande, joindre toutes les pièces justificatives et le soumettre à votre délégation du MINEFOP ou au Ministère de l’Emploi et de la Formation Professionnelle, Nlongkak, Yaoundé.",
        EN: "Please print the application form, attach all supporting documents and submit to your local MINEFOP Delegation or at the Ministry of Employment and Vocational Training, Nlongkak, Yaoundé."
    },
}