import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useDataFetching from "../../../hooks/useDataFetching";
import { useLocation, useNavigate } from "react-router-dom";
import { config } from "../../../constants/config";

const PassportNumberModal = ({ handleClose, open, setEmployer }) => {
  const validationSchema = Yup.object().shape({
    passportNumber: Yup.string().required("Passport Number is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  // const {register, handleSubmit, formState} = useForm(formOptions);
  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors, isSubmitting } = formState;

  const [pNumber, setPnumber] = useState("");

  const location = useLocation();
  const navigate = useNavigate()

  //   fetch passportNumber
  const [loading, error, employer, fetchData] = useDataFetching(
    pNumber != "" &&
      `${config.app.fntec_url}/employers/get-by-passport/${pNumber}`
  );

  const handleSend = ({ passportNumber }) => {
    setPnumber(passportNumber);
  };

  useEffect(() => {}, [pNumber, employer]);
  useEffect(() => {
    if (employer) {
      setEmployer(employer?.data);
    }
  }, [employer]);
  useEffect(() => {
    /* PA0558884CM */
  }, [error]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
      >
        <form onSubmit={handleSubmit(handleSend)}>
          <DialogTitle>Entrez le numero de passport</DialogTitle>
          {error?.length > 0 && (
            <div className="alert alert-danger text-center" role="alert">
              Numero de passport invalide
            </div>
          )}

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="passportNumber"
              label="Passport Number"
              type="text"
              fullWidth
              variant="outlined"
              {...register("passportNumber")}
              error={errors.passportNumber?.message ? true : false}
            />
            <div className="text-danger">{errors.passportNumber?.message}</div>
          </DialogContent>
          <DialogActions className="justify-content-between">
            <Button onClick={() => {
                navigate('/foreignWorkers')
            }}>Annuler</Button>

            {pNumber != "" && loading ? (
              <div class="spinner-border text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              <Button type="submit" class="btn btn-outlined btn-success mt-3">
                Valider
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default PassportNumberModal;
