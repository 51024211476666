import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function Maccordion(props) {
  return (
    <div className='mt-2'>
      <Accordion
      defaultExpanded
      >
        <AccordionSummary
          expandIcon={<i className='bi bi-chevron-down' />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{props?.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props?.children}
          </Typography>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}
