import React, { useEffect, useState } from "react";
import axios from "axios";
import Moment from "moment/moment";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { Link } from "react-router-dom";
import { width } from "@mui/system";

const News = () => {
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs`).then(response => {
            setBlogs(response.data.data)
        });
    }, []);

    return (
        <>
            <Header />
            <section className="blog-section bg-grey bd-bottom padding">
                <div className="container">
                    <div className="section-heading text-center mb-40">
                        {/* <h2 style={{ textDecoration: "underline" }} className="fw-bold">Recent Posts</h2> */}
                        <span className="text-dark"></span>
                        {/* <p>Help today because tomorrow you may be the one who <br /> needs more helping!</p> */}
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                                {
                                    blogs.map((item) => {
                                        return (
                                            <div key={item.id} className="card shadow-sm border-1 mx-3" style={{width : "30rem", maxHeight : "90vh"}}>
                                                    <Link to={`/new/${item.id}`} style={{textDecoration : 'none', color : 'inherit'}}>
                                                <div className="card-img-top">
                                                        <img src={`http://ework-admin.eycrecruitment.org/public/${item.image}`} height={220} width={'100%'} className="rounded mb-lg-3" alt="blog post" />
                                                    </div>
                                                    <div className="card-body " >
                                                        <span className="date"><i className="fa fa-clock-o"></i> {Moment(item?.created_at).format("MMM Do YY")}</span>
                                                        <h3><Link to={`/new/${item.id}`}>{item.titre}</Link></h3>
                                                        <p className="" style={{overflow : 'hidden', maxHeight : "25vh"}}>
                                                            {item.description}.</p>
                                                        <Link to={`/new/${item.id}`} className="post-meta">Read More</Link>
                                                    </div>
                                                    <div className="card-footer">

                                                    </div>
                                                    </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default News;