import React, { Component, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import dashboardRoutes from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";


function Header() {
  const { i18n } = useTranslation();

  const location = useLocation();
  const changeLanguage = (event) => {
    const lang = event.target.value
    i18n.changeLanguage(lang);
    // window.location.reload(false);

  }

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < dashboardRoutes.length; i++) {
      if (location.pathname.indexOf(dashboardRoutes[i].layout + dashboardRoutes[i].path) !== -1) {
        return dashboardRoutes[i].name;
      }
    }
    return "Brand";
  };

  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  // only show nav when logged in
  if (!isLoggedIn) return null;

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto d-flex align-items-center" navbar>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                onClick={logOut}
              >
                <span className="no-icon">{t('links.logOut')}</span>
              </Nav.Link>
            </Nav.Item>

            {isLoggedIn && <Nav.Item>
              <Nav.Link
                className="m-0"
              >
                <span className="no-icon">{currentUser?.user?.name} <i class="bi bi-circle-fill text-success"></i></span>
              </Nav.Link>
            </Nav.Item>}

            <Nav.Item className="step5" style={{ right: "2rem" }}>
              <select className='form-select shadow' onChange={changeLanguage} value={i18n.language} >
                <option value='en' >En </option>
                <option value='fr' >Fr</option>
              </select>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
