import { useState } from "react";
import axios from "axios";
import { config } from "../constants/config";
import { t } from "i18next";
// import { useAuthHeader } from "react-auth-kit";

const usePostAxiosData = (url, method = "POST") => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const [errorMassage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState({
    status: null,
    message: "",
  });
  // const authHeader = useAuthHeader();
  const postAxiosData = async (data) => {
    try {
      setLoading(true);
      setErrorMessage(null);
      const configurationData = {
        method: method,
        url: `${config.app.fntec_url}/${url}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fntec_token")}`,
        },
        data: data,
      };
      await axios(configurationData)
        .then((response) => {
          setLoading(false);

          if (response?.status === 200 || response?.status === 201) {
            setResult(response?.data);
            setSuccessMessage({
              status: response?.status,
              message: response?.data?.message,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          // error?.code === 'ERR_NETWORK' ? message = t('Problème de conexion') : ''
          // toaster.show({ message: message, type: 'error', duration: 5000, position: 'top' })
          setErrorMessage({
            status: error?.response?.status,
            message: error?.code === 'ERR_NETWORK' ? t('Problème de conexion') :  error?.response?.data?.message,
          });
          return null;
        });
    } catch (error) {
      setLoading(false);
      setErrorMessage({
        status: error?.response?.data?.status,
        // message: error?.response?.data?.message,
        message: error?.code === 'ERR_NETWORK' ? t('Problème de conexion') :  error?.response?.data?.message,

      });
    }
  };

  return [loading, postAxiosData, errorMassage, successMessage, result];
};

export default usePostAxiosData;
