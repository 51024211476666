import CryptoJS from "crypto-js"
import i18next from "i18next";


const currentLanguage =  i18next?.language;

export const DEMANDE_STATUS = {
    SUBMITTED: {
        value: 0,
        label:  "Soumis",
        labelEn:  "Submitted",
        className: "text-info"

    },
    UNDER_PROCESS: {
        value: 1,
        label: "En cours...",
        labelEn:  "In Progress..." ,
        className: "text-warning"

    },
    SENT_FOR_VALIDATION: {
        value: 2,
        label: "En cours...",
        labelEn: "In Progress...",
        className: "text-warning"

    },

    REJECTED: {
        value: 3,
        label:  "Rejetée",
        labelEn:  "Rejected" ,
        className: "text-danger"

    },
    DELETED: {
        value: 4,
        label:  "Supprimée",
        labelEn:  "Deleted" ,
        className: "text-danger"

    },
    UNLOCKED: {
        value: 5,
        label:"Débloquée",
        labelEn: "Unlocked" ,
        className: "text-info"

    },
    VALIDATED: {
        value: 6,
        label:  "Soumettre une preuve de paiement",
        labelEn: "Submit proof of paiement" ,
        className: "text-success"

    },
    PAID: {
        value: 7,
        label:  "Payée",
        labelEn:  "Paid",
        className: "text-success"

    },
    EWORKPERMIT: {
        value: 8,
        label:  "Approuvée",
        labelEn:  "Approuved" ,
        className: "text-success"

    },
    APPLICATION_VERIFIED: {
        value: 9,
        label:  "Application vérifiée",
        labelEn:  "Application Verified" ,
        className: "text-success"

    },
    ICOMPLETE: {
        value: 10,
        label: "Compléter cette demande" ,
        labelEn: "Complete this application" ,
        className: "text-success"

    },
    CANCELED: {
        value: 11,
        label: "Annuler" ,
        labelEn: "Canceled" ,
        className: "text-danger"

    },
}

export const ACCOUNT_STATUS = {

    CREATED: 0,
    SUBMITTED: 1,
    VALIDATED: 2,
    REJECTED: 3,
    UNLOCKED: 4,
}



export const DOCUMENT_NUMBER = 5
export const FILE_MAX_SIZE = 10

export const DOCUMENTS = [
    {
        key: "passport",
        required: true,
        uploaded: false,
    },
    {
        key: "contract",
        uploaded: false,
        required: true
    },
    {
        key: "visa",
        uploaded: false,
        required: false
    }
    ,
    {
        key: "cv",
        uploaded: false,
        required: true
    }
    ,
    {
        key: "medical",
        uploaded: false,
        required: false
    }
    ,
    {
        key: "criminal",
        uploaded: false,
        required: false
    }
]


export const typeVisas = [
    { label: "Ordinary", value: "Ordinary" },
    { label: "Diplomatic", value: "Diplomatic" },
]




export const typeEntrees =
{
    "fr": [
        { label: "Simple", value: "Simple" },
        { label: "Multiple", value: "Multiple" },
    ],

    "en": [
        { label: "Single", value: "Simple" },
        { label: "Multiple", value: "Multiple" },
    ],
}


export const genres = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
]


export const maritals = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorce", value: "Divorce" },
    { label: "Widower", value: "Widower" },]


export const contractDurations = [
    { label: "Year", value: "Year" },
    { label: "Month", value: "Month" },
]


export const modeTransports = [
    { label: "Plane", value: "Plane" },
    { label: "Train", value: "Train" },

]







export const SECRET_KEY = "eWorkPermit23"

export function encrypt(data) {

    if (data !== undefined) {

        var ciphertext = CryptoJS.AES.encrypt(data.toString(), SECRET_KEY);
        ciphertext = ciphertext.toString().replaceAll('+', 'xMl3Jk').replaceAll('/', 'Por21Ld').replaceAll('=', 'Ml32');

        return (ciphertext)
    }



}

export function decrypt(encryptedText) {


    if (encryptedText !== undefined) {

        encryptedText = encryptedText.toString().replaceAll('xMl3Jk', '+').replaceAll('Por21Ld', '/').replaceAll('Ml32', '=');
        var bytes = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);


        return (decryptedData)
    }
}