import axios from "axios";
import React, { Component, useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { Link, useParams } from "react-router-dom";

import Footer from "../components/Footer/Footer";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";

import dashboardRoutes from "../routes";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import { useSelector } from "react-redux";

import { t } from "i18next";
import { decrypt, DEMANDE_STATUS, encrypt } from "../GLOBAL_VARIABLE";
import ModalFormStartPay from "./ModalFormStartPay";
import Maccordion from "../components/MAccordion";

const API_URL = `${process.env.REACT_APP_API_URL}/`;

function DemandeVisaProfile() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);
  const [demandes, setDemandes] = React.useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [successful, setSuccessful] = useState(false);
  const [data, setData] = useState({});
  const [checkedTable, setcheckedTable] = useState({});

  let { id } = useParams("id");

  id = decrypt(id);

  const fetDemande = () => {
    setSuccessful(true);
    axios
      .get(API_URL + "demandeVisas/" + id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        setSuccessful(false);
        console.log("Demande  : ", response.data.data);
        setData(response.data.data);
      })
      .catch((e) => {
        setSuccessful(false);
        setData(null);
      });
  };

  const fetcheckedTable = () => {
    axios
      .get(API_URL + "checkedTable/getElementByDemandeId/" + id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Checked table : ", response.data.data);
        setcheckedTable(response.data.data[0]);
      })
      .catch((e) => {
        setData(null);
      });
  };

  useEffect(() => {
    fetcheckedTable();
  }, [id]);

  useEffect(() => {
    fetDemande();
    // fetcheckedTable();
  }, []);

  return (
    <>
      <div className="wrapper">
        <Sidebar
          color={color}
          image={hasImage ? image : ""}
          routes={dashboardRoutes}
        />
        <div className="main-panel" ref={mainPanel}>
          <Header />
          {successful ? <div id="preloader"></div> : <> </>}
          <div className="content">
            <Container>
              <Row>
                <Row className="mt-2 mb-5 ms-2">
                  <div className=" d-flex justify-content-between ">
                    <Link to="/dashboard">
                      <Button
                        className="btn-fill pull-left  shadow border-0 shadow"
                        variant="danger"
                      >
                        <i class="bi bi-arrow-left"></i>
                        {t("tabForms.prev")}
                      </Button>
                    </Link>

                    {(data?.status === DEMANDE_STATUS.REJECTED.value ||
                      data?.status === DEMANDE_STATUS.SUBMITTED.value) && (
                      <Link to={`/form/${encrypt(data.id)}`}>
                        <Button
                          className="btn-fill pull-left  shadow border-0 shadow"
                          variant="primary"
                        >
                          <i class="bi bi-pencil me-2"></i>
                          {t("edit")}
                        </Button>
                      </Link>
                    )}
                  </div>

                  <Row className="ml-1 justify-content-center" md="6">
                    <Col md="6">
                      <div className="d-flex justify-content-center h6 shadow bg-white rounded p-4">
                        {Object.keys(DEMANDE_STATUS).map((item) => {
                          return (
                            data.status !== DEMANDE_STATUS.VALIDATED.value &&
                            data.status === DEMANDE_STATUS[item].value && (
                              <>
                                {" "}
                                <div className="me-2">Status : </div>{" "}
                                <span
                                  className={DEMANDE_STATUS[item].className}
                                >
                                  {" "}
                                  {DEMANDE_STATUS[item].label}
                                </span>
                              </>
                            )
                          );
                        })}
                        {data.status === DEMANDE_STATUS.VALIDATED.value && (
                          <ModalFormStartPay data={data} />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Row>
                {
                  data?.has_hard_copy === 1 ? (
                    <div className="alert alert-info text-center shadow-sm fs-6">
                      <i className="bi bi-check-circle me-2"></i>
                      {t("goodFiscalPaper")}{" "}
                    </div>
                  ) : (
                    <></>
                  )
                  // (<div className="alert alert-danger text-center shadow-sm fs-6"><i className="bi bi-x-circle me-2"></i>{t('badFiscalPaper')}</div>)
                }
                <Row className="ms-1">
                  <Button
                    className={
                      checkedTable?.visa_detail
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    {t("tabForms.titles.1")}{" "}
                    {checkedTable?.visa_detail ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>

                  <Button
                    className={
                      checkedTable?.application_information
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    {t("tabForms.titles.2")}{" "}
                    {checkedTable?.application_information ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>

                  <Button
                    className={
                      checkedTable?.passport
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    3. {t("tabForms.3.passeport")}{" "}
                    {checkedTable?.passport ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>

                  <Button
                    className={
                      checkedTable?.contract
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    4. {t("tabForms.3.contract")}{" "}
                    {checkedTable?.contract ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>

                  <Button
                    className={
                      checkedTable?.permit_doc
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    5. {t("tabForms.3.ePermit")}{" "}
                    {checkedTable?.permit_doc ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>

                  <Button
                    className={
                      checkedTable?.cv
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    6. {t("tabForms.3.cv")}{" "}
                    {checkedTable?.cv ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>
                  <Button
                    className={
                      checkedTable?.medical_certificate
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    7. {t("tabForms.3.medical")}{" "}
                    {checkedTable?.medical_certificate ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>

                  <Button
                    className={
                      checkedTable?.criminal_record
                        ? "fw-bold m-1 col text-success border-success"
                        : "fw-bold m-1 col text-danger border-danger"
                    }
                  >
                    8. {t("tabForms.3.criminal")}{" "}
                    {checkedTable?.criminal_record ? (
                      <i class="bi bi-check-square-fill"></i>
                    ) : (
                      <i class="bi bi-x-square-fill"></i>
                    )}
                  </Button>
                </Row>
                <Form>
                  {successful ? <div id="preloader"></div> : <> </>}
                  <Col md="12">
                    <Card className="shadow">
                      <Card.Body>
                        <Card.Header>
                          <Card.Title as="h2" className="text-center">
                            {t("tabForms.titles.1")}
                          </Card.Title>
                        </Card.Header>
                        <hr className="mx-3" />
                        <div>
                          <div className="alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0 h4">
                            {t("tabForms.1.section-1")}
                          </div>

                          <Row>
                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.passN")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.numeroPasseport || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.passT")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.typePasseport || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.doi")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.dateEmission || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.poi")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.lieuEmission || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.expDate")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.dateExpiration || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>

                        {/* Informations du Visa */}
                        <div>
                          <div className="alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0 h4">
                            {t("tabForms.1.section-2")}
                          </div>
                          <Row>
                            <Col md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.visaNumber")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.visaNumber || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <label> {t("tabForms.1.visaPurpose")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.visaPurpose || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group>
                                <label> {t("tabForms.1.tof")} </label>
                                <p className="form-control shadow-sm">
                                  {data?.typeVisa || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.poi")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.placeIssue || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <Form.Group>
                                <label>{t("tabForms.1.toe")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.typeEntree || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.vsd")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.dateDepart || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="6">
                              <Form.Group>
                                <label>{t("tabForms.1.ved")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.dateRetour || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>

                        {/* Information du Sejours  */}
                        <div className="alert alert-success text-uppercase mt-5 mb-0 py-2 shadow-sm mx-0 h4">
                          {t(
                            "foreignworkers.addEmployer.residencePermitNumberTitle"
                          )}
                        </div>
                        <Row className="row-cols-2">
                          {
                            <Col className="pr-1">
                              <Form.Group>
                                <label>
                                  {t(
                                    "foreignworkers.addEmployer.residencePermitNumber"
                                  )}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.numero_cs || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          }

                          <Col className="pr-1">
                            <Form.Group>
                              <label>
                                {t(
                                  "foreignworkers.addEmployer.residencePermitObtDate"
                                )}
                              </label>
                              <p className="form-control shadow-sm">
                                {data?.date_obtention_cs || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col className="pr-1">
                            <Form.Group>
                              <label>
                                {t(
                                  "foreignworkers.addEmployer.residencePermitExpDate"
                                )}
                              </label>
                              <p className="form-control shadow-sm">
                                {data?.date_expiration_cs || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Information du Voyage  */}
                        <div>
                          <div className="alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0 h4">
                            {t("tabForms.1.section-3")}
                          </div>
                          <Row>
                            <Col md="6">
                              <Form.Group>
                                <label> {t("tabForms.2.poe")}</label>
                                <p className="form-control shadow-sm">
                                  {data?.portEntry || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col md="6">
                              <Form.Group>
                                <label> {t("tabForms.2.arrivalDate")} </label>
                                <p className="form-control shadow-sm">
                                  {data?.arrivalDate || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <Form.Group>
                                <label> {t("tabForms.2.maxLengthStay")} </label>
                                <p className="form-control shadow-sm">
                                  {data?.lengthStay || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group>
                                <label> {t("tabForms.2.addressCmr")} </label>
                                <p className="form-control shadow-sm">
                                  {data?.addressCmr || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <Form.Group>
                                <label> {t("tabForms.2.destinations")} </label>
                                <p className="form-control shadow-sm">
                                  {data?.placeWork || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col md="6">
                              <Form.Group>
                                <label> {t("tabForms.2.modestrans")} </label>
                                <p className="form-control shadow-sm">
                                  {data?.modeTransport || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <Form.Group>
                                <label> {t("tabForms.1.cc")} </label>
                                <p className="form-control shadow-sm">
                                  {data?.codePays || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="12">
                    <Card className="shadow">
                      <Card.Header>
                        <Card.Title as="h2" className="text-center">
                          {t("tabForms.titles.2")}
                        </Card.Title>
                      </Card.Header>
                      <hr className="mx-3" />
                      <Card.Body>
                        {/* ---------------------------  2. Details sur l'employé ------------------ */}
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>{t("tabForms.1.surname")}</label>
                              <p className="form-control shadow-sm">
                                {data?.prenom || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>{t("tabForms.1.firstN")}</label>
                              <p className="form-control shadow-sm">
                                {data?.nom || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>{t("tabForms.1.dob")}</label>
                              <p className="form-control shadow-sm">
                                {data?.dateNaissance || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>{t("tabForms.1.bp")}</label>
                              <p className="form-control shadow-sm">
                                {data?.lieuNaissance || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.1.gender")}</label>
                              <p className="form-control shadow-sm">
                                {data?.genre || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.1.nationality")}</label>
                              <p className="form-control shadow-sm">
                                {data?.nationalite || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.ms")} </label>
                              <p className="form-control shadow-sm">
                                {data?.maritalStatus || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.noc")}</label>
                              <p className="form-control shadow-sm">
                                {data?.nombreEnfants || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.natLang")}</label>
                              <p className="form-control shadow-sm">
                                {data?.nativeLanguage || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Job Details */}
                        <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                          {t("tabForms.2.jobDetails")}
                        </div>

                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.jobTitle")}</label>
                              <p className="form-control shadow-sm">
                                {data?.jobTitle || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.contractED")}</label>
                              <p className="form-control shadow-sm">
                                {data?.contractStart || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.contractEnd")}</label>
                              <p className="form-control shadow-sm">
                                {data?.contractEnd || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* Details salaire */}
                        <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                          {t("tabForms.2.salaryDetails")}
                        </div>
                        {/* ---- First section ------ */}
                        <Row className="row row-cols-5">
                          <Col>
                            <Form.Group>
                              <label>
                                {" "}
                                {t("tabForms.2.bSalary")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <p className="form-control shadow-sm">
                                {data?.baseSalary || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <label>
                                {" "}
                                {t("tabForms.2.indemnite de logement")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <p className="form-control shadow-sm">
                                {data?.indLogement || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <label>
                                {" "}
                                {t("tabForms.2.indemnite de transport")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <p className="form-control shadow-sm">
                                {data?.indTransport || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <label>
                                {" "}
                                {t("tabForms.2.prime de reponsabilite")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <p className="form-control shadow-sm">
                                {data?.indResponsabilite || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <label>
                                {" "}
                                {t("tabForms.2.prime de representation")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <p className="form-control shadow-sm">
                                {data?.indRepresentation || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* ------ End first section ------ */}

                        {/* ---- Second section ------ */}
                        <Maccordion
                          title={t("tabForms.2.Autres idemnités et primes")}
                        >
                          <Row className="row-cols-1 row-cols-sm-5">
                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.prime d'expatriation")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.primeExploitation || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.sursalaire")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.sursalaire || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.prime de salissure")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.primeSalissure || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.indenmite de caisse")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.indCaisse || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.bonus")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.bonus || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.prime de panier")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.primePanier || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.billet d'avion")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.billetAvion || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group>
                                <label>
                                  {" "}
                                  {t("tabForms.2.autres primes")}{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <p className="form-control shadow-sm">
                                  {data?.autresPrimes || (
                                    <small className="text-warning">
                                      {t("tabForms.Non renseigne")}
                                    </small>
                                  )}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Maccordion>

                        {/* ------ End second section ------ */}

                        {/* ---------  contact details --------------- */}
                        <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                          {t("tabForms.2.contactDetails")}
                        </div>
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label> {t("tabForms.2.email")} </label>
                              <p className="form-control shadow-sm">
                                {data?.email || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.phoneN")} </label>
                              <p className="form-control shadow-sm">
                                {data?.phone || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* -------- Permanent Address --------- */}
                        <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                          {t("tabForms.2.pAddress")}
                        </div>
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.houseNumber")} *</label>
                              <p className="form-control shadow-sm">
                                {data?.numeroMaisonPermanente || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.city")} *</label>
                              <p className="form-control shadow-sm">
                                {data?.villePermanente || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.state")}</label>
                              <p className="form-control shadow-sm">
                                {data?.regionPermanente || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.postal")}</label>
                              <p className="form-control shadow-sm">
                                {data?.codePostalPermanente || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.country")}</label>
                              <p className="form-control shadow-sm">
                                {data?.paysPermanente || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* -------- Emergency contact --------- */}
                        <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                          {t("tabForms.2.eContact")}
                        </div>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.fullName")}</label>
                              <p className="form-control shadow-sm">
                                {data?.fullNameE || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.phoneN")} *</label>
                              <p className="form-control shadow-sm">
                                {data?.phoneE || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>{t("tabForms.2.email")} </label>
                              <p className="form-control shadow-sm">
                                {data?.emailE || (
                                  <small className="text-warning">
                                    {t("tabForms.Non renseigne")}
                                  </small>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                      {/* <div className="clearfix"></div> */}
                      <Row className="my-5 ms-2">
                        <Col className="ml-1" md="4">
                          <Link to="/dashboard">
                            <Button
                              className="btn-fill pull-left  shadow border-0 shadow"
                              variant="danger"
                            >
                              <i class="bi bi-arrow-left"></i>

                              {t("tabForms.prev")}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Form>
              </Row>
            </Container>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}

export default DemandeVisaProfile;