import  {
    useState,
    useEffect,
    useCallback,
  } from 'react';
  
  const useDataFetching = url => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
  
    const fetchData = useCallback(async () => {
      try {
        setLoading(true);
        const data = await fetch(url, {
          method: 'GET',
        });
        const result = await data.json();
        if (result) {
          setData(result);
          setLoading(false);
          setError('')
        }
      } catch (e) {
        setError(e.message);
        console.log("error ", e)
        setLoading(false);
      }
    }, [url]);
  
    useEffect(() => {
      fetchData();
      // TODO: delete cash
    }, [url]);

    return [loading, error, data, fetchData];
  };
  
  export default useDataFetching;
  