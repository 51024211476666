import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { config } from "../constants/config";

const UseCinetPay = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const API_KEY = config.CINET.API_KEY;
  const APP_ID = config.CINET.APP_ID;
  const PAYMENT_URL = "https://api-checkout.cinetpay.com/v2/payment";

  const startPayment = async (values) => {
    console.log("start payment");
    // const ref = Math.floor(Math.random() * 100000000).toString();
    setLoading(true);

    const data = JSON.stringify({
      "apikey": API_KEY,
      "site_id": APP_ID,
      "transaction_id": values.ref,
      "amount": values.amount,
      "currency": "XAF",
      "alternative_currency": "",
      "description": "Payment for eWorkPermit.",
      "customer_id": currentUser?.user?.id,
      "customer_name": currentUser?.user?.name,
      "customer_surname": currentUser?.user?.name,
      "customer_phone_number": currentUser?.user?.phone,
      "customer_address": "ADRESS",
      "customer_city": "Yaounde",
      "customer_country": 'CM',
      "customer_state": 'CM',
      "customer_zip_code": "67589",
      "notify_url": 'http://ework-hook.eycrecruitment.org/hook.php',
      "return_url": 'http://ework-hook.eycrecruitment.org/index.html',
      "channels": "ALL",
      "metadata": "user1",
      "lang": "FR",
      "invoice_data": {
        "Donnee1": "",
        "Donnee2": "",
        "Donnee3": ""
      }
    });

    // console.log('data', data);

    const configuration = {
      method: "POST",
      url: PAYMENT_URL,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    console.log("CONFIG ", configuration);

    axios(configuration)
      .then(function (response) {
        setLoading(false);
        console.log("Response", response);
        if (response.data.code === "201") {
          let win = window.open(response.data.data.payment_url, "_self");
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("cinet pay error", error)
      });
  };

  return [
    loading,
    error,
    startPayment
  ];
};

export default UseCinetPay;
