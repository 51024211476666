import React from 'react'
import { useEffect } from 'react'
import {
    PREVIEW_VARIABLES,
} from '../../GLOBAL_VARIABLES.js'

const Preview = ({ data }) => {
    useEffect(() => {
        console.log('data : ', data)
    }, [])
    return (
        <div className='d-flex justify-content-center'>
            <table id='previewTable' className='text-center' style={{ width: "100%", border: 'solid 2px black' }}>
                <tr>
                    <td className='border-left' rowSpan={3} style={{ backgroundColor: 'white', width: "25%" }}>
                        <img src={require("../../assets/img/logo.jpeg")} alt="..." />
                    </td>
                    <td colSpan={3} className="fw-bold " style={{ color: 'rgba(37, 55, 91, 1)' }} id="blue">
                        {PREVIEW_VARIABLES.HEADER.HEADER1}
                    </td>
                    {/* <td colSpan={2}>

                    </td> */}
                    <td className='border-right' colSpan={2} rowSpan={3} style={{ textAlign: 'center', backgroundColor: 'white', marginTop: '1%' }}>
                        <img className='mb-2' src={require("../../assets/img/qrcode.png")} alt="..." /><br />
                        <img src={require("../../assets/img/barcode.png")} alt="..." /><br />
                        <span></span>
                    </td>
                </tr>
                <tr>
                    <td className="fw-bold" colSpan={3} id="blue" style={{ color: 'rgba(37, 55, 91, 1)' }}>{PREVIEW_VARIABLES.HEADER.HEADER2}</td>
                </tr>
                <tr>
                    <td className="fw-bold" colSpan={3} >{PREVIEW_VARIABLES.HEADER.HEADER3}</td>
                </tr>

                {/* DETAILS VISA/VISA DETAILS */}
                <tr>
                    <th id='th' className='blueW border-left border-right' colSpan={6}>DETAILS DU PASSPORT, VISA ET DU VOYAGE / PASSPORT,VISA AND TRAVEL DETAILS</th>
                </tr>
                <tr>
                    <td colspan="2" className='blue border-left border-right' id='th'>DETAILS DU PASSPORT / PASSPORT DETAILS</td>
                    <td colspan="2" className='blue border-right' id='th'>DETAILS DU VISA / VISA DETAILS</td>
                    <td colspan="2" className='blue border-right' id='th'>DETAILS DU VOYAGE / TRAVEL DETAILS</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left' style={{ width: '15%' }}>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.NUM_PASSPORT.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.NUM_PASSPORT.EN}</i></td>
                    <td className='text-start text-muted border-right p-2' style={{ width: '15%' }}>{data?.numeroPasseport}</td>
                    <td className='text-start  p-2 border-left' style={{ width: '15%' }}>{PREVIEW_VARIABLES.DETAILS_DU_VISA.NUM_VISA.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VISA.NUM_VISA.EN}</i></td>
                    <td className='text-start text-muted border-right p-2' style={{ width: '15%' }}>{data?.visaNumber}</td>
                    <td className='text-start  p-2 border-left' style={{ width: '15%' }}>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.PORT_OF_ENTRY.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.PORT_OF_ENTRY.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right' style={{ width: '15%' }}>{data?.portEntry}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.TYPE_PASSPORT.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.TYPE_PASSPORT.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.typePassport?.label}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VISA.VISA_PERPOSE.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VISA.VISA_PERPOSE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.visaPurpose}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.DATE_ARRIVE.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.DATE_ARRIVE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.arrivalDate}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.DATE_EMIS.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.DATE_EMIS.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.dateEmission}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VISA.TYPE_VISA.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VISA.TYPE_VISA.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.typeVisa?.label}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.TEMPS_SEJOUR.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.TEMPS_SEJOUR.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.lengthStay}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.LIEU_EMIS.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.LIEU_EMIS.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.lieuEmission}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VISA.TYPE_ENTREE.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VISA.TYPE_ENTREE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.typeEntree?.label}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.ADDRESSE.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.ADDRESSE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.addressCmr}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.DATE_EXP.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_PASSPORT.DATE_EXP.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.dateExpiration}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VISA.DATE_DEPART.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VISA.DATE_DEPART.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.dateDepart}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.DESTINATIONS.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.DESTINATIONS.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.placeWork}</td>
                </tr>
                <tr>
                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VISA.DATE_RETOUR.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VISA.DATE_RETOUR.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.dateRetour}</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.MODE_TRANSPORT.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.MODE_TRANSPORT.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.modeTransport?.label}</td>
                </tr>





                <tr>
                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.numero_cs.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.numero_cs.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.numero_cs}</td>
                </tr>

                <tr>
                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.date_obtention_cs.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.date_obtention_cs.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.date_obtention_cs}</td>
                </tr>

                <tr>
                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.date_expiration_cs.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_VOYAGE.date_expiration_cs.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.date_expiration_cs}</td>
                </tr>

                {/* ADRESSE DU DEMANDEUR/APPLICANT’S ADDRESS */}
                <tr>
                    <th id='th' className='blueW border-left' colSpan={6}>DETAILS DE ADRESSE, DU CONTACT ET DU CONTACT D'URGENCE / ADDRESS, CONTACT AND EMERGENCY CONTACT DETAILS</th>
                </tr>
                <tr>
                    <td colspan="2" className='blue border-left border-right' id='th'>ADRESSE PERMANANTE / PERMANENT ADDRESS </td>
                    <td colspan="2" className='blue border-right' id='th'>DETAILS DU CONTACT / CONTACT DETAILS</td>
                    <td colspan="2" className='blue' id='th'>CONTACT D'URGENCE / EMERGENCY CONTACT</td>
                </tr>

                {/*  */}
                <tr>
                    <td className='text-start  p-2 border-left' style={{ width: '15%' }} >{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.HOUSE_NUMBER.FR}<br /><i>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.HOUSE_NUMBER.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right' style={{ width: '15%' }}>{data?.numeroMaisonPermanente}</td>

                    <td className='text-start  p-2 border-left' style={{ width: '15%' }} >{PREVIEW_VARIABLES.DETAILS_DU_CONTACT.EMAIL.FR}<br /><i>{PREVIEW_VARIABLES.DETAILS_DU_CONTACT.EMAIL.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right' style={{ width: '15%' }}>{data?.email}</td>

                    <td className='text-start  p-2 border-left' style={{ width: '15%' }} >{PREVIEW_VARIABLES.CONTACT_URGENCE.PRENOM.FR}<br /><i>{PREVIEW_VARIABLES.CONTACT_URGENCE.PRENOM.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right' style={{ width: '15%' }}>{data?.fullNameE}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.VILLE.FR} <br /> <i>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.VILLE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.villePermanente}</td>

                    <td className='text-start  p-2 border-left' >{PREVIEW_VARIABLES.DETAILS_DU_CONTACT.NUM_TEL.FR}<br /> <i>{PREVIEW_VARIABLES.DETAILS_DU_CONTACT.NUM_TEL.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right' >{data?.phone}</td>

                    <td className='text-start  p-2 border-left' style={{ width: '15%' }} >{PREVIEW_VARIABLES.CONTACT_URGENCE.EMAIL.FR}<br /><i>{PREVIEW_VARIABLES.CONTACT_URGENCE.EMAIL.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right' style={{ width: '15%' }}>{data?.emailE}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.REGION.FR}  <br /><i>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.REGION.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.regionPermanente}</td>

                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left' >{PREVIEW_VARIABLES.CONTACT_URGENCE.NUM_TEL.FR}<br /> <i>{PREVIEW_VARIABLES.CONTACT_URGENCE.NUM_TEL.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right' >{data?.phoneE}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.CODE_POSTAL.FR} <br /><i>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.CODE_POSTAL.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.codePostalPermanente}</td>
                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2'>--------------------</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.PAYS.FR} <br /><i>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.PAYS.FR}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.paysPermanente?.label}</td>

                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2'>--------------------</td>
                </tr>
                <tr >

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.NUM_TEL.FR} <br /><i>{PREVIEW_VARIABLES.ADRESSE_PERMANANTE.NUM_TEL.EN} </i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.phoneE}</td>
                    {/* <td className='text-start text-muted p-2 border-right'>{!data?.phoneE && <span className="text-danger">Information Maquante / Missed Information</span>} {data?.phoneE}</td> */}
                    <td className='text-center text-muted  p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2'>--------------------</td>
                </tr>

                {/* INFORMATION PERSONELLE &&  JOB’S DETAILS && SALARY DETAILS*/}
                <tr>
                    <th id='th' className='blueW border-left' colSpan={6}>DETAILS PERSONNEL, DU TRAVAIL ET DU SALAIRE / PERSONAL, JOB'S AND SALARY DETAILS</th>
                </tr>
                <tr>
                    <td colspan="2" className='blue border-right border-left' id='th'>INFORMATION PERSONNELLE / PERSONAL INFORMATION</td>
                    <td colspan="2" className='blue border-right' id='th'>JOB DETAILS</td>
                    <td colspan="2" className='blue' id='th'>SALARY DETAILS</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.PRENOM.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.PRENOM.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.prenom}</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.JOB_DETAILS.INTITULE_DU_POSTE.FR}<br /><i>{PREVIEW_VARIABLES.JOB_DETAILS.INTITULE_DU_POSTE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.jobTitle}</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.DATE_EFFECT_CONTRAT.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.DATE_EFFECT_CONTRAT.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.contractStart}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.NOM.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.NOM.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.nom}</td>


                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.SALAIRE_BRUTE.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.SALAIRE_BRUTE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{parseInt(data?.baseSalary) + (data?.bonus) + (data?.billetAvion) + (data?.sursalaire) + parseInt(data?.indTransport) + parseInt(data?.indRepresentation) + parseInt(data?.indResponsabilite) + parseInt(data?.indCaisse) + parseInt(data?.primeExploitation) + parseInt(data?.primePanier) + parseInt(data?.primeSalissure) + parseInt(data?.autresPrimes) + parseInt(data?.indLogement)}</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.JOB_DETAILS.DUREE_CONTRAT.FR}<br /><i>{PREVIEW_VARIABLES.JOB_DETAILS.DUREE_CONTRAT.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.contractEnd}</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.DATE_NAISS.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.DATE_NAISS.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.dateNaissance}</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2  border-right'>--------------------</td>

                    <td className='text-center text-muted  p-2 border-left'>--------------------<br /><i></i></td>
                    <td className='text-center text-muted p-2'>--------------------</td>
                </tr>
                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.LIEU_NAISS.EN}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.LIEU_NAISS.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.lieuNaissance}</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.DEVISE.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.DEVISE.EN}</i></td>
                    <td className='text-start text-muted p-2'>XAF</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.GENRE.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.GENRE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.genre?.label}</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.BASE_SALARY.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.BASE_SALARY.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.baseSalary}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.ETAT_CIVIL.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.ETAT_CIVIL.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.maritalStatus?.label}</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.indLogement.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.indLogement.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.indLogement}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.NATIONALITY.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.NATIONALITY.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.nationalite?.label}</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.indTransport.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.indTransport.FR}</i></td>
                    <td className='text-start text-muted p-2'>{data?.indTransport}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.NB_ENFANTS.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.NB_ENFANTS.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.nombreEnfants}</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.indResponsabilite.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.indResponsabilite.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.indResponsabilite}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.LANG_MATERNELLE.FR}<br /><i>{PREVIEW_VARIABLES.PERSONAL_INFORMATIONS.LANG_MATERNELLE.EN}</i></td>
                    <td className='text-start text-muted p-2 border-right'>{data?.nativeLanguage}</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.indRepresentation.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.indRepresentation.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.indRepresentation}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------</td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.primeExploitation.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.primeExploitation.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.primeExploitation}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------<br /></td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.sursalaire.FR}<br /><i>{PREVIEW_VARIABLES.SALARY_DETAILS.sursalaire.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.sursalaire}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------<br /></td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.primeSalissure.FR}<br />
                    <i>{PREVIEW_VARIABLES.SALARY_DETAILS.primeSalissure.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.primeSalissure}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------<br /></td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.indCaisse.FR}<br />
                    <i>{PREVIEW_VARIABLES.SALARY_DETAILS.indCaisse.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.indCaisse}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------</td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.bonus.FR}<br />
                    <i>{PREVIEW_VARIABLES.SALARY_DETAILS.bonus.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.bonus}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------</td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.primePanier.FR}<br />
                    <i>{PREVIEW_VARIABLES.SALARY_DETAILS.primePanier.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.primePanier}</td>
                </tr>


                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------</td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.billetAvion.FR}<br />
                    <i>{PREVIEW_VARIABLES.SALARY_DETAILS.billetAvion.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.billetAvion}</td>
                </tr>

                <tr>
                    <td className='text-start  p-2 border-left border-left'>--------------------</td>
                    <td className='text-start text-muted p-2 border-right'>--------------------</td>

                    <td className='text-center text-muted p-2 border-left'>--------------------</td>
                    <td className='text-center text-muted p-2 border-right'>--------------------</td>

                    <td className='text-start  p-2 border-left'>{PREVIEW_VARIABLES.SALARY_DETAILS.autresPrimes.FR}<br />
                    <i>{PREVIEW_VARIABLES.SALARY_DETAILS.autresPrimes.EN}</i></td>
                    <td className='text-start text-muted p-2'>{data?.autresPrimes}</td>
                </tr>
                <tr>
                    <th id='th' colSpan={6} className='bg-secondary border-left'>
                        {PREVIEW_VARIABLES.FOOTER.FR}<br />
                        <i>{PREVIEW_VARIABLES.FOOTER.EN}</i>
                    </th>
                </tr>
            </table>
        </div>
    )
}

export default Preview