import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className=" px-0 px-lg-3 " style={{zIndex : '999999'}}>
        <Container fluid>
          <nav className="d-flex justify-content-center align-items-center pt-3">
            {/* <div className="d-flex justify-content-around w-25 ">
                <Link to="/" className="text-secondary">
                  Home
                </Link>
                <Link to="/dashboard" className="text-secondary" >
                  Dashboard
                </Link>
            </div> */}
            <p className="copyright text-center">
              © {new Date().getFullYear()}
              <strong className="ms-1">eWorkPermit</strong>
            </p>
          </nav>
          
        </Container>
      </footer>
    );
  }
}

export default Footer;
