import { t } from "i18next";
import { useEffect, useState } from "react";
import Joyride from 'react-joyride';

const Guide = () => {
    const [run, setRun] = useState(true);
    const [steps, setSteps] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false); // Remplacez cela par la logique réelle pour déterminer si l'utilisateur est admin
  

    const homeTour = [
        {
            target: 'body',
            content: t("demo.home.start"),
            placement: 'center'
        },
        {
            target: '.step1',
            content: t('demo.home.login'),
        },
        {
            target: '.step2',
            content: t('demo.home.register'),
        },
        {
            target: '.step5',
            content: t('demo.home.lang'),
        },
        {
            target: '.step6',
            content: t('demo.home.started'),
        },
        {
            target: '.step3',
            content: t('demo.home.tutorial'),
        },
        {
            target: '.step4',
            content: t('demo.home.manual'),
        },
    ];

    const adminTour = [
        {
            target: 'body',
            content: t("demo.permit.start"),
            placement: 'center'
        },
        {
            target: '.menu1',
            content: t('demo.permit.menu1'),
        },
        {
            target: '.menu2',
            content: t('demo.permit.menu2'),
        },
        {
            target: '.menu3',
            content: t('demo.permit.menu3'),
        },
        {
            target: '.menu4',
            content: t('demo.permit.menu4'),
        }
    ];

    const employeesTour = [
        {
            target: 'body',
            content: t("demo.permit.start"),
            placement: 'center'
        },
        {
            target: '.addEmpl',
            content: t('demo.empl.1'),
        },
        {
            target: '.requests',
            content: t('demo.empl.2'),
        },
        {
            target: '.newPermit',
            content: t('demo.empl.3'),
        }
    ];

    useEffect(() => {
      // Logique pour déterminer la page actuelle ou l'état de connexion
      const currentPage = window.location.pathname;
  
      if (currentPage === '/') {
        setSteps(homeTour);
        console.log("Home")
      } else if (currentPage === '/dashboard') {
        setSteps(adminTour);
        console.log("Admin")
      } else if (currentPage === '/foreignWorkers') {
        setSteps(employeesTour);
        console.log("employeesTour")
      }
    }, []);

    return (
        <Joyride
            locale={{
                back:t("demo.Back"),
                close: t("demo.Close"),
                last: t("demo.Last"),
                next: t("demo.Next"),
                open: t("demo.Open"),
                skip: t("demo.Skip")
            }}
            steps={steps}
            run={run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
        />
    );
};

export default Guide;