// import image from "../assets/img/sidebar-1.jpg"
// import image2 from "../assets/img/sidebar-2.jpg"
// import "../assets/css/SlideBar.css"
import $ from 'jquery';
import { useEffect } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Trans, useTranslation } from "react-i18next";

function SlideBar2() {
    // useEffect(() => {
    //     var multipleCardCarousel = document.querySelector(
    //         "#carouselExampleControls"
    //     );
    //     if (window.matchMedia("(min-width: 768px)").matches) {

    //         var carouselWidth = $(".carousel-inner")[0].scrollWidth;
    //         var cardWidth = $(".carousel-item").width();
    //         var scrollPosition = 0;
    //         $("#carouselExampleControls .carousel-control-next").on("click", function () {
    //             if (scrollPosition < carouselWidth - cardWidth * 4) {
    //                 scrollPosition += cardWidth;
    //                 $("#carouselExampleControls .carousel-inner").animate(
    //                     { scrollLeft: scrollPosition },
    //                     600
    //                 );
    //             }
    //         });
    //         $("#carouselExampleControls .carousel-control-prev").on("click", function () {
    //             if (scrollPosition > 0) {
    //                 scrollPosition -= cardWidth;
    //                 $("#carouselExampleControls .carousel-inner").animate(
    //                     { scrollLeft: scrollPosition },
    //                     600
    //                 );
    //             }
    //         });
    //     } else {
    //         $(multipleCardCarousel).addClass("slide");
    //     }
    // })
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (

        <div id="carouselExampleControls" className="carousel" data-bs-ride="carousel">
            {/* <div className="carousel-inner">
            

                <div className="carousel-item active">
                    <div className="" >
                        <div className="icon-box" >
                            <div className="icon">01.</div>
                            <p><a className="btn-watch-video text-center"><span>Fill up the registration form</span></a></p>
                        </div>
                    </div>
                </div>
              

                <div className="carousel-item">
                    <div className="" >
                        <div className="icon-box">
                            <div className="icon">02.</div>
                            <p><a className="btn-watch-video text-center"><span>Click on the link sent to your email to validate your information</span></a></p>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="" >

                        <div className="icon-box">
                            <div className="icon">03.</div>
                            <p><a className="btn-watch-video text-center"><span>Log in and fill up the information on the profile then send for validation</span></a></p>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="" >
                        <div className="icon-box">
                            <div className="icon">04.</div>
                            <p><a className="btn-watch-video text-center"><span>Information verfication and validation by the staff</span></a></p>
                        </div>
                    </div>
                </div>
                
                <div className="carousel-item">
                    <div className="" >
                        <div className="icon-box">
                            <div className="icon">05.</div>
                            <p><a className="btn-watch-video text-center"><span>Account ready to request for permit visa</span></a></p>
                        </div>
                    </div>
                </div>



            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button> */}

            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="transform 600ms ease-in-out"
                // transitionDuration={3000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style d-none"
                itemClass="carousel-item-padding-40-px"
            >
                <div className='p-1' style={{ height: "15rem" }}>
                    <div className="icon-box" >
                        <div className="icon">01.</div>
                        <p>
                            <a className="btn-watch-video text-center">
                                <span>
                                    <Trans i18nKey="header.accountStep.1">
                                        Fill up the registration form
                                    </Trans>
                                </span></a></p>
                    </div>
                </div>

                <div className='p-2' style={{ height: "15rem" }}>

                    <div className="icon-box">
                        <div className="icon">02.</div>
                        <p><a className="btn-watch-video text-center"><span>
                            <Trans i18nKey="header.accountStep.2">

                                Click on the link sent to your email to validate your information
                            </Trans>
                        </span></a></p>


                    </div>
                </div>

                <div className='p-2' style={{ height: "15rem" }}>

                    <div className="icon-box">
                        <div className="icon">03.</div>
                        <p><a className="btn-watch-video text-center"><span>
                            <Trans i18nKey="header.accountStep.3">

                                Log in and fill up the information on the profile then send for validation
                            </Trans>
                        </span></a></p>
                    </div>
                </div>

                <div className='p-2' style={{ height: "15rem" }}>

                    <div className="icon-box">
                        <div className="icon">04.</div>
                        <p><a className="btn-watch-video text-center"><span>
                            <Trans i18nKey="header.accountStep.4">

                                Information verfication and validation by the staff
                            </Trans>
                        </span></a></p>
                    </div>
                </div>

                <div className='p-2' style={{ height: "15rem" }}>

                    <div className="icon-box">
                        <div className="icon">05.</div>
                        <p><a className="btn-watch-video text-center"><span>
                            <Trans i18nKey="header.accountStep.5">
                                Account ready to request for work visa permit 
                            </Trans>
                        </span></a></p>
                    </div>
                </div>
            </Carousel>
        </div>


    )




}

export default SlideBar2