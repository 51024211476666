
function FormHeader() {
    return (
        <div className="row justify-content-center   " style={{ backgroundColor: '#008374', backgroundImage: "url('https://images.pexels.com/photos/732548/pexels-photo-732548.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')", backgroundRepeat: "no-repeat",backgroundSize:"cover" }}>
            <div className='col  text-center p-3 '>
                <p style={{ color: "white", fontSize: "150%", fontWeight: "bold" }}> MINISTERE DE L'EMPLOI ET DE LA FORMATION PROFESSIONNELLE</p>

                <p style={{ color: "white", fontSize: "150%", fontWeight: "bold" }}>  MINISTRY OF EMPLOYMENT AND VOCATIONAL TRAINING</p>
            </div>
        </div>
    )
}

export default  FormHeader;