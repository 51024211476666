import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/`;

const register = ({name, email, password, confirm_password}) => {
    return axios.post(API_URL + "register", {
        name,
        email,
        password,
        confirm_password,
    })
    .then((response) => {

        return response;
    });
};

const verify = ({email, code}) => {
    return axios.post(API_URL + "account/verify", {
        email,
        code,
    })
    .then((response) => {

        return response;
    });
};

const login = ({email, password}) => {
    return axios
        .post(API_URL + "login", {
            email,
            password,
        })
        .then((response) => {
            console.log("Response :",response)
            if (response.data.success) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }

            return response;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    register,
    login,
    verify,
    logout,
};