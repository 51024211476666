import axios from "axios";
import React, { Component, useEffect, useState } from 'react';
import Select from "react-select";

import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col
} from "react-bootstrap";

import { Link, useParams } from "react-router-dom";

import Footer from "../components/Footer/Footer";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";

import dashboardRoutes from "../routes";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import { useSelector } from "react-redux";

import { t } from "i18next";
import { decrypt, DEMANDE_STATUS, encrypt, genres, modeTransports, typeEntrees, typeVisas } from "../GLOBAL_VARIABLE";
import ModalFormStartPay from "./ModalFormStartPay";
import ReactSelect from "react-select";
// import { Select } from "@mui/material";
import * as Yup from "yup";
import "yup-phone";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Mtoast } from "../components/utils/toast";
import { toast } from "react-hot-toast";
import { CardBody } from "reactstrap";

const API_URL = `${process.env.REACT_APP_API_URL}/`;



function EditDemande() {

    const [image, setImage] = React.useState(sidebarImage);
    const [color, setColor] = React.useState("black");
    const [hasImage, setHasImage] = React.useState(true);
    const mainPanel = React.useRef(null);
    const [demandes, setDemandes] = React.useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);

    const [successful, setSuccessful] = useState(false);
    const [data, setData] = useState({})
    const [checkedTable, setcheckedTable] = useState({})

    const [countries, setCountries] = React.useState([]);


    let { id } = useParams('id');
    id = decrypt(id)

    const fetchCountry = () => {
        axios
            .get("https://restcountries.com/v3.1/all")
            .then((response) => {
                setCountries(response.data);
                let countrie = [];
                response.data.forEach((data) => {
                    countrie.push({ label: data.name.common, value: data.cca2 });
                });
                setCountries(
                    countrie.sort((a, b) => {
                        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })
                );
            })
            .catch((e) => {
                console.log(e);
            });
    };

    React.useEffect(() => {
        fetchCountry();
    }, []);



    const fetDemande = () => {
        setSuccessful(true);
        axios
            .get(API_URL + "demandeVisas/" + id, {
                headers: {
                    "Authorization": `Bearer ${currentUser.token}`
                }
            })
            .then((response) => {
                setSuccessful(false);
                console.log('Demande  : ', response.data.data)
                setData(response.data.data);
            })
            .catch((e) => {
                setSuccessful(false);
                setData(null);
            });
    }

    const fetcheckedTable = () => {
        axios
            .get(API_URL + "checkedTable/getElementByDemandeId/" + id, {
                headers: {
                    "Authorization": `Bearer ${currentUser.token}`
                }
            })
            .then((response) => {
                console.log('Checked table : ', response.data.data)
                setcheckedTable(response.data.data[0])
            })
            .catch((e) => {
                setData(null);
            });
    }


    useEffect(() => {
        fetcheckedTable();
    }, [id]);


    useEffect(() => {
        fetDemande();
        // fetcheckedTable();
    }, []);



    const [typePassport, setTypePassport] = useState("")
    const [typeVisa, setTypeVisa] = useState("")
    const [modeTrans, setTypeModeTrans] = useState("")
    const [codePays, setCodePays] = useState("")
    const [gender, setGender] = useState("")
    const [nationalite, setNationalite] = useState("")
    const [maritalStatus, setMaritalStatus] = useState("")
    const [typeEntree, setTypeEntree] = useState("")




    // form validation rules
    const validationSchema = Yup.object().shape({
        numeroPasseport: Yup.string().required(t('required')),
        dateEmission: Yup.string().required(t('required')),
        lieuEmission: Yup.string().required(t('required')),
        dateExpiration: Yup.string().required(t('required')),


        visaNumber: Yup.string().required(t('required')),
        visaPurpose: Yup.string().required(t('required')),
        typeVisa: Yup.string().required(t('required')),
        placeIssue: Yup.string().required(t('required')),
        typeEntree: Yup.string().required(t('required')),
        dateDepart: Yup.string().required(t('required')),
        dateRetour: Yup.string().required(t('required')),



        portEntry: Yup.string().required(t('required')),
        arrivalDate: Yup.string().required(t('required')),
        lengthStay: Yup.string().required(t('required')),
        addressCmr: Yup.string().required(t('required')),
        placeWork: Yup.string().required(t('required')),
        modeTransport: Yup.string().required(t('required')),



        ///Tab2

        prenom: Yup.string().required(t('required')),
        nom: Yup.string().required(t('required')),
        dateNaissance: Yup.string().required(t('required')),
        lieuNaissance: Yup.string().required(t('required')),
        codePays: Yup.string().required(t('required')),
        nationalite: Yup.string().required(t('required')),
        maritalStatus: Yup.string().required(t('required')),
        nombreEnfants: Yup.string().required(t('required')),
        nativeLanguage: Yup.string().required(t('required')),

        jobTitle: Yup.string().required(t('required')),
        numperiod: Yup.string().required(t('required')),
        contractStart: Yup.string().required(t('required')),
        contractEnd: Yup.string().required(t('required')),
        // salary: Yup.string().required(t('required')),


        currency: Yup.string().required(t('required')),
        prime: Yup.string().required(t('required')),
        professionalFees: Yup.string().required(t('required')),
        indemnite: Yup.string().required(t('required')),
        baseSalary: Yup.string().required(t('required')),


        email: Yup.string().email(),
        phone: Yup.string().phone(),



        numeroMaisonPermanente: Yup.string().required(t('required')),
        villePermanente: Yup.string().required(t('required')),
        regionPermanente: Yup.string().required(t('required')),
        codePostalPermanente: Yup.string().required(t('required')),
        paysPermanente: Yup.string().required(t('required')),


        fullNameE: Yup.string().required(t('required')),
        phoneE: Yup.string().phone(),
        emailE: Yup.string().email(),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const [isFtechUpdatedEnded, setIsFetchUpdateEnded] = useState(true)

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");

    const [cv, setCv] = useState()
    const [medical, setMedical] = useState()
    const [criminal, setCriminal] = useState()
    const [visa, setVisa] = useState()
    const [contract, setContract] = useState()
    const [passport, setPassport] = useState()

    let formdata = new FormData();

    function onSubmit(datas) {
        setIsFetchUpdateEnded(false)

        console.log('Let submit !')
        const toUpdate = {
            ...datas,
            demande_id: data?.id,
            typePasseport: typePassport.label || data?.typePasseport,
            typeVisa: typeVisa.label || data?.typeVisa,
            modeTrans: modeTrans.label || data?.modeTrans,
            codePays: codePays.label || data?.codePays,
            genre: gender.label || data?.gender,
            nationalite: nationalite.label || data?.nationalite,
            maritalStatus: maritalStatus.label || data?.maritalStatus,
            typeEntree: typeEntree.label || data?.typeEntree,
        }
        if (cv) toUpdate.cv = cv[0]
        if (medical) toUpdate.medical = medical[0]
        if (criminal) toUpdate.criminal = criminal[0]
        if (visa) toUpdate.visa = visa[0]
        if (contract) toUpdate.contract = contract[0]
        if (passport) toUpdate.passport = passport[0]

        // if(immatriculation != null) toUpdate.immatriculation =  immatriculation[0]
        // if(nonredevance != null) toUpdate.nonredevance =  nonredevance[0]
        // if(organigram != null) toUpdate.organigram =  organigram[0]

        // if(paysPermanente == null || paysPermanente === "") toUpdate.paysPermanente =  compte?.paysPermanente
        console.log('tuUpdate : ', toUpdate)
        Object.keys(toUpdate).map((key) => {
            // console.log(`(${key},  ${toUpdate[key]})`)

            return formdata.append(key, toUpdate[key])

        })


        return axios
            .post(
                API_URL + `demandeVisas/update`,
                formdata
                ,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${currentUser.token}`,
                    },
                }
            )
            .then((response) => {
                console.log("response", response.status);
                if (response.status == 201) {
                    setMessage(response.data.message);
                    setSuccess(true);
                    Mtoast(response.data.message)
                    // window.location.reload(false)

                } else {
                    setMessage(response.data.message);
                    setError(true);
                    toast.error(response.data.message)

                }
                return response;
            })
            .catch((e) => {
                console.log("error", e);
                setSuccessful(false);
                toast.error('Failed to update!!')

                return null;
            })
            .finally(() => {
                setIsFetchUpdateEnded(true)
            })
            ;
    }


    useEffect(() => {
        const { cv, medical, contract, passport, visa, criminal, ...test } = data;
        Object.keys(test).map((key) => {
            return setValue(key, data[key])
        })
    }, [data])



    return (
        <>
            <div className="wrapper">
                <Sidebar color={color} image={hasImage ? image : ""} routes={dashboardRoutes} />
                <div className="main-panel" ref={mainPanel}>
                    <Header />
                    {(successful) ? (<div id="preloader"></div>) : (<> </>)}
                    <div className="content">
                        <Container >
                            <Row>
                                {/* <Row>
                                    {
                                        checkedTable?.all_check ?
                                            <div className="alert alert-success fs-5 text-center shadow">
                                                <i class="bi bi-info-square me-2"></i>
                                                {t('demandeProfile.allCheck')}
                                            </div>
                                            :
                                            <div className="alert alert-danger fs-5 text-center shadow">
                                                <i class="bi bi-info-square me-2"></i>
                                                {t('demandeProfile.pending')}

                                            </div>

                                    }
                                </Row> */}

                                <Row className="mt-2 mb-5 ms-2" >
                                    {!isFtechUpdatedEnded ? (
                                        <div id="preloader"></div>
                                    ) : (
                                        <> </>
                                    )}

                                    <div className=" d-flex justify-content-between ">

                                        <Link to={`/demandes/${encrypt(data?.id)}`} >
                                            <Button
                                                className="btn-fill pull-left  shadow border-0 shadow"
                                                variant="danger"
                                            >
                                                <i class="bi bi-arrow-left">
                                                </i>
                                                {t('tabForms.prev')}
                                            </Button>
                                        </Link>


                                    </div>
                                    {console.log('Errors :', errors)}

                                    <Row className="ml-1 justify-content-center" md="6" >
                                        <Col md="6">

                                            <div className="d-flex justify-content-center h6 shadow bg-white rounded p-4">

                                                {Object.keys(DEMANDE_STATUS).map((item) => {
                                                    return (


                                                        data.status !== DEMANDE_STATUS.VALIDATED.value && data.status === DEMANDE_STATUS[item].value && (<> <div className="me-2">Status : </div> <span className={DEMANDE_STATUS[item].className}> {DEMANDE_STATUS[item].label}</span></>)

                                                    )
                                                })

                                                }
                                                {data.status === DEMANDE_STATUS.VALIDATED.value && <ModalFormStartPay data={data} />}
                                            </div>
                                        </Col>

                                    </Row>


                                </Row>


                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    {(successful) ? (<div id="preloader"></div>) : (<> </>)}
                                    <Col md="12" >
                                        <Card className="shadow">
                                            <Card.Body>
                                                <Card.Header>
                                                    <Card.Title as="h2" className="text-center">{t('tabForms.titles.1')}</Card.Title>
                                                </Card.Header>
                                                <hr className="mx-3" />
                                                <div>


                                                    <div className='alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0 h4'>{t('tabForms.1.section-1')}</div>

                                                    <Row>

                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.passN')}</label>
                                                                <input type="text" className="form-control shadow-sm " {...register('numeroPasseport')} />
                                                                <span className="text-danger">{errors.numeroPasseport?.message}</span>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.passT')}</label> : {data.typePasseport}
                                                                <Select className={`shadow-sm`}
                                                                    id="typePasseport"
                                                                    name="typePasseport"
                                                                    options={typeVisas}
                                                                    defaultValue={{ label: data.typePasseport, value: data.typePasseport }}
                                                                    // defaultValue ={typeVisas[0]}

                                                                    onChange={(selected) => {
                                                                        setTypePassport(selected);
                                                                    }} />
                                                                {/* <span className='text-danger'> {errors.typePasseport?.message} </span> */}

                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.doi')}</label>
                                                                <input defaultValue={data?.dateEmission} type="date" className="form-control shadow-sm "  {...register('dateEmission')} />
                                                                <span className="text-danger">{errors.dateEmission?.message}</span>



                                                            </Form.Group>
                                                        </Col>



                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.poi')}</label>
                                                                <input defaultValue={data?.lieuEmission} type="text" className="form-control shadow-sm "  {...register('lieuEmission')} />
                                                                <span className="text-danger">{errors.lieuEmission?.message}</span>



                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.expDate')}</label>
                                                                <input defaultValue={data?.dateExpiration} type="date" className="form-control shadow-sm "  {...register('dateExpiration')} />
                                                                <span className="text-danger">{errors.dateExpiration?.message}</span>



                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="pr-1" md="6">

                                                            <Form.Group>
                                                                <label>{t('tabForms.1.poi')}</label>
                                                                <Form.Control className={`shadow-sm ${errors?.placeIssue?.message && 'border-danger'}`}
                                                                    placeholder={t('tabForms.1.poi')}
                                                                    name="placeIssue"
                                                                    type="text"
                                                                    {...register('placeIssue')}
                                                                ></Form.Control>
                                                                <span className='text-danger'> {errors.placeIssue?.message} </span>

                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>




                                                <div>
                                                    <div className='alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0 h4'>{t('tabForms.1.section-2')}</div>
                                                    <Row>

                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label> {t('tabForms.1.visaNumber')} </label>
                                                                <input defaultValue={data?.visaNumber} type="text" className="form-control shadow-sm "  {...register('visaNumber')} />
                                                                <span className="text-danger">{errors.visaNumber?.message}</span>



                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <label>  {t('tabForms.1.visaPurpose')}</label>
                                                                <input defaultValue={data?.visaPurpose} type="text" className="form-control shadow-sm "  {...register('visaPurpose')} />
                                                                <span className="text-danger">{errors.visaPurpose?.message}</span>



                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <label> {t('tabForms.1.tof')} </label>  : {data.typePasseport}
                                                                {/* <i defaultValue={data?.typeVisa} type="text" className="form-control shadow-sm " />  nput {...register('numeroPasseport')}*/}
                                                                {/* <span className="text-danger">{errors.numeroPasseport?.message}</span> */}


                                                                <Select className={`shadow-sm`}
                                                                    id="typeVisa"
                                                                    name="typeVisa"
                                                                    options={typeVisas}
                                                                    defaultValue={{ label: data.typePasseport, value: data.typePasseport }}
                                                                    onChange={(selected) => {
                                                                        setTypeVisa(selected);
                                                                    }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.toe')}</label> : {data.typeEntree}
                                                                {/* <i defaultValue={data?.typeEntree} type="text" className="form-control shadow-sm " />  nput {...register('numeroPasseport')}*/}
                                                                {/* <span className="text-danger">{errors.numeroPasseport?.message}</span> */}


                                                                <Select className={`shadow-sm`}
                                                                    id="typeEntree"
                                                                    name="typeEntree"
                                                                    options={typeEntrees}
                                                                    defaultValue={{ label: data.typeEntree, value: data.typeEntree }}
                                                                    onChange={(selected) => {
                                                                        setTypeEntree(selected);
                                                                    }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>


                                                    <Row>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.vsd')}</label>
                                                                <input defaultValue={data?.dateDepart} type="date" className="form-control shadow-sm "  {...register('dateDepart')} />
                                                                <span className="text-danger">{errors.dateDepart?.message}</span>



                                                            </Form.Group>
                                                        </Col>





                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label>{t('tabForms.1.ved')}</label>
                                                                <input defaultValue={data?.dateRetour} type="date" className="form-control shadow-sm "  {...register('dateRetour')} />
                                                                <span className="text-danger">{errors.dateRetour?.message}</span>



                                                            </Form.Group>
                                                        </Col>






                                                    </Row>

                                                </div>



                                                <div>

                                                    <div className='alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0 h4'>{t('tabForms.1.section-3')}</div>


                                                    <Row>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label> {t('tabForms.2.poe')}</label>
                                                                <input defaultValue={data?.portEntry} type="text" className="form-control shadow-sm "  {...register('portEntry')} />
                                                                <span className="text-danger">{errors.portEntry?.message}</span>



                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label> {t('tabForms.2.arrivalDate')} </label>
                                                                <input defaultValue={data?.arrivalDate} type="date" className="form-control shadow-sm "  {...register('arrivalDate')} />
                                                                <span className="text-danger">{errors.arrivalDate?.message}</span>



                                                            </Form.Group>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label> {t('tabForms.2.maxLengthStay')} </label>
                                                                <input defaultValue={data?.lengthStay} type="text" className="form-control shadow-sm "  {...register('lengthStay')} />
                                                                <span className="text-danger">{errors.lengthStay?.message}</span>



                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label> {t('tabForms.2.addressCmr')} </label>
                                                                <input defaultValue={data?.addressCmr} type="text" className="form-control shadow-sm "  {...register('addressCmr')} />
                                                                <span className="text-danger">{errors.addressCmr?.message}</span>




                                                            </Form.Group>
                                                        </Col>


                                                    </Row>

                                                    <Row>

                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label> {t('tabForms.2.destinations')}  </label>
                                                                <input defaultValue={data?.placeWork} type="text" className="form-control shadow-sm "  {...register('placeWork')} />
                                                                <span className="text-danger">{errors.placeWork?.message}</span>



                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="6">
                                                            <Form.Group>
                                                                <label>  {t('tabForms.2.modestrans')} </label> : {data.modestrans}
                                                                {/* <i defaultValue={data?.modeTransport} type="text" className="form-control shadow-sm " />  nput {...register('numeroPasseport')}*/}
                                                                {/* <span className="text-danger">{errors.modestrans?.message}</span> */}


                                                                <Select className={`shadow-sm`}
                                                                    id="modestrans"
                                                                    name="modestrans"
                                                                    options={modeTransports}
                                                                    defaultValue={{ label: data.modestrans, value: data.modestrans }}
                                                                    onChange={(selected) => {
                                                                        setTypeModeTrans(selected);
                                                                    }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>







                                                </div>



                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label> {t('tabForms.1.cc')} </label> : {data.codePays}

                                                            <Select className={`shadow-sm`}
                                                                id="codePays"
                                                                name="codePays"
                                                                options={countries}
                                                                defaultValue={{ label: data.codePays, value: data.codePays }}
                                                                onChange={(selected) => {
                                                                    setCodePays(selected);
                                                                }} />
                                                        </Form.Group>
                                                    </Col>



                                                </Row>



                                            </Card.Body>
                                        </Card>
                                    </Col>





















                                    <Col md="12" >
                                        <Card className="shadow">
                                            <Card.Header>
                                                <Card.Title as="h2" className="text-center">{t('tabForms.titles.2')}</Card.Title>
                                            </Card.Header>
                                            <hr className="mx-3" />
                                            <Card.Body>

                                                <Row>
                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.1.surname')}</label>
                                                            <input defaultValue={data?.prenom} type="text" className="form-control shadow-sm "  {...register('prenom')} />
                                                            <span className="text-danger">{errors.prenom?.message}</span>



                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.1.firstN')}</label>
                                                            <input defaultValue={data?.nom} type="text" className="form-control shadow-sm "  {...register('nom')} />
                                                            <span className="text-danger">{errors.nom?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                </Row>


                                                <Row>
                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.1.dob')}</label>
                                                            <input defaultValue={data?.dateNaissance} type="date" className="form-control shadow-sm "  {...register('dateNaissance')} />
                                                            <span className="text-danger">{errors.dateNaissance?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.1.bp')}</label>
                                                            <input defaultValue={data?.lieuNaissance} type="text" className="form-control shadow-sm "  {...register('lieuNaissance')} />
                                                            <span className="text-danger">{errors.lieuNaissance?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                </Row>



                                                <Row>

                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.1.gender')}</label> : {data.gender}
                                                            <Select className={`shadow-sm`}
                                                                id="gender"
                                                                name="gender"
                                                                options={genres}
                                                                defaultValue={{ label: data.gender, value: data.gender }}
                                                                onChange={(selected) => {
                                                                    setGender(selected);
                                                                }} />

                                                        </Form.Group>
                                                    </Col>


                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.1.nationality')}</label> : {data.nationalite}
                                                            {/* <i defaultValue={data?.nationalite} type="text" className="form-control shadow-sm " />  nput {...register('numeroPasseport')}*/}
                                                            {/* <span className="text-danger">{errors.numeroPasseport?.message}</span> */}


                                                            <Select className={`shadow-sm`}
                                                                id="nationalite"
                                                                name="nationalite"
                                                                options={countries}
                                                                defaultValue={{ label: data.nationalite, value: data.nationalite }}
                                                                onChange={(selected) => {
                                                                    setNationalite(selected);
                                                                }} />
                                                        </Form.Group>
                                                    </Col>


                                                </Row>
                                                <Row>


                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.ms')} <span className="text-danger">*</span></label> : {data.maritalStatus}
                                                            {/* <i defaultValue={data?.maritalStatus} type="text" className="form-control shadow-sm " />  nput {...register('numeroPasseport')}*/}
                                                            {/* <span className="text-danger">{errors.maritalStatus?.message}</span> */}


                                                            <Select className={`shadow-sm`}
                                                                id="maritalStatus"
                                                                name="maritalStatus"
                                                                options={countries}
                                                                defaultValue={{ label: data.maritalStatus, value: data.maritalStatus }}
                                                                onChange={(selected) => {
                                                                    setMaritalStatus(selected);
                                                                }} />
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.noc')}</label>
                                                            <input defaultValue={data?.nombreEnfants} type="text" className="form-control shadow-sm "  {...register('nombreEnfants')} />
                                                            <span className="text-danger">{errors.nombreEnfants?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                    </Row>




<Row>

                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.natLang')} *</label>
                                                            <input defaultValue={data?.nativeLanguage} type="text" className="form-control shadow-sm "  {...register('nativeLanguage')} />
                                                            <span className="text-danger">{errors.nativeLanguage?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                </Row>

















                                                <div className='alert alert-success text-uppercase shadow-sm mx-0 h4'>

                                                    {t('tabForms.2.jobDetails')}
                                                </div>





                                                <Row>

                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.jobTitle')} *</label>
                                                            <input defaultValue={data?.jobTitle} type="text" className="form-control shadow-sm "  {...register('jobTitle')} />
                                                            <span className="text-danger">{errors.jobTitle?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.contractED')} *</label>
                                                            <Form.Control className={`shadow-sm ${errors?.contractStart?.message && 'border-danger'}`}
                                                                type="date"
                                                                name="contractStart"
                                                                {...register('contractStart')}
                                                                defaultValue={data?.contractStart}
                                                            ></Form.Control>
                                                            <span className='text-danger'> {errors.contractStart?.message} </span>

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.contractEnd')} *</label>
                                                            <Form.Control className={`shadow-sm ${errors?.contractEnd?.message && 'border-danger'}`}
                                                                type="date"
                                                                name="contractEnd"
                                                                {...register('contractEnd')}


                                                            ></Form.Control>
                                                            <span className='text-danger'> {errors.contractEnd?.message} </span>

                                                        </Form.Group>
                                                    </Col>
                                                </Row>






                                                {/* <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.numberP')} *</label>
                                                            <input defaultValue={data?.numperiod} {data?.contractDuration} type="text" className="form-control shadow-sm "  {...register('numeroPasseport')} />
                                                                <span className="text-danger">{errors.numeroPasseport?.message}</span>




                                                        </Form.Group>
                                                    </Col>


                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>Unit</label>
                                                            <input defaultValue={data?.contractDuration} {data?.contractDuration} type="text" className="form-control shadow-sm "  {...register('numeroPasseport')} />
                                                                <span className="text-danger">{errors.numeroPasseport?.message}</span>




                                                        </Form.Group>
                                                    </Col> */}













                                                <div className='alert alert-success text-uppercase shadow-sm mx-0 h4'>{t('tabForms.2.salaryDetails')}</div>








                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.contractED')} </label>
                                                            <input defaultValue={data?.contractStart} type="date" className="form-control shadow-sm "  {...register('contractStart')} />
                                                            <span className="text-danger">{errors.contractStart?.message}</span>



                                                        </Form.Group>
                                                    </Col>


                                                    {/* <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.salary')} </label>
                                                            <input defaultValue={data?.salary} type="text" className="form-control shadow-sm " {...register('salary')} />
                                                            <span className="text-danger">{errors.salary?.message}</span>



                                                        </Form.Group>
                                                    </Col> */}

                                                </Row>






                                                <Row>
                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.bSalary')} </label>
                                                            <input defaultValue={data?.baseSalary} type="text" className="form-control shadow-sm "  {...register('baseSalary')} />
                                                            <span className="text-danger">{errors.baseSalary?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">


                                                        <Form.Group>
                                                            <label>{t('tabForms.2.currency')} </label>
                                                            <input defaultValue={data?.currency} type="text" className="form-control shadow-sm "  {...register('currency')} />
                                                            <span className="text-danger">{errors.currency?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                </Row>




                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.primes')} </label>
                                                            <input defaultValue={data?.prime} type="text" className="form-control shadow-sm "  {...register('prime')} />
                                                            <span className="text-danger">{errors.prime?.message}</span>



                                                        </Form.Group>
                                                    </Col>


                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.fees')} </label>
                                                            <input defaultValue={data?.professionalFees} type="text" className="form-control shadow-sm "  {...register('professionalFees')} />
                                                            <span className="text-danger">{errors.professionalFees?.message}</span>



                                                        </Form.Group>
                                                    </Col>
                                                </Row>




                                                <Row>

                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.allowances')} </label>
                                                            <input defaultValue={data?.indemnite} type="text" className="form-control shadow-sm "  {...register('indemnite')} />
                                                            <span className="text-danger">{errors.indemnite?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.email')}</label>
                                                            <input defaultValue={data?.email} type="text" className="form-control shadow-sm "  {...register('email')} />
                                                            <span className="text-danger">{errors.email?.message}</span>



                                                        </Form.Group>
                                                    </Col>


                                                </Row>



                                                <Row>


                                                    <Col className="pr-1" md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.1.phoneN')}</label>
                                                            <input defaultValue={data?.phone} type="text" className="form-control shadow-sm "  {...register('phone')} />
                                                            <span className="text-danger">{errors.phone?.message}</span>



                                                        </Form.Group>
                                                    </Col>





                                                </Row>












                                                <div className='alert alert-success text-uppercase shadow-sm mx-0 h4'>{t('tabForms.2.pAddress')}</div>
                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.houseNumber')} *</label>
                                                            <input defaultValue={data?.numeroMaisonPermanente} type="text" className="form-control shadow-sm "  {...register('numeroMaisonPermanente')} />
                                                            <span className="text-danger">{errors.numeroMaisonPermanente?.message}</span>



                                                        </Form.Group>
                                                    </Col>


                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.address1')} *</label>
                                                            <input defaultValue={data?.adresse1Permanente} type="text" className="form-control shadow-sm "  {...register('adresse1Permanente')} />
                                                            <span className="text-danger">{errors.adresse1Permanente?.message}</span>



                                                        </Form.Group>
                                                    </Col>


                                                </Row>


                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.address2')} *</label>
                                                            <input defaultValue={data?.adresse2Permanente} type="text" className="form-control shadow-sm "  {...register('adresse2Permanente')} />
                                                            <span className="text-danger">{errors.adresse2Permanente?.message}</span>



                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.city')} *</label>
                                                            <input defaultValue={data?.villePermanente} type="text" className="form-control shadow-sm "  {...register('villePermanente')} />
                                                            <span className="text-danger">{errors.villePermanente?.message}</span>



                                                        </Form.Group>
                                                    </Col>


                                                </Row>






                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.postal')} *</label>
                                                            <input defaultValue={data?.codePostalPermanente} type="text" className="form-control shadow-sm "  {...register('codePostalPermanente')} />
                                                            <span className="text-danger">{errors.codePostalPermanente?.message}</span>



                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.country')} *</label> : {data.paysPermanente}
                                                            {/* <i defaultValue={data?.paysPermanente} type="text" className="form-control shadow-sm " />  nput {...register('numeroPasseport')}*/}
                                                            {/* <span className="text-danger">{errors.paysPermanente?.message}</span> */}


                                                            <Select className={`shadow-sm`}
                                                                id="paysPermanente"
                                                                name="paysPermanente"
                                                                options={countries}
                                                                defaultValue={{ label: data.paysPermanente, value: data.paysPermanente }}
                                                                // defaultValue ={typeVisas[0]}

                                                                onChange={(selected) => {
                                                                    setTypePassport(selected);
                                                                }} />
                                                        </Form.Group>
                                                    </Col>

                                                </Row>





                                                <div className='alert alert-success text-uppercase shadow-sm mx-0 h4'>{t('tabForms.2.eContact')}</div>



                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>Full name</label>
                                                            <input defaultValue={data?.fullNameE} type="text" className="form-control shadow-sm "  {...register('fullNameE')} />
                                                            <span className="text-danger">{errors.fullNameE?.message}</span>



                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t('tabForms.2.phoneN')} *</label>
                                                            <input defaultValue={data?.phoneE} type="text" className="form-control shadow-sm "     {...register('phoneE')} />
                                                            <span className="text-danger">{errors.phoneE?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                </Row>




                                                <Row>
                                                    <Col md="6">
                                                        <Form.Group>
                                                            <label>{t("tabForms.2.email")} </label>
                                                            <input defaultValue={data?.emailE} type="text" className="form-control shadow-sm "   {...register('emailE')} />
                                                            <span className="text-danger">{errors.emailE?.message}</span>



                                                        </Form.Group>
                                                    </Col>

                                                </Row>



                                            </Card.Body>
                                        </Card>

                                        {/* Files */}
                                        <Card className="shadow">
                                            <Card.Body>

                                                <Card.Header>
                                                    <Card.Title as="h2" className="text-center">{t('tabForms.titles.3')}</Card.Title>
                                                </Card.Header>
                                                <hr className="mx-3" />



                                                <Row className="justify-content-between">
                                                    <Col md="6">
                                                        <label>{t('tabForms.3.medical')}</label> {data?.medical && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                                                        <input
                                                            className="form-control shadow-sm"
                                                            name="medical"
                                                            //   disabled={data?.status == ACCOUNT_STATUS.SUBMITTED  || data?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={(e) => { setMedical(e.target.files) }}
                                                        //   required={data?.immatriculation ? false : true}
                                                        />
                                                        <div className="text-danger">
                                                            {/* {errors.immatriculation?.message} */}
                                                        </div>

                                                    </Col>
                                                    <Col md="6">



                                                        <label>{t('tabForms.3.cv')}</label>{data?.cv && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                                                        <input
                                                            className="form-control shadow-sm"

                                                            name="cv"
                                                            //   disabled={data?.status == ACCOUNT_STATUS.SUBMITTED  || data?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={(e) => { setCv(e.target.files) }}
                                                        //   required={data?.nonredevance ? false : true}


                                                        />
                                                        <div className="text-danger">
                                                        </div>

                                                    </Col>
                                                </Row>

                                                <Row className="justify-content-between">
                                                    <Col md="6">
                                                        <label>{t('tabForms.3.passport')}</label> {data?.passport && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                                                        <input
                                                            className="form-control shadow-sm"
                                                            name="passport"
                                                            //   disabled={data?.status == ACCOUNT_STATUS.SUBMITTED  || data?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={(e) => { setPassport(e.target.files) }}
                                                        //   required={data?.immatriculation ? false : true}
                                                        />
                                                        <div className="text-danger">
                                                            {/* {errors.immatriculation?.message} */}
                                                        </div>

                                                    </Col>
                                                    <Col md="6">



                                                        <label>{t('tabForms.3.criminal')}</label>{data?.criminal && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                                                        <input
                                                            className="form-control shadow-sm"

                                                            name="criminal"
                                                            //   disabled={data?.status == ACCOUNT_STATUS.SUBMITTED  || data?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={(e) => { setCriminal(e.target.files) }}
                                                        //   required={data?.nonredevance ? false : true}


                                                        />
                                                        <div className="text-danger">
                                                        </div>

                                                    </Col>
                                                </Row>

                                                <Row className="justify-content-between">
                                                    <Col md="6">
                                                        <label>{t('tabForms.3.visa')}</label> {data?.visa && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                                                        <input
                                                            className="form-control shadow-sm"
                                                            name="visa"
                                                            //   disabled={data?.status == ACCOUNT_STATUS.SUBMITTED  || data?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={(e) => { setVisa(e.target.files) }}
                                                        //   required={data?.immatriculation ? false : true}
                                                        />
                                                        <div className="text-danger">
                                                            {/* {errors.immatriculation?.message} */}
                                                        </div>

                                                    </Col>
                                                    <Col md="6">



                                                        <label>{t('tabForms.3.contract')}</label>{data?.contract && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                                                        <input
                                                            className="form-control shadow-sm"

                                                            name="contract"
                                                            //   disabled={data?.status == ACCOUNT_STATUS.SUBMITTED  || data?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={(e) => { setContract(e.target.files) }}
                                                        //   required={data?.nonredevance ? false : true}


                                                        />
                                                        <div className="text-danger">
                                                        </div>

                                                    </Col>
                                                </Row>








                                            </Card.Body>
                                            {/* <div className="clearfix"></div> */}
                                            <div className="d-flex my-5 px-2 justify-content-between" >

                                                <Link to={`/demandes/${data?.id}`}>
                                                    <Button
                                                        className="btn-fill pull-left  shadow border-0 shadow"
                                                        variant="danger"
                                                    >
                                                        <i class="bi bi-arrow-left"></i>

                                                        {t('tabForms.prev')}
                                                    </Button>
                                                </Link>



                                                {(data?.status === DEMANDE_STATUS.REJECTED.value || data?.status === DEMANDE_STATUS.SUBMITTED.value) && <Button type="submit"
                                                    className="btn-fill pull-left  shadow border-0 shadow"
                                                    variant="primary"
                                                >

                                                    {t('update')}
                                                </Button>}


                                            </div>

                                        </Card>
                                    </Col>
                                </Form>
                            </Row>
                        </Container>
                    </div>

                    <Footer />
                </div>
            </div >
        </>)
}

export default EditDemande