import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { history } from '../_helpers';


const PrivateRoute = ({ children }) => {

    const { user: authUser } = useSelector(x => x.auth);

    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: history.location }} />
    }

    return children
}
export default PrivateRoute;