import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { yupResolver } from '@hookform/resolvers/yup';
import Footer from '../../components/Footer';
import FormHeader from '../../components/FormHeader';
import Header from '../../components/Header';
import { history } from '../../_helpers';
import { useForm } from 'react-hook-form';
import { register as registerFunction } from '../../actions/auth';
import { t } from 'i18next';
import { CLEAR_MESSAGE } from '../../actions/types';
import { Mtoast } from '../../components/utils/toast';

const Register = () => {
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const [successful, setSuccessful] = useState(false);

    YupPassword(Yup);


    useEffect(() => {
        // redirect to home if already logged in
        if (isLoggedIn) history.navigate('/dashboard');


        dispatch({
            type: CLEAR_MESSAGE
        });
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
        password: Yup.string().password().required('Password is required'),
        confirm_password: Yup.string().password().required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        name: Yup.string().required('Name is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ name, email, password, confirm_password }) {
        dispatch({
            type: CLEAR_MESSAGE
        });

        return dispatch(registerFunction({ name, email, password, confirm_password }))
            .then(() => {
                Mtoast('Registration successful!')
                // history.navigate('/verify?msg=successResetCode');
                history.navigate('/login');
            }).catch(() => {
                setSuccessful(false);
            });


    }

    return (
        <>
            <Header />
            <div className="content ">
                <div className="container">
                    <FormHeader />
                    <div className="row justify-content-center my-3  ">
                        <div className="col-md-6 border shadow bg-forms" >
                            <div className="mb-4 text-center">
                                <h3>{t("forms.signUp")}</h3>
                                {message && message[0] &&
                                    <div className="form-group">
                                        <div className={message[1] ? "alert alert-success" : "alert alert-danger"} role="alert">
                                            {message[0]}
                                        </div>
                                    </div>
                                }
                            </div>
                            <hr></hr>
                            <form onSubmit={handleSubmit(onSubmit)} className=' p-2 p-md-3'>
                                <div className="form-group first  my-2 ">
                                    <label>{t('forms.username')}  <span className='text-danger'>* </span> : </label>
                                    <input type="text" placeholder={t('forms.username')} {...register('name')} name="name" className={errors.name?.message ? "form-control border-danger" : "form-control"} id="name" />
                                    <div className="text-danger">{errors.name?.message}</div>
                                </div>
                                <div className="form-group first my-2 ">
                                    <label>{t('forms.email')}  <span className='text-danger'>* </span> : </label>
                                    <input type="email" placeholder={t('forms.email')} {...register('email')} name="email" className={errors.email?.message ? "form-control border-danger" : "form-control"} id="email" />
                                    <div className="text-danger">{errors.email?.message}</div>
                                </div>
                                <div className="form-group my-2 ">
                                    <label>{t("forms.pwd")}  <span className='text-danger'>* </span> : </label>
                                    <input type="password" placeholder={t("forms.pwd")} name="password" {...register('password')} className={errors.password?.message || errors.confirm_password?.message ? "form-control border-danger" : "form-control"} id="password" />
                                    <div className="text-danger">{errors.password?.message}</div>
                                </div>
                                <div className="form-group  my-2 last mb-4">
                                    <label>{t('forms.cpwd')}  <span className='text-danger'>* </span> : </label>
                                    <input type="password" placeholder={t('forms.cpwd')} {...register('confirm_password')} name="confirm_password" className={errors.confirm_password?.message ? "form-control border-danger" : "form-control"} id="confirmpassword" />
                                    <div className="text-danger">{errors.confirm_password?.message}</div>
                                </div>
                                <div className='alert alert-warning my-2 rounded shadow text-center'> <i className="bi bi-info-circle fs-6"></i> {t('passwordIndication')}</div>

                                <div className='row justify-content-center'>
                                    <div className='col-8 text-center my-4'>

                                        {isSubmitting && <div id="preloader"></div>}
                                        <input type="submit" value={t("forms.register")} className={Object.values(errors).length === 0 ? "btn btn-block bg-warning text-white" : "btn btn-outline-danger disabled"} />

                                    </div>

                                    {/* <label className="control control--checkbox mb-0"><span className="caption">Remember me</span>
                                                <input type="checkbox" checked="checked" />
                                                <div className="control__indicator"></div>
                                            </label> */}
                                    <span >
                                        <Link to="/login" className="forgot-pass text-white">{t('forms.alreadyR')}</Link></span>
                                </div>
                                {/* <span class="d-block text-left my-4 text-muted">&mdash; or login with &mdash;</span>

                                        <div class="social-login">
                                            <a href="#" class="facebook">
                                                <span class="icon-facebook mr-3"></span>
                                            </a>
                                            <a href="#" class="twitter">
                                                <span class="icon-twitter mr-3"></span>
                                            </a>
                                            <a href="#" class="google">
                                                <span class="icon-google mr-3"></span>
                                            </a>
                                        </div> */}
                            </form>
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Register;