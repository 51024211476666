
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Trans } from "react-i18next";
function VisaSteps() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={6000}
                keyBoardControl={true}
                customTransition="transform 600ms ease-in-out"
                // transitionDuration={3000}
                containerClass=""
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style d-none"
                itemClass="carousel-item-padding-40-px service-item position-relative"
            >
                <div className='item-content'>
                    <div className="icon">
                        <i class="bi bi-person-bounding-box"></i>
                    </div>
                    <h3>01 <i className="bi bi-arrow-right"></i></h3>
                    <p>
                        <Trans i18nKey="services.visaSept.1">

                            Login to the "Ework Permit Platform" and click on "New Visa Application"
                        </Trans>

                    </p>
                </div>

                <div className='item-content'>
                    <div className="icon">
                        <i class="bi bi-cursor"></i>
                    </div>
                    <h3>02 <i className="bi bi-arrow-right"></i></h3>
                    <p>
                        <Trans i18nKey="services.visaSept.2">

                            Complete the "Application Details", "send" and "Print" the Application form
                        </Trans>
                    </p>
                </div>

                <div className='item-content'>
                    <div className="icon">
                        <i class="bi bi-files"></i>
                    </div>
                    <h3>03 <i className="bi bi-arrow-right"></i></h3>
                    <p>
                        <Trans i18nKey="services.visaSept.3">

                            Deposit the hard copy of the Application and supporting documents for processing
                        </Trans>
                    </p>
                </div>

                <div className='item-content'>
                    <div className="icon">
                        <i class="bi bi-alarm"></i>
                    </div>
                    <h3>04 <i className="bi bi-arrow-right"></i></h3>
                    <p>
                        <Trans i18nKey="services.visaSept.4">
                            Receive notification and payment details
                        </Trans>
                    </p>
                </div>


                <div className='item-content' >
                    <div className="icon">
                        <i class="bi bi-credit-card"></i>
                    </div>
                    <h3>05</h3>
                    <p>
                        <Trans i18nKey="services.visaSept.5">


                            Choose the payment option
                        </Trans>
                    </p>
                </div>
            </Carousel>
        </div>

    )




}

export default VisaSteps