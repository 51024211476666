import { toast, Toaster, ToastBar } from 'react-hot-toast';

export function Mtoast(message) {
    toast.success(message, {
        duration: 6000,
        position: 'top-center',
    })
}


