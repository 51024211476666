import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
    render() {
        return (
            <>
                <footer id="footer" className="footer">
                    <div className="container">
                        <div className="row gy-4">
                            <div className=" col-md-6 footer-info">
                                <Link to="/" className="logo d-flex align-items-center">
                                    <img src="assets/img/logo.jpeg" alt="" />
                                </Link>
                                <p >
                                    <Trans i18nKey="footer.welcome">
                                        Welcome to the eWorkpermit platform. Foreigners seeking employment in the Republic of Cameroon must complete the online application form and submit online. Please <a href="assets/Drmo.pdf" className="btn-watch-video" download="Drmo.pdf">click here</a> for conditions to be met by persons of foreign nationality wishing to work in The Republic of Cameroon.
                                    </Trans>
                                </p>

                            </div>



                            <div className="col-md-3 footer-links">
                                <h4>
                                    <Trans i18nKey="footer.linksTitle">

                                        Useful Links
                                    </Trans>
                                </h4>
                                <ul>
                                    <li><Link to="/">
                                        <Trans i18nKey="links.home">
                                            Home
                                        </Trans>
                                    </Link></li>
                                    <li><Link to="/news">
                                        <Trans i18nKey="links.blog">
                                            Blog
                                        </Trans>
                                    </Link></li>
                                    <li><Link to="/contactUs">
                                        <Trans i18nKey="links.contact">
                                            Contact
                                        </Trans>
                                    </Link></li>
                                </ul>
                            </div>



                            <div className=" col-md-3 footer-contact  text-md-start">
                                {/* <h4>
                                    <Trans i18nKey="footer.contactUs">

                                        Contact Us
                                    </Trans>
                                </h4> */}
                                {/* <p>
                                    A108 Adam Street
                                    Yaoundé, NY 535022
                                    Cameroon
                                </p>
                                <p>
                                    <strong>Phone:</strong> +237 589 55488 55
                                </p> */}
                                {/* <p>
                                    <strong>Email:</strong> info@minefop.gov.cm
                                </p> */}

                            </div>

                        </div>

                        <hr></hr>
                        <div className='row'>
                            {/* <div className="credits">
                                Designed by <Link to="/">Alshadows Group</Link>
                            </div> */}

                            <div className="copyright">
                            &copy; Copyright <strong><span>eWorkPermit</span></strong>. All Rights Reserved
                        </div>
                        </div>
                    </div>

                    {/* <div className="container mt-4 ">
                        <div className="copyright">
                            &copy; Copyright <strong><span>Ework-Permit</span></strong>. All Rights Reserved
                        </div>

                    </div> */}
                </footer>
                <Link to="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>
            </>
        )
    }
}