

import { t } from "i18next"
import Header from "./Header";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { useState } from "react";
import Footer from "./Footer";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Mtoast } from "./utils/toast";
import { toast } from "react-hot-toast";


function ContactUs() {

    const [isfecEndded, setisfecEndded] = useState(true);
    const API_URL = `${process.env.REACT_APP_API_URL}/`;


    const handleSendMess = async ({ name, email, message }) => {
        console.log("name : ", name)
        console.log("email : ", email)
        console.log("message : ", message)


        setisfecEndded(false)
        fetch(API_URL + 'contactUs', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name,email,message})
        })
            // setTimeout(() => { }, 6000)
            .then(response => {
                // if (!response.ok) {
                //     throw Error(`HTTP error: ${response.status}`);
                // }
                return response.json();
            })
            .then((res) => {
                // console.log(res)
                if (res.success === true) {
                    Mtoast('Message successfull send !')

                }
            })
            .catch((err) => {
                toast.error('Your message connot bee sent. Please try aigainst!')
                console.log(err)
            })
            .finally(() => {
                setisfecEndded(true)
            })
            ;
    }



    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Your name is required'),
        email: Yup.string().email('Must be and valid email').required('Your email is required'),
        message: Yup.string().required('Your message is required').min(4)
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    // const { register, handleSubmit, formState } = useForm(formOptions);
    const { register, handleSubmit, formState } = useForm(formOptions);


    const { errors, isSubmitting } = formState;




    const inputStyle = {
        height: "4rem",
        // marginTop: "10px"
    }

    const areaStyle = {
        height: "8rem",
        // marginTop: "10px"
    }
    return (
        <>
            <Header />
            <div className="row justify-content-center ">

                <div className="col-10 col-md-6 shadow p-2 p-md-4 m-md-6 justify-content-center bg-forms" style={{ marginTop: "5%", marginBottom: "5%" }} >
                    <h1 className="text-center">
                        {t("contactUs.title")}
                    </h1>
                    <hr></hr>
                    <Row className="justify-content-center  ">
                        <div className="col-md-8">

                            <Form className="my-2 px-2 px-md-6 " onSubmit={handleSubmit(handleSendMess)}>
                                <Form.Group className="mt-md-3">
                                    <label>{t('contactUs.name')}<span className="text-danger">*</span> :</label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder={t('contactUs.name')}
                                        // required
                                        style={inputStyle}
                                        // onChange = {(text)=> setName(text.target.value)}
                                        className={errors.name?.message ? " border-danger" : ""}
                                        {...register('name')}
                                        disabled={!isfecEndded ? true : false}

                                    ></Form.Control>
                                    <div className="text-danger">{errors.name?.message}</div>

                                </Form.Group>
                                <Form.Group className="mt-md-3">
                                    <label>{t('forms.email')} <span className="text-danger">*</span> : </label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="name@example.com"
                                        // onChange = {(text)=> setEmail(text.target.value)}
                                        className={errors.email?.message ? " border-danger" : ""}

                                        style={inputStyle}
                                        {...register('email')}
                                        disabled={!isfecEndded ? true : false}

                                    ></Form.Control>
                                    <div className="text-danger">{errors.email?.message}</div>

                                </Form.Group>
                                <Form.Group className="mt-md-3">
                                    <label>Message <span className="text-danger">*</span> : </label>
                                    <Form.Control
                                        name="message"
                                        placeholder={t('contactUs.message')}
                                        style={areaStyle}
                                        as="textarea" rows={3}
                                        // onChange = {(text)=> setMessage(text.target.value)}
                                        className={errors.message?.message ? " border-danger" : ""}

                                        {...register('message')}
                                        disabled={!isfecEndded ? true : false}

                                    ></Form.Control>
                                    <div className="text-danger">{errors.message?.message}</div>

                                </Form.Group>



                                {!isfecEndded ?
                                    <Button
                                        type="submit"
                                        className="btn-fill pull-right  shadow border-0 shadow mt-4"
                                        // onClick={()=>handleSendMess()}
                                        variant="success"
                                        disabled={true}

                                    >
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Sending...
                                    </Button>
                                    :

                                    <Button
                                        type="submit"
                                        className="btn-fill pull-right  shadow border-0 shadow mt-4"
                                        // onClick={()=>handleSendMess()}
                                        variant="success"
                                        disabled={Object.values(errors).length === 0 ? false : true}

                                    >
                                        {t('contactUs.send')}
                                        <i class="bi bi-send-fill ms-2"></i>
                                    </Button>
                                }


                            </Form>
                        </div>
                    </Row>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs;