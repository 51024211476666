import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Mtoast } from '../../components/utils/toast';



export default function SendCodeFormModal({ setMessageModal }) {
    const API_URL = `${process.env.REACT_APP_API_URL}/`;
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Must be and valid email').required('Email is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    // const { register, handleSubmit, formState } = useForm(formOptions);
    const { register, handleSubmit, formState } = useForm(formOptions);


    const { errors, isSubmitting } = formState;


    const [open, setOpen] = React.useState(false);
    const [isfecEndded, setisfecEndded] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [res, setRes] = React.useState({})

    const handleSend = ({ email }) => {
        setisfecEndded(false)
        fetch(API_URL + `account/${email}/sendCode`)
            .then(response => {
                // if (!response.ok) {
                //     throw Error(`HTTP error: ${response.status}`);
                // }
                return response.json();
            })
            .then((res) => {
                // console.log(res)
                setRes(res)
                if (res.success === true) {
                    Mtoast(`New code has been Successfulyyy send to ${email} !`)
                    setOpen(false)
                }
            })
            .catch((err) => {
                console.log(err.message)
            })
            .finally(() => {
                setisfecEndded(true)
            })
            ;
    }

    return (
        <div>
            <Button variant="outlined" className='text-white border-white' onClick={handleClickOpen}>
                Send code again ?
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit(handleSend)} >

                    <DialogTitle>Send code</DialogTitle>
                    {res?.success === false && <div className='alert alert-danger text-center' role='alert'> {res?.message}</div>}
                    <DialogContent>

                        <DialogContentText>
                            The Code will be send to your email adddress
                        </DialogContentText>

                        <TextField

                            autoFocus
                            margin="dense"
                            id="name"
                            name="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            {...register('email')}
                            disabled={!isfecEndded ? true : false}

                            error={errors.email?.message ? true : false}
                        />
                        <div className="text-danger">{errors.email?.message}</div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        {!isfecEndded ? <div class="spinner-border text-info" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div> : <Button type='submit' >Send</Button>}
                    </DialogActions>
                </form>

            </Dialog>
        </div>
    );
}