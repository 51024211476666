import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Badge, Button, Container } from "react-bootstrap";
import axios from "axios";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import dashboardRoutes from "../routes";

import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import UseCinetPay from "../hooks/useCinetPay";
import ModalFormStartPay from "./ModalFormStartPay";
import { ACCOUNT_STATUS, DEMANDE_STATUS, encrypt } from "../GLOBAL_VARIABLE";
import { config } from "../constants/config";
import AddEmployerModal from "../app/pages/fntec/AddEmployerModal";
import dayjs from "dayjs";
import { history } from "../_helpers";
import { checkIsValidPermit } from "../utils";
const API_URL = `${process.env.REACT_APP_API_URL}/`;

function ForeinWorkers() {
  const navigate = useNavigate();
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [loading, setloading] = React.useState(false);
  const mainPanel = React.useRef(null);
  const [employees, setemployees] = React.useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [compte, setCompte] = React.useState({});
  const [selectedEmployer, setSetselectedEmployer] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setSetselectedEmployer(null)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSetselectedEmployer(null)
    // fetechEmployees()

  };

  const fetchCompte = () => {
    setloading(true);
    axios
      .get(API_URL + "comptes" + "/" + currentUser?.user?.compte_id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        if(response.data.data.status != ACCOUNT_STATUS.VALIDATED){
          navigate("/user");
        }
        // setloading(false);
        setCompte(response.data?.data);
        // if(response.data?.data?.immatriculation) setShowimmatriculation(true)
        console.log("compte : ", response.data?.data);
      })
      .catch((e) => {
        navigate("/user");
        // setloading(false);
        setCompte(null);
      });
  };
  // const [loadingEm, setLoadingEm] = useState(false);

  const fetechEmployees = () => {
    // setloading(true);
    axios
      .get(
        config.app.fntec_url +
        `/enterprises/search-by-nui/${compte?.nui}/employers`,
        {
          headers: {
            // Authorization: `Bearer ${currentUser.token}`,
          },
        }
      )
      .then((response) => {
        // setloading(false);
        setemployees(response.data?.data);
      })
      .catch((e) => {
        // setloading(false);
        setemployees(null);
      })
      .finally(()=>setloading(false))
      ;
  };

  // console.log("employees ==> ", employees)
  React.useEffect(() => {
    fetchCompte();
  }, []);

  React.useEffect(() => {
    compte && fetechEmployees();
  }, [compte]);

  useEffect(() => {
    if (selectedEmployer) {
      setOpen(true)
    }
  }, [selectedEmployer]);

  const handleClick = (url, filename) => {
    console.log(url);
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "application.pdf";
        alink.click();
      });
    });
  };
  // const [loading, errorp, startPayment] = UseCinetPay();

  // const startPay = (item) => {
  //   startPayment({
  //     amount: item.salary * 2,
  //   });
  // };

  return (
    loading ? <div id="preloader"></div> : <>
      <AddEmployerModal open={open} handleClose={handleClose} fetch={fetechEmployees} employer={selectedEmployer} />
      <div className="wrapper">
        <Sidebar
          color={color}
          image={hasImage ? image : ""}
          routes={dashboardRoutes}
        />
        <div className="main-panel" ref={mainPanel}>
          <Header />
           
          <div className="content">
            <Container fluid style={{ overflow: "auto", minHeight: "" }}>
              <div className="d-flex flex-row justify-content-end">
                <Button
                  type="button"
                  onClick={handleClickOpen}
                  variant="success"
                  className="btn btn-outlined btn-success mt-3 addEmpl"
                >
                  <i className="bi bi-plus me-2"></i>
                  {t("foreignworkers.addEmployer.buttons.add")}
                </Button>
              </div>
              <h4 className="text-center text-uppercase">
                {t("foreignworkers.title")} 
                <span className="badge badge-info ms-2">{employees?.length}</span>
              </h4>

              <div class="table-responsive ">
                <table
                  id="example"
                  className="table align-items-center mb-0 shadow rounded"
                // style={{ border: "3px black" }}
                >
                  <thead>
                    <tr role="button"

                    >
                      {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {" "}
                        {t("dashboard.table.appID")}{" "}
                      </th> */}
                      {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {" "}
                        {t("dashboard.table.lastName")}{" "}
                      </th> */}
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("foreignworkers.addEmployer.personalInfos.fullName")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.pn")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.nat")}
                      </th>
                      {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.sDate")}
                      </th> */}
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.status")}
                      </th>
                      {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("dashboard.table.action")}
                      </th> */}
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(employees)} */}
                    {employees &&
                      employees.map((emp) => {
                        // console.log("🚀 ~ employees.map ~ visa:", visa)
                        return (
                          <tr
                          className={(emp?.permitDate && checkIsValidPermit(emp))?.isValid ? 'text-success' : 'text-danger'} 

                            role="button"
                            onClick={() => {
                              setSetselectedEmployer(emp)
                            }}
                            style={{
                              // backgroundColor: !checkIsValidPermit(emp) ? "rgba(222,71,106,0.4)" : "",
                              // color : checkIsValidPermit(emp) ?"white": "white"
                            }}
                          // className={checkIsValidPermit(emp) ?"bg-alert-danger": "bg-alert-danger"}
                          >
                            {/* <td>{visa?.demande_id}</td> */}
                            <td className="text-xs font-weight-bold mb-0 ">
                              {emp?.firstName != null && emp?.lastName != null ? emp?.firstName +" "+emp?.lastName : emp?.fullName}
                            </td>
                            {/* <td>{visa?.prenom}</td> */}
                            <td>{emp?.passportNumber}</td>
                            <td>{emp?.nationality}</td>
                            {/* <td>
                              {visa?.visaOptDate &&
                                dayjs(visa?.visaOptDate).format("YYYY-MM-DD")}
                            </td> */}
                            <td>
                              {(emp?.permitDate && checkIsValidPermit(emp))?.isValid ? <span className="badge badge-success p-2"> 
                              {t(checkIsValidPermit(emp)?.message)} </span>: <span className="badge badge-danger p-2">{t(checkIsValidPermit(emp)?.message)}</span>}
                            </td>
                            {/* <td>
                              <Link to={`/demandes/${encrypt(visa?.id)}`}>
                                <Button
                                  className="btn-fill pull-right  shadow border-0"
                                  type="submit"
                                  variant="warning"
                                >
                                  <i className="bi bi-eye"></i>
                                </Button>
                              </Link>
                            </td> */}
                            <td>
                              {/* {!checkIsValidPermit(emp)?.isValid
                                ? */}
                                  <span
                                  className="badge badge-info p-2 fs-6 requests"
                                  variant="primary"
                                  role="button"
                                  onClick={() => {
                                    navigate("/demandes-by-employee", {
                                      state: { employer: emp },
                                    });
                                  }}
                                >
                                  {t("Demandes")}
                                  <i className="bi bi-list ms-2"/>
                                </span>
                                <Button
                                  className="badge text-primary p-2 border border-primary fs-6 ms-2 newPermit"
                                  variant="primary "
                                  // target="_blank"
                                  // disabled={checkIsValidPermit(emp)}
                                  role="button"
                                  onClick={() => {
                                    navigate("/form", {
                                      state: { employer: emp },
                                    });
                                  }}
                                >
                                  {t("Initier une nouvelle demande")}
                                  <i className="bi bi-plus-circle ms-2"/>
                                </Button>

                              
                                
                                {/* : <></>
                              } */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr></tr>
                  </tfoot>
                </table>
              </div>
            </Container>
          </div>
          <Footer />
        </div>
      </div>
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
    </>
  );
}

export default ForeinWorkers;
