import React from "react";
// react-bootstrap components
import {
    Button,
    Container,
} from "react-bootstrap";
import axios from "axios";
import fileDownload from 'js-file-download'
import { useSelector } from "react-redux";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import dashboardRoutes from "../routes";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ACCOUNT_STATUS, DEMANDE_STATUS, encrypt } from "../GLOBAL_VARIABLE";
import ModalFormStartPay from "./ModalFormStartPay";
import moment from "moment";
const API_URL = `${process.env.REACT_APP_API_URL}/`;

function VisaApproved() {
    const [image, setImage] = React.useState(sidebarImage);
    const [color, setColor] = React.useState("black");
    const [hasImage, setHasImage] = React.useState(true);
    const [successful, setSuccessful] = React.useState(false);
    const mainPanel = React.useRef(null);
    const [demandes, setDemandes] = React.useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const fetchDemande = () => {
        setSuccessful(true);
        axios
            .get(API_URL + "demandeVisas" + "/user/" + currentUser?.user?.id, {
                headers: {
                    "Authorization": `Bearer ${currentUser.token}`
                }
            })
            .then((response) => {
                console.log('response : ', response)
                setSuccessful(false);
                setDemandes(response.data.data.data);
            })
            .catch((e) => {
                setSuccessful(false);
                setDemandes(null);
            });
    }

    React.useEffect(() => {
        fetchCompte();
        fetchDemande();
    }, [currentUser]);

    const handleClick = (url, filename) => {
        console.log(url)
        fetch(url).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'application.pdf';
                alink.click();
            })
        })
    }



  const fetchCompte = () => {
    axios
      .get(API_URL + "comptes" + "/" + currentUser?.user?.compte_id, {
        headers: {
          "Authorization": `Bearer ${currentUser.token}`
        }
      })
      .then((response) => {
        if(response.data.data.status != ACCOUNT_STATUS.VALIDATED){
          navigate("/user");
        }
      })
      .catch((e) => {
        navigate("/user");
      });
  }


    return (
        <>
            <div className="wrapper" >
                <Sidebar color={color} image={hasImage ? image : ""} routes={dashboardRoutes} />
                <div className="main-panel" ref={mainPanel}>
                    <Header />
                    {(successful) ? (<div id="preloader"></div>) : (<> </>)}
                    <div className="content">
                        <Container fluid style={{ overflow: 'auto' }} className="rounded">

                            <h4 className="text-center">{t('approuvedVisa.title')} <span className="badge bg-info">{demandes?.length || 0}</span>
                            </h4>
                            <div className="table-responsive ">

                                <table id="example" className="table align-items-center shadow rounded" >
                                    <thead >
                                        <tr>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} > {t('dashboard.table.appID')} </th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} > {t('dashboard.table.lastName')} </th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.firstName')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.pn')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.nat')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.sDate')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.status')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.iDate')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.expDate')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >{t('dashboard.table.action')}</th>
                                            <th className="fw-bolder text-dark" style={{ fontSize: "0.8vw" }} >PDF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {demandes &&
                                            demandes.map((visa) => {
                                                return (
                                                    visa?.status == DEMANDE_STATUS.EWORKPERMIT.value ?
                                                        <tr>
                                                            <td>{visa?.demande_id}</td>
                                                            <td>{visa?.nom}</td>
                                                            <td>{visa?.prenom}</td>
                                                            <td>{visa?.numeroPasseport}</td>
                                                            <td>{visa?.nationalite}</td>
                                                            <td>
                                                                {
                                                                 moment(visa?.created_at).format()
                                                                    }
                                                            </td>
                                                            <td>

                                                                {
                                                                    Object.keys(DEMANDE_STATUS).map((item) => {
                                                                        return (

                                                                            visa?.status !== DEMANDE_STATUS.VALIDATED.value && visa?.status === DEMANDE_STATUS[item].value && (<span className={DEMANDE_STATUS[item].className}> {DEMANDE_STATUS[item].label}</span>)

                                                                        )
                                                                    })

                                                                }
                                                                {visa?.status === DEMANDE_STATUS.VALIDATED.value && <ModalFormStartPay data={visa} />}

                                                            </td>
                                                            <td>{moment(visa?.delivery).format()}</td>
                                                            <td>{moment(visa?.expiry).format()}</td>
                                                            <td>
                                                                <Link to={`/demandes/${encrypt(visa?.id)}`} >

                                                                    <Button
                                                                        className="btn-fill pull-right  shadow border-0"
                                                                        type="submit"
                                                                        variant="warning"
                                                                    >
                                                                        <i className="bi bi-eye"></i>
                                                                    </Button>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    className="btn btn-fill pull-right  shadow border-0"
                                                                    variant="info"
                                                                    target="_blank"
                                                                    href={visa?.doc}
                                                                    download={visa?.demande_id}
                                                                >
                                                                    <i className="bi bi-download"></i>
                                                                </a>
                                                            </td>
                                                        </tr>

                                                        : ""

                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>

                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </Container>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
}

export default VisaApproved;
