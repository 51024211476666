import Footer from "../components/Footer/Footer";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import React, { Component, useEffect, useState } from 'react';

import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col
} from "react-bootstrap";
import dashboardRoutes from "../routes";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import { useSelector } from "react-redux";
import {
    Link,
    useParams
} from "react-router-dom";
import { Input } from "reactstrap";

function Intervention() {
    const [color, setColor] = React.useState("black");
    const [image, setImage] = React.useState(sidebarImage);
    const [hasImage, setHasImage] = React.useState(true);
    const mainPanel = React.useRef(null);
    const [demandes, setDemandes] = React.useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [successful, setSuccessful] = useState(false);
    const [mes, setMes] = useState([
        "Message 1",
        "Message 2",
        "Message 3"
    ]);
    let { id } = useParams();

    const chatBox = (user, mes) => {
        if (user === "me") {
            return (
                <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight bg-primary rounded shadow m-2  p-2">
                        {mes}
                        <div className="h6">
                            <hr className="p-0 m-0"></hr>
                            11/02/2022</div>
                    </div>
                </div>
            )

        } else {
            return (
                <div class="d-flex flex-row bd-highlight mb-3">
                    <div class="p-2 bd-highlight bg-success rounded shadow m-2 p-2 ">
                        Response {mes}
                        <div className="h6">
                            <hr className="p-0 m-0"></hr>
                            11/02/2022</div>
                    </div>
                </div>
            )
        }

    }


    function handleMessage(event){
        setMes(event.target.value)
    }

    const messages = [
        "Message 1",
        "Message 2",
        "Message 3"
    ]

    const handleSendMessage = () => {

        const wrapper = document.getElementById('chat-wrapper');
        const chat = chatBox("me", {mes});
        console.log('chat' , chat)
        wrapper.innerHTML = wrapper.innerHTML + chatBox("me", {mes});
    }

    return (
        <div className="wrapper">
            <Sidebar color={color} image={hasImage ? image : ""} routes={dashboardRoutes} />
            <div className="main-panel" ref={mainPanel}>
                <Header />
                {/* {(successful) ? (<div id="preloader"></div>) : (<> </>)} */}
                <div className="content">
                    <Container >
                        <Row>
                            <Link to={`/demandes/${id}`} >
                                <Button
                                    className="btn-fill pull-left  shadow border-0 shadow"
                                    variant="danger"
                                >
                                    <i class="bi bi-arrow-left">
                                    </i>
                                    Back
                                </Button>
                            </Link>
                        </Row>

                        <h1 className="text-center border-bottom  p-2 m-5">
                            <span className="badge badge-danger bg-danger mx-1">9</span>
                            Interventions
                        </h1>

                        <Row className="justify-content-center m-2">
                            <div className="col-6">
                                <div className="row justify-content-center ">

                                    <Form>
                                        <div className="col">

                                            <Form.Group>
                                                <Form.Control
                                                    defaultValue=""
                                                    placeholder="Your message here"
                                                    name="nationalite"
                                                    type="text"
                                                    onChange={handleMessage}
                                                    style={{ width: "20rem" }}
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col">
                                            <div className="btn btn-primary" onClick={() => handleSendMessage()}>
                                                Send <i class="bi bi-send-fill"></i></div>
                                            
                                        </div>
                                    </Form>

                                </div>

                            </div>

                        </Row>

                        <Row className=" justify-content-center my-4">
                            <div className=" col-6 p-5 border rounded shadow" style={{ backgroundColor: "rgba(20,100,200,0.3)" }} id="chat-wrapper">

                                {chatBox('res', "mesLOre mlererj dfdiujure dfj")}

                             

                            </div>
                        </Row>

                    </Container>
                </div>

                <Footer />
            </div>
        </div>

    )
}

export default Intervention