import { ACCOUNT_STATUS } from "./GLOBAL_VARIABLE";
import ApplicationByEmployee from "./views/ApplicationByEmployee";
import Dashboard from "./views/Dashboard";
import DemandeVisaProfile from "./views/DemandeVisaProfile";
import ForeinWorkers from "./views/ForeignWorkers";
import TabForm from "./views/TabForm";
import UserProfile from "./views/UserProfile";
import UserRealProfile from "./views/UserRealProfile";
import VisaApproved from "./views/VisaApproved";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    tour: "menu1",
    icon: "bi bi-speedometer",
    component: Dashboard,
    layout: "/admin",
    status: ACCOUNT_STATUS.VALIDATED
  },
  {
    path: "/demandes-by-employee",
    name: "Application",
    icon: "bi bi-speedometer",
    component: ApplicationByEmployee,
    layout: "/admin",
    status: ACCOUNT_STATUS.VALIDATED
  },
  {
    path: "/user",
    name: "Company Profile",
    icon: "bi bi-person-gear",
    tour: "menu2",
    component: UserProfile,
    layout: "/admin",
    status: ACCOUNT_STATUS.SUBMITTED
  },
  {
    path: "/profile",
    name: "User Profile",
    icon: "bi bi-person-circle",
    component: UserRealProfile,
    layout: "/admin",
    status: ACCOUNT_STATUS.SUBMITTED
  },
  {
    path: "/form",
    name: "New Visa Application",
    // icon: "nc-icon nc-badge text-dark",
    icon: "bi bi-person-vcard",
    component: TabForm,
    layout: "/admin",
    status: ACCOUNT_STATUS.VALIDATED
  },

  

  {
    path: "/approvedVisa",
    name: "Approved visas",
    icon: "bi bi-check2-circle",
    tour: "menu3",
    component: VisaApproved,
    layout: "/admin",
    status: ACCOUNT_STATUS.VALIDATED
  },

  {
    path: "/foreignWorkers",
    name: "foreign workers",
    icon: "bi bi-people",
    tour: "menu4",
    component: ForeinWorkers,
    layout: "/admin",
    status: ACCOUNT_STATUS.VALIDATED
  },

];

export default dashboardRoutes;
