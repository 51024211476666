import { t } from "i18next";
import { useState } from "react";
import Joyride from 'react-joyride';

const TourPermit = () => {
    const [run, setRun] = useState(true);

    const steps = [
        {
            target: '.menu',
            content: t("demo.permit.start"),
            placement: 'center'
        },
        {
            target: '.menu1',
            content: t('demo.permit.menu1'),
        },
        {
            target: '.menu2',
            content: t('demo.permit.menu2'),
        },
        {
            target: '.menu3',
            content: t('demo.permit.menu3'),
        },
        {
            target: '.menu4',
            content: t('demo.permit.menu4'),
        }
    ];

    return (
        <Joyride
            locale={{
                back:t("demo.Back"),
                close: t("demo.Close"),
                last: t("demo.Last"),
                next: t("demo.Next"),
                open: t("demo.Open"),
                skip: t("demo.Skip")
            }}
            steps={steps}
            run={run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
        />
    );
};

export default TourPermit;