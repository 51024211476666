import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useSelector } from "react-redux";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import dashboardRoutes from "../routes";
import * as Yup from "yup";
import "yup-phone";
import { yupResolver } from "@hookform/resolvers/yup";

import Select from "react-select";

import { t } from "i18next";
import { Mtoast } from "../components/utils/toast";
import { toast } from "react-hot-toast";
import { ACCOUNT_STATUS } from "../GLOBAL_VARIABLE";
import ConfirmDialog from "./ConfirmDialog";
import { config } from "../constants/config";
import useDataFetching from "../hooks/useDataFetching";
import TourPermit from "../components/utils/TourPermit";

const API_URL = `${process.env.REACT_APP_API_URL}/`;

function User() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [countries, setCountries] = React.useState([]);
  const [paysLocale, setPaysLocale] = useState([]);

  // form validation rules
  const validationSchema = Yup.object().shape({
    nomEntreprise: Yup.string('Must be a Text format').required(t('required')),
    sigle: Yup.string('Must be a Text format').required(t('required')),
    phoneEntreprise: Yup.string('Must be a Text format').phone("", "", t('errorPhone')).required(t('required')),
    emailEntreprise: Yup.string('Must be a Text format').email().required(t('required')),
    //industrie: Yup.string('Must be a Text format').required(t('required')),
    // nombreEmployes: Yup.string('Must be a Text format').required(t('required')),

    nombreNationaux: Yup.string('Must be a Text format').required(t('required')),
    nombreExpatries: Yup.string('Must be a Text format').required(t('required')),
    comRegister: Yup.string('Must be a Text format').required(t('required')),
    nui: Yup.string('Must be a Text format').min(3).required(t('required')),
    nomPrimaire: Yup.string('Must be a Text format').required(t('required')),
    phonePrimaire: Yup.string('Must be a Text format').phone("", "", t('errorPhone')).required(t('required')),
    emailPrimaire: Yup.string('Must be a Text format').email().required(t('required')),

    nomGeneral: Yup.string('Must be a Text format').required(t('required')),
    phoneGeneral: Yup.string('Must be a Text format').phone("", "", t('errorPhone')).required(t('required')),
    emailGeneral: Yup.string('Must be a Text format').email().required(t('required')),

    nomRH: Yup.string('Must be a Text format').required(t('required')),
    phoneRH: Yup.string('Must be a Text format').phone("", "", t('errorPhone')).required(t('required')),
    emailRH: Yup.string('Must be a Text format').email().required(t('required')),


    adresse1Permanente: Yup.string('Must be a Text format').required(t('required')),
    adresse2Permanente: Yup.string('Must be a Text format').required(t('required')),
    villePermanente: Yup.string('Must be a Text format').required(t('required')),
    // regionPermanente: Yup.string('Must be a Text format').required(t('required')),
    codePostalPermanente: Yup.string('Must be a Text format'),

    adresse1Locale: Yup.string('Must be a Text format').required(t('required')),
    // adresse2Locale: Yup.string('Must be a Text format').required(t('required')),
    villeLocale: Yup.string('Must be a Text format').required(t('required')),
    // regionLocale: Yup.string('Must be a Text format').required(t('required')),
    codePostalLocale: Yup.string('Must be a Text format'),


    // immatriculation : Yup.mixed().required(t('required')),
    // nonredevance : Yup.mixed().required(t('required')),
    // numeroMaisonPermanente: Yup.string('Must be a Text format').min(3).required(t('required')),
    // numeroMaisonLocale: Yup.string('Must be a Text format').min(3).required(t('required')),

  });
  const [compte, setCompte] = useState({});
  const formOptions = { resolver: yupResolver(validationSchema) };
  console.log("🚀 ~ User ~ compte:", compte?.lenght)
  // const [showimmatriculation, setShowimmatriculation] = useState(false)

  const [immatriculation, setimmatriculation] = useState()
  const [nonredevance, setnonredevance] = useState()
  const [organigram, setorganigram] = useState()

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [successful, setSuccessful] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [message, setMessage] = useState("");
  const [paysPermanente, setPaysPermanente] = useState("");
  const [activitySectors, setActivitySectors] = useState([]);
  const [activitySector, setActivitySector] = useState("");
  let formdata = new FormData();


  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.fntec_url}/regions`
  );
  const [regionLocale, setregionLocale] = useState();
  const [department, setdepartment] = useState(null)
  const [arrondissement, setArrondissement] = useState(null)
  // fetcing departments by regionLocale id
  const [loadingD, errorD, departments, fetchDataD] = useDataFetching(
    `${config.app.fntec_url}/regions/${regionLocale !== '*' && regionLocale}/departments`
  );

  // fetcing arrondisements by department id
  const [loadingA, errorA, arrondisements, fetchDataA] = useDataFetching(
    `${config.app.fntec_url}/departments/${department}/arrondissements`
  );
  const fetchCompte = () => {
    setSuccessful(true);
    axios
      .get(API_URL + "comptes" + "/" + currentUser?.user?.compte_id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        setSuccessful(false);
        response?.data?.data && setCompte(response?.data?.data);
        // if(response.data.data.immatriculation) setShowimmatriculation(true)
        console.log('compte : ', response?.data?.data);
      })
      .catch((e) => {
        setSuccessful(false);
        // setCompte(null);
      });
  };

  const fetchCountry = () => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        setCountries(response.data);
        let countrie = [];
        response.data.forEach((data) => {
          countrie.push({ label: data.name.common, value: data.cca2 });
        });
        setCountries(
          countrie.sort((a, b) => {
            const nameA = a.label.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchActivitySector = () => {
    setSuccessful(true);
    axios
      .get(
        config.app.fntec_url +
        `/activity-sectors`,
        {
          headers: {
            // Authorization: `Bearer ${currentUser.token}`,
          },
        }
      )
      .then((response) => {
        setSuccessful(false);
        // console.log("🚀 ~ .then ~ response.data?.data:", response?.data?.data)
        setActivitySectors(response?.data?.data?.map((d) => {
          return { label: d?.name, value: d?.id }
        }));
      })
      .catch((e) => {
        setSuccessful(false);
        setActivitySectors(null);
      });
  };
  useEffect(() => {
    fetchCountry();
    fetchActivitySector()
  }, []);

  useEffect(() => {
    fetchCompte();
  }, [currentUser]);

  const [isFtechUpdatedEnded, setIsFetchUpdateEnded] = useState(true)

  async function onSubmi(
    {
      sigle,
      nomEntreprise,
      phoneEntreprise,
      emailEntreprise,
      //industrie,

      nombreNationaux,
      nombreExpatries,
      comRegister,
      nui,
      nomPrimaire,
      phonePrimaire,
      emailPrimaire,
      nomGeneral,
      phoneGeneral,
      emailGeneral,
      nomRH,
      phoneRH,
      emailRH,
      adresse1Permanente,
      adresse2Permanente,

      // paysPermanente, 
      villePermanente,
      // regionPermanente,
      codePostalPermanente,
      adresse1Locale,
      // adresse2Locale,
      villeLocale,
      // regionLocale,
      codePostalLocale,


    }) {


    console.log('Let submit !')
    let toUpdate = {
      sigle,
      nomEntreprise,
      phoneEntreprise,
      emailEntreprise,
      industrie: "fake",
      nombreEmployes: parseInt(nombreExpatries) + parseInt(nombreNationaux),
      nombreNationaux,
      nombreExpatries,
      comRegister,
      nui,
      nomPrimaire,
      phonePrimaire,
      emailPrimaire,
      nomGeneral,
      phoneGeneral,
      emailGeneral,
      nomRH,
      phoneRH,
      emailRH,
      adresse1Permanente,
      adresse2Permanente,
      paysPermanente,
      villePermanente,
      regionPermanente: "FakeRegion",
      codePostalPermanente,
      adresse1Locale,
      adresse2Locale: "FakeAddess2Locale",
      villeLocale,

      regionLocale,
      codePostalLocale,
      arrondissement,
      department,
      activitySector,
      // immatriculation: immatriculation[0],
      // nonredevance: nonredevance[0],
      // organigram: organigram[0],
      compte_id: currentUser?.user?.compte_id
    }

    if (immatriculation != null) toUpdate.immatriculation = immatriculation[0]
    if (nonredevance != null) toUpdate.nonredevance = nonredevance[0]
    if (organigram != null) toUpdate.organigram = organigram[0]
    if (paysPermanente == null || paysPermanente === "" || paysPermanente === undefined) { toUpdate.paysPermanente = compte?.paysPermanente }
    if (regionLocale == null || regionLocale === "" || regionLocale === undefined) { toUpdate.regionLocale = compte?.regionLocale }
    if (department == null || department === "" || department === undefined) { toUpdate.department = compte?.department }
    if (arrondissement == null || arrondissement === "" || arrondissement === undefined) { toUpdate.arrondissement = compte?.arrondissement }
    if (activitySector == null || activitySector === "" || activitySector === undefined) { toUpdate.activitySector = compte?.activitySector }
    // else {
    //   toUpdate.paysPermanente = paysPermanente
    // }


    console.log('tuUpdate : ', toUpdate)

    Object.keys(toUpdate).map((key) => {
      console.log(`(${key},  ${toUpdate[key]})`)
      // if (key === 'paysPermanente' && toUpdate[key] === undefined) 
      // return formdata.append(key, compte?.paysPermanente)
      // } else if (key === 'paysLocale' && toUpdate[key] === undefined) {
      //   return formdata.append(key, compte?.paysLocale)
      // }
      // else {

      return formdata.append(key, toUpdate[key])
      // }
    })
    setIsFetchUpdateEnded(false)

    return axios
      .post(
        API_URL + "comptes/update",
        formdata
        ,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${currentUser.token}`,
          },
        }
      )
      .then(async (response) => {
        console.log("response", response.status);
        if (response.status == 201) {
          setMessage(response.data.message);
          setSuccess(true);
          Mtoast(response.data.message)
          // if (compte?.status === ACCOUNT_STATUS.CREATED || compte?.status === ACCOUNT_STATUS.REJECTED) {
          //   const res = handleSend()
          // }
          // fetchCompte()

          window.location.reload(false)


        } else {
          setMessage(response.data.message);
          setError(true);
          toast.error(response.data.message)

        }
        return response;
      })
      .catch((e) => {
        console.log("error update information", e?.response);
        setSuccessful(false);
        toast.error('Failed to update!!')

        return null;
      })
      .finally(() => {
        setIsFetchUpdateEnded(true)
      })
      ;
  }

  const handleSend = async () => {
    // e.preventDefault();
    alert(t('validInfo'))

    setSuccessful(true);
    return await axios
      .put(API_URL + "comptes/envoyer/" + currentUser?.user?.compte_id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("response", response.status);
        setSuccessful(false);
        if (response.status == 201) {
          setMessage(response.data.message);
          setSuccess(true);
          Mtoast(response.data.message)
          // window.location.reload(false)
        } else {
          setMessage(response.data.message);
          toast.error(response.data.message)
          setError(true);
        }
        return response;
      })
      .catch((e) => {
        console.log("error", e);
        setSuccessful(false);
        toast.error('Failed to send!!')
        return null;
      });
  };

  useEffect(() => {
    console.log("🚀 ~ compte&&Object.keys ~ compte:", compte)
    compte && Object.keys(compte).map((key) => {
      if (compte[key] === null)
        return setValue(key, "")
      else
        return setValue(key, compte[key])

    })
    if (compte) {
      setregionLocale(compte?.regionLocale)
      setdepartment(compte?.department)
      setArrondissement(compte?.arrondissement)
    }
  }, [compte])

  return (
    <>
      <div className="wrapper">
        {console.log("errors : ", errors)}
        <Sidebar
          // color={color}
          image={hasImage ? image : ""}
          routes={dashboardRoutes}
        />
        <div className="main-panel" ref={mainPanel}>
          <Header />
          <div className="content ">
            {/* {compte?.numeroContribuale != null && ( */}
            {compte != undefined && <Container fluid>

              <Row>
                <Col md="12">
                  <Card className=" border-0 rounded shadow">

                    <Card.Header>

                      {isSubmitting || successful || !isFtechUpdatedEnded ? (
                        <div id="preloader"></div>
                      ) : (
                        <> </>
                      )}
                      {error && (
                        <Alert dismissible={true} key="danger" variant="danger">
                          {message}
                        </Alert>
                      )}
                      {success && (
                        <Alert
                          dismissible={true}
                          key="success"
                          variant="success"
                          className="text-center"
                        >
                          {message}
                        </Alert>
                      )}
                      {compte && (compte?.status == ACCOUNT_STATUS.CREATED) ?
                        <Alert
                          closeLabel="Close"
                          key="danger"
                          variant="danger"
                          className="text-center"
                        >
                          {t("userProfile.statusAccountNotValidate")}
                        </Alert> : (<></>)}
                      {compte && (compte?.status == ACCOUNT_STATUS.SUBMITTED) ?
                        <Alert
                          closeLabel="Close"
                          key="success"
                          variant="success"
                          className="text-center"
                        >
                          {t("userProfile.statusAccountSubmitted")}
                        </Alert> : (<></>)}
                      {compte && (compte?.status == ACCOUNT_STATUS.VALIDATED) ?
                        <Alert
                          closeLabel="Close"
                          key="success"
                          variant="success"
                          className="text-center"
                        >
                          {t("userProfile.statusAccountValidated")}
                        </Alert> : (<></>)}
                      {compte && (compte?.status == ACCOUNT_STATUS.REJECTED) ?
                        <Alert
                          closeLabel="Close"
                          key="danger"
                          variant="danger"
                          className="text-center"
                        >
                          {t("userProfile.statusAccountRejected")}
                        </Alert> : (<></>)}
                    </Card.Header>
                    <Card.Body>

                      <form onSubmit={handleSubmit(onSubmi)}>

                        <Card.Title as="h4" className="">
                          <div className='alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0'>
                            {t("userProfile.titles.1")}{" "}
                          </div>
                        </Card.Title>
                        <Row className="row row-cols-1 row-cols-sm-2">
                          <Col>
                            <label>{t("userProfile.compName")} <span className='text-danger'>*</span></label>
                            {/* <input defaultValue={compte?.induxtrie} type="text" value={compte?.nomEntreprise}/> */}
                            <input
                              // defaultValue={compte?.nomEntreprise}
                              className={errors?.nomEntreprise?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              name="nomEntreprise"
                              {...register("nomEntreprise")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.nomEntreprise?.message}
                            </div>
                          </Col>
                          <Col>
                            <label>{t("userProfile.sigle")} <span className='text-danger'>*</span></label>
                            {/* <input defaultValue={compte?.induxtrie} type="text" value={compte?.nomEntreprise}/> */}
                            <input
                              // defaultValue={compte?.nomEntreprise}
                              className={errors?.sigle?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              name="sigle"
                              {...register("sigle")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.sigle?.message}
                            </div>
                          </Col>
                          <Col >
                            <label>{t("userProfile.pEmail")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.emailEntreprise}
                              className={errors?.emailEntreprise?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="emailEntreprise"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("emailEntreprise")}
                              // placeholder={t('userProfile.pEmail')}
                              type="email"
                            />
                            <div className="text-danger">
                              {errors?.emailEntreprise?.message}
                            </div>
                          </Col>

                          <Col >
                            <label>{t("userProfile.phoneN")} (exp : +237....) <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.phoneEntreprise}
                              name="phoneEntreprise"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              className={errors?.phoneEntreprise?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // placeholder = "Phone"

                              {...register("phoneEntreprise")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.phoneEntreprise?.message}
                            </div>
                          </Col>

                          {/* {console.log('Error : ', errors)} */}
                          <Col>
                            <label>{t("userProfile.nbFN")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.nombreNationaux}
                              className={errors?.nombreNationaux?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              name="nombreNationaux"
                              // placeholder={t('userProfile.nbFN')}
                              {...register("nombreNationaux")}
                              type="number"
                            />
                            <div className="text-danger">
                              {errors?.nombreNationaux?.message}
                            </div>
                          </Col>




                          <Col >
                            <label>{t("userProfile.nbExp")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.nombreExpatries}
                              className={errors?.nombreExpatries?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="nombreExpatries"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              // placeholder={t('userProfile.nbExp')}
                              {...register("nombreExpatries")}
                              type="number"
                            />
                            <div className="text-danger">
                              {errors?.nombreExpatries?.message}
                            </div>
                          </Col>


                          {/* <Col md="6">
                            <label>{t("userProfile.nbEmp")} <span className='text-danger'>*</span></label>
                            <input defaultValue={nombreEmployes}
                            // <input value={compte?.nombreExpatries + compte?.nombreNationaux}

                              className={errors?.nombreEmployes?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="nombreEmployes"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED  || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              disabled={true}


                              // placeholder={t('userProfile.nbEmp')}
                              {...register("nombreEmployes")}
                              type="number"
                            />
                            <div className="text-danger">
                              {errors?.nombreEmployes?.message}
                            </div>
                          </Col> */}




                          <Col >
                            <label>{t("userProfile.nui")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.nui}
                              className={errors?.nui?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="nui"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("nui")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.nui?.message}
                            </div>
                          </Col>

                          <Col >
                            <label>{t("userProfile.comRegistre")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.comRegister}
                              className={errors?.comRegister?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="comRegister"
                              {...register("comRegister")}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.comRegister?.message}
                            </div>
                          </Col>


                          {/* <Col md="6">
                            <label>{t("userProfile.industry")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.industrie}
                              className={errors?.industrie?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="industrie"
                              //disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("industrie")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.industrie?.message}
                            </div>
                          </Col> */}

                          <Col>
                            <label>{t("userProfile.activity sector")} <span className='text-danger'>*</span></label>
                            {/* <select defaultValue={compte?.industrie}
                              className={errors?.industrie?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="industrie"
                              //disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("industrie")}
                              type="text"
                            /> */}
                            {compte?.activitySector ? <span className="ms-2">{t('current')} : {activitySectors?.find((d) => d?.value == compte?.activitySector)?.label}</span> : <></>}



                            <Select
                              id="activitySector"
                              name="activitySector"
                              className="shadow-sm"
                              // defaultInputValue={}
                              // isDisabled={compte?.status === ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              options={activitySectors}
                              // value={paysPermanente}
                              onChange={(selected) => {
                                console.log(selected?.label)
                                setActivitySector(selected?.value);
                              }}
                              required={compte?.activitySector ? false : true}
                            />
                            {/* <div className="text-danger">
                              {errors?.industrie?.message}
                            </div> */}

                          </Col>


                        </Row>








                        {/* //Doncuments */}



                        <Card.Title as="h4" className="">
                          <div className='alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0'>{t('userProfile.titles.docsCompany')}</div>
                        </Card.Title>
                        <Row className="justify-content-between">
                          <Col md="6">
                            <label>{t("userProfile.immatriculation")} <span className='text-danger'>*</span></label> {compte?.immatriculation && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                            <input defaultValue={compte?.immatriculation}
                              className={errors?.nui?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="immatriculation"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              type="file"
                              accept=".pdf"
                              onChange={(e) => { setimmatriculation(e.target.files) }}
                              required={compte?.immatriculation ? false : true}
                            />
                            <div className="text-danger">
                              {errors?.immatriculation?.message}
                            </div>

                          </Col>
                          <Col md="6">



                            <label>{t('userProfile.docRegistre')} <span className='text-danger'>*</span></label>{compte?.nonredevance && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                            <input defaultValue={compte?.nonredevance}
                              className={errors?.nonredevance?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="nonredevance"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              type="file"
                              accept=".pdf"
                              onChange={(e) => { setnonredevance(e.target.files) }}
                              required={compte?.nonredevance ? false : true}


                            />
                            <div className="text-danger">
                              {errors?.nonredevance?.message}
                            </div>

                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <label>{t('userProfile.organigram')} <span className='text-danger'>*</span></label>{compte?.organigram && <span className="text-success"> ({t('Received')} <i className="bi bi-check-circle ">)</i></span>}

                            <input defaultValue={compte?.organigram}
                              className={errors?.organigram?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="organigram"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              type="file"
                              accept=".pdf"
                              onChange={(e) => { setorganigram(e.target.files) }}
                              required={compte?.organigram ? false : true}


                            />
                            <div className="text-danger">
                              {errors?.organigram?.message}
                            </div>
                          </Col>
                        </Row>


                        {/* Human Ressource */}

                        <div className='text-primary  mx-0 mt-3 mb-1 border-bottom'> {t('userProfile.titles.HRContact')} </div>
                        <Row className="row row-cols-1 row-cols-sm-2">
                          <Col>
                            <label>{t("userProfile.contName")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.nomRH}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              className={errors?.nomRH?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}

                              name="nomRH"
                              // placeholder={t('userProfile.contName')}
                              {...register("nomRH")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.nomRH?.message}
                            </div>
                          </Col>
                          <Col >
                            <label>{t("userProfile.cEmail")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.emailRH}
                              className={errors?.emailRH?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              placeholder={
                                compte?.emailRH == null ? "" : compte?.emailRH
                              }
                              // placeholder={t('userProfile.cEmail')}
                              name="emailRH"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("emailRH")}
                              type="email"
                            />
                            <div className="text-danger">
                              {errors?.emailRH?.message}
                            </div>
                          </Col>

                          <Col>
                            <label>{t("userProfile.contPhone")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.phoneRH}
                              className={errors?.phoneRH?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              placeholder={
                                compte?.phoneRH == null ? "" : compte?.phoneRH
                              }
                              {...register("phoneRH")}
                              name="phoneRH"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              // placeholder={t('userProfile.contPhone')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.phoneRH?.message}
                            </div>
                          </Col>
                        </Row>


                        {/* Contact Details */}

                        <Card.Title as="h4" className="">
                          <div className='alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0'>  {t("userProfile.titles.2")}{" "}</div>
                        </Card.Title>

                        <div className='text-primary  mx-0 mt-3 mb-1 border-bottom'> {t('userProfile.titles.primaryContact')} </div>
                        <Row>
                          <Col md="6">
                            <label>{t("userProfile.contName")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.nomPrimaire}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              className={errors?.nomPrimaire?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}

                              name="nomPrimaire"
                              // placeholder={t('userProfile.contName')}
                              {...register("nomPrimaire")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.nomPrimaire?.message}
                            </div>
                          </Col>
                          <Col md="6">
                            <label>{t("userProfile.cEmail")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.emailPrimaire}
                              className={errors?.emailPrimaire?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              placeholder={
                                compte?.emailPrimaire == null ? "" : compte?.emailPrimaire
                              }
                              // placeholder={t('userProfile.cEmail')}
                              name="emailPrimaire"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("emailPrimaire")}
                              type="email"
                            />
                            <div className="text-danger">
                              {errors?.emailPrimaire?.message}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <label>{t("userProfile.contPhone")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.phonePrimaire}
                              className={errors?.phonePrimaire?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              placeholder={
                                compte?.phonePrimaire == null ? "" : compte?.phonePrimaire
                              }
                              {...register("phonePrimaire")}
                              name="phonePrimaire"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              // placeholder={t('userProfile.contPhone')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.phonePrimaire?.message}
                            </div>
                          </Col>
                        </Row>


                        {/* Genaral */}

                        <div className='text-primary  mx-0 mt-3 mb-1 border-bottom'> {t('userProfile.titles.GMContact')}</div>
                        <Row>
                          <Col md="6">
                            <label>{t("userProfile.contName")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.nomGeneral}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              className={errors?.nomGeneral?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}

                              name="nomGeneral"
                              // placeholder={t('userProfile.contName')}
                              {...register("nomGeneral")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.nomGeneral?.message}
                            </div>
                          </Col>
                          <Col md="6">
                            <label>{t("userProfile.cEmail")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.emailGeneral}
                              className={errors?.emailGeneral?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              placeholder={
                                compte?.emailGeneral == null ? "" : compte?.emailGeneral
                              }
                              // placeholder={t('userProfile.cEmail')}
                              name="emailGeneral"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("emailGeneral")}
                              type="email"
                            />
                            <div className="text-danger">
                              {errors?.emailGeneral?.message}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <label>{t("userProfile.contPhone")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.phoneGeneral}
                              className={errors?.phoneGeneral?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              placeholder={
                                compte?.phoneGeneral == null ? "" : compte?.phoneGeneral
                              }
                              {...register("phoneGeneral")}
                              name="phoneGeneral"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              // placeholder={t('userProfile.contPhone')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.phoneGeneral?.message}
                            </div>
                          </Col>
                        </Row>










                        <Card.Title as="h4" className="">
                          <div className='alert alert-success text-uppercase mt-5 mb-0 py-0 shadow-sm mx-0'>  {t("userProfile.titles.3")}{" "}</div>
                        </Card.Title>

                        <div className='text-primary  mx-0 mt-3 mb-1 border-bottom'> {t("userProfile.titles.HOAddress")}</div>


                        <Row>
                          <Col md="6">
                            <label>{t("userProfile.street")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.adresse1Permanente}
                              className={errors?.adresse1Permanente?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              name="adresse1Permanente"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              {...register("adresse1Permanente")}
                              // placeholder={t('userProfile.address1')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.adresse1Permanente?.message}
                            </div>
                          </Col>


                          {/* <Col md="6">
                            <label>{t("userProfile.state")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.regionPermanente}
                              // placeholder={t('userProfile.state')}
                              className={errors?.regionPermanente?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED  || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              name="regionPermanente"
                              {...register("regionPermanente")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.regionPermanente?.message}
                            </div>
                          </Col> */}





                          <Col md="6">
                            <label>{t("userProfile.town")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.villePermanente}
                              // placeholder={t('userProfile.town')}

                              className={errors?.villePermanente?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              name="villePermanente"
                              {...register("villePermanente")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.villePermanente?.message}
                            </div>
                          </Col>




                        </Row>
                        <Row>

                          <Col md="6">
                            <label>{t("tabForms.2.state")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.adresse2Permanente}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              className={errors?.adresse2Permanente?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}

                              name="adresse2Permanente"
                              {...register("adresse2Permanente")}
                              // placeholder={t('userProfile.address2')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.adresse2Permanente?.message}
                            </div>
                          </Col>

                          <Col md="6">
                            <label>{t("userProfile.postal")} </label>
                            <input defaultValue={compte?.codePostalPermanente}
                              className={errors?.codePostalPermanente?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // placeholder={t('userProfile.postal')}

                              name="codePostalPermanente"
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("codePostalPermanente")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.codePostalPermanente?.message}
                            </div>
                          </Col>
                        </Row>

                        <Row>


                          <Col className="" md="6">
                            <label>{t("userProfile.countryP")} <span className='text-danger'>*</span></label>
                            {compte?.paysPermanente ? <span className="ms-2">{t('current')} : {compte?.paysPermanente}
                            </span> : <></>}


                            <Select
                              id="paysPermanente"
                              name="paysPermanente"
                              className="shadow-sm"
                              // isDisabled={compte?.status === ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              options={countries}
                              // value={paysPermanente}
                              onChange={(selected) => {
                                // console.log(selected?.label)
                                setPaysPermanente(selected?.label);
                              }}
                              required={compte?.paysPermanente ? false : true}
                              defaultInputValue={compte?.paysPermanente ? compte?.paysPermanente : ""}
                            // defaultInputValue={compte?.paysPermanente}
                            />
                            <div className="text-danger">
                              {errors?.paysPermanente?.message}
                            </div>
                          </Col>
                        </Row>









                        <div className='text-primary  mx-0 mt-3 mb-1 border-bottom '>  {t('tabForms.2.titleLocal')}</div>
                        <Row className="row row-cols-1 row-cols-sm-3">
                          <Col >
                            <label>{t("userProfile.street")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.adresse1Locale}
                              className={errors?.adresse1Locale?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              name="adresse1Locale"
                              {...register("adresse1Locale")}
                              // placeholder={t('userProfile.address1')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.adresse1Locale?.message}
                            </div>
                          </Col>

                          <Col>
                            <label>{t("userProfile.town")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.villeLocale}
                              // placeholder={t('userProfile.town')}
                              className={errors?.villeLocale?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              name="villeLocale"
                              {...register("villeLocale")}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.villeLocale?.message}
                            </div>
                          </Col>

                          <Col >
                            <label>{t("userProfile.postal")} </label>
                            <input defaultValue={compte?.codePostalLocale}
                              className={errors?.codePostalLocale?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              // disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}

                              name="codePostalLocale"
                              {...register("codePostalLocale")}
                              // placeholder={t('userProfile.postal')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.codePostalLocale?.message}
                            </div>
                          </Col>
                        </Row>
                        <Row>

                          {/* <Col md="6">
                            <label>{t("tabForms.2.state")} <span className='text-danger'>*</span></label>
                            <input defaultValue={compte?.adresse2Locale}
                              disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              className={errors?.adresse2Locale?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}

                              {...register("adresse2Locale")}
                              name="adresse2Locale"
                              // placeholder={t('userProfile.address2')}
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.adresse2Locale?.message}
                            </div>
                          </Col> */}



                          {/* <Col md="6">
                            <label>{t("tabForms.2.state")} <span className='text-danger'>*</span></label>

                            <input defaultValue={compte?.regionLocale}
                              // placeholder={t('userProfile.state')}

                              className={errors?.regionLocale?.message ? "form-control shadow-sm border-danger" : "form-control shadow-sm"}
                              //disabled={compte?.status == ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              {...register("regionLocale")}
                              name="regionLocale"
                              type="text"
                            />
                            <div className="text-danger">
                              {errors?.regionLocale?.message}
                            </div>
                          </Col> */}

                          <Col>
                            {/* <label>{t("userProfile.activity sector")} <span className='text-danger'>*</span></label> */}
                            <label>{t("tabForms.2.state")} <span className='text-danger'>*</span></label>
                            {compte?.regionLocale ? <span className="ms-2">{t('current')} : {regions?.data?.find((d) => d?.id == compte?.regionLocale)?.name}</span> : <></>}

                            <Select
                              id="region"
                              name="region"
                              // value={9}
                              // defaultValue={2}
                              // value={2}
                              // isDisabled={compte?.status === ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              // inputValue="centre"
                              defaultInputValue={compte?.regionLocale}

                              // className="shadow-sm"
                              className={errors?.regionLocale?.message ? "shadow-sm border-danger" : "shadow-sm"}

                              options={regions?.data?.map((r) => { return { label: r?.name, value: r?.id } })}
                              onChange={(selected) => {
                                // console.log(selected?.label)
                                setregionLocale(selected?.value);
                                setdepartment('')
                                setArrondissement('')

                              }}
                              required={compte?.regionLocale ? false : true}

                            />

                            <div className="text-danger">
                              {/* {errors?.industrie?.message} */}
                              {errors?.regionLocale?.message}

                            </div>

                          </Col>

                          <Col>
                            {/* <label>{t("userProfile.activity sector")} <span className='text-danger'>*</span></label> */}
                            <label>{t("userProfile.departement")} <span className='text-danger'>*</span></label>
                            {compte?.department ? <span className="ms-2">{t('current')} : {departments?.data?.find((d) => d?.id == compte?.department)?.name}</span> : <></>}
                            <Select
                              id="department"
                              name="department"
                              // isDisabled={compte?.status === ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              // className="shadow-sm"
                              defaultInputValue={compte?.department}

                              className={errors?.department?.message ? "shadow-sm border-danger" : "shadow-sm"}

                              options={departments?.data?.map((r) => { return { label: r?.name, value: r?.id } })}
                              onChange={(selected) => {
                                // console.log(selected?.label)
                                setdepartment(selected?.value);
                                setArrondissement('')
                              }}
                            // required={compte?.paysPermanente ? false : true}
                            />

                            <div className="text-danger">
                              {/* {errors?.industrie?.message} */}
                              {errors?.department?.message}

                            </div>

                          </Col>



                          <Col>
                            {/* <label>{t("userProfile.activity sector")} <span className='text-danger'>*</span></label> */}
                            <label>{t("userProfile.arrondissement")} <span className='text-danger'>*</span></label>
                            {compte?.arrondissement ? <span className="ms-2">{t('current')} : {arrondisements?.data?.find((d) => d?.id == compte?.arrondissement)?.name}</span> : <></>}

                            <Select
                              id="arrondissement"
                              name="arrondissement"
                              defaultInputValue={compte?.arrondissement}

                              // className="shadow-sm"
                              // isDisabled={compte?.status === ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                              // value={arrondissement || ""}
                              className={errors?.arrondissement?.message ? "shadow-sm border-danger" : "shadow-sm"}

                              options={arrondisements?.data?.map((r) => { return { label: r?.name, value: r?.id } })}
                              onChange={(selected) => {
                                // console.log(selected?.label)
                                setArrondissement(selected?.value);
                              }}
                            // required={compte?.paysPermanente ? false : true}
                            />

                            <div className="text-danger">
                              {/* {errors?.industrie?.message} */}
                              {errors?.arrondissement?.message}

                            </div>

                          </Col>



                        </Row>

                        <Row className="justify-content-end mt-3">
                          {/* {(compte?.status === ACCOUNT_STATUS.SUBMITTED || compte?.status === ACCOUNT_STATUS.REJECTED) && ( */}
                          <Col className="" md="2">
                            <Button
                              className="btn-fill pull-right  shadow border-0 text-center"
                              type="submit"
                              // disabled={compte?.status === ACCOUNT_STATUS.VALIDATED || compte?.status === ACCOUNT_STATUS.SUBMITTED ? true : false}
                              variant="info"
                            >
                              {/* <ConfirmDialog compte={compte} handleSubmit={handleSubmit} onSubmi={onSubmi} /> */}
                              {`${t("userProfile.update")}  ${compte?.status === ACCOUNT_STATUS.CREATED || compte?.status === ACCOUNT_STATUS.REJECTED ? "& " + t("userProfile.valid.0") : ''}`}
                            </Button>
                          </Col>
                          {/* )} */}

                          {/* <Col className="" md="2">
                            {
                              (compte?.status < ACCOUNT_STATUS.VALIDATED || compte?.status === ACCOUNT_STATUS.REJECTED || compte?.status === ACCOUNT_STATUS.UNLOCKED) &&
                              <Button
                                className="btn-fill pull-right  shadow border-0 shadow"
                                onClick={handleSend}
                                disabled={compte?.status == ACCOUNT_STATUS.VALIDATED || compte?.status === ACCOUNT_STATUS.VALIDATED ? true : false}
                                variant="success"
                              >

                                {t("userProfile.valid.0")}
                              
                              </Button>}
                          </Col> */}
                        </Row>
                        <div className="clearfix"></div>
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>}
            {/* )} */}
          </div>
          <Footer />
        </div>
      </div>
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
    </>
  );
}

export default User;
