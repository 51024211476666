
//theme
//core
import "primereact/resources/primereact.min.css";


import React, { useEffect, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { CircularProgress, FormControl, FormLabel } from "@mui/material";
import { config } from "../../constants/config";
import { CancelRounded, CheckBoxRounded, DeleteForever, InfoRounded } from "@mui/icons-material";
import { t } from "i18next";
import { FILE_MAX_SIZE } from "../../GLOBAL_VARIABLE";
import axios from "axios";
import { useSelector } from "react-redux";

// import { FILE_MAX_SIZE } from "../../constants/GLOBAL_VARIALABELS";
const API_URL = `${process.env.REACT_APP_API_URL}`;

export default function FileUploader({ label, name, setDocuments, documents, url, errors, delete_url, setDemandeVisa, required }) {
    // console.log("🚀 ~ FileUploader ~ documents:", documents)
    const { user: currentUser } = useSelector((state) => state.auth);
    const [progress, setProgress] = useState(false)
    const [success, setSuccess] = useState(false)
    const [uploaded, setUploaded] = useState(documents[name] ? true : false)
    const [error, setError] = useState(null)
    const [doc, setDoc] = useState(null)
    const [uploadedFile, setUploadedFile] = useState(null);
    function getDoc(name) {
        return documents[name] || null
    }

    function check() {
        let d = getDoc(name);
        setUploaded(d ? true : false)
        setDoc(d)
    }
    useEffect(() => {
        check()
    }, [documents]);

    // const customBase64Uploader = async (event) => {
    //     alert("yes")
    //     // convert file to base64 encoded
    //     const uploadedFile = event.files;
    //     // Access your uploaded file details here: name, size, type, etc.
    //     setUploadedFile(uploadedFile);
    //     let docs = documents;
    //     docs[name] = uploadedFile
    //     setDocuments(docs)
    //     check()
    //     if (errors && errors[name]) {
    //         errors[name] = null
    //     }

    //     const file = event.files[0];
    //     const reader = new FileReader();
    //     try {


    //         let response = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    //         console.log("🚀 ~ customBase64Uploader ~ await:" + response)
    //     }
    //     catch (error) {
    //         console.log("🚀 ~ customBase64Uploader ~ error:", error)

    //     }

    // };

    const onUpload = (event) => {
        const uploadedFile = event.files;
        // Access your uploaded file details here: name, size, type, etc.
        setUploadedFile(uploadedFile);
        let docs = documents;
        docs[name] = uploadedFile
        setDocuments(docs)
        // alert("test")
        check()
        if (errors && errors[name]) {
            errors[name] = null
        }
        // if (setphpbLoaded) { setphpbLoaded(true) }
        setSuccess(true)
        setUploaded(true)
        setProgress(false)
        setError(null)
        // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const onProgress = () => {
        setSuccess(false)
        setUploaded(false)
        setError(null)
        setProgress(true)
    };
    const onError = () => {
        setSuccess(false)
        setProgress(false)
        setUploaded(false)

        setError("Failed to upload")
        // if (setphpbLoaded) { setphpbLoaded(false) }

    };
    const handleDelete = (nameP) => {
        console.log("let delete")
        setProgress(true)
        axios
            .delete(API_URL + delete_url + nameP, {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            })
            .then((response) => {
                console.log("🚀 ~ .then ~ response:", response)
                //   setSuccessful(false);
                //   setCompte(response.data.data);
                setDemandeVisa(response?.data?.data)
                let dd = documents;
                delete dd[name]
                setDocuments(dd)
                setProgress(false)
                setUploaded(false)
                check()


            })
            .catch((e) => {
                console.log("🚀 ~ handleDelete ~ e:", e)
                //   setSuccessful(false);
                //   setCompte(null);
                setProgress(false)

            });
    }
    // const accetps = "image/*,.pdf,.doc,.docx,.odt,.ods,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    const accetps = ".pdf"
    return (<>
        <FormControl className="col mb-3">
            <FormLabel className={error ? "text-danger" : uploaded ? "text-success" : ""}>{label} {required && <span className="text-danger fs-6">*</span>} </FormLabel>

            <div className=" d-flex justify-content-start align-items-center p-0">
                {url &&
                    <FileUpload mode="basic" name={name}
                        url={API_URL + url}
                        accept={accetps}
                        maxFileSize={FILE_MAX_SIZE * 1000000}

                        // customUpload={true}
                        // uploadHandler={customBase64Uploader}
                        onUpload={onUpload}
                        removeIcon={<DeleteForever />}
                        onProgress={onProgress}
                        onError={onError}
                        auto
                        disabled={progress}
                        
                    
                        chooseLabel={ progress ? t("Uploading") : uploaded ? t("Replace") : t("Browse")}
                        className={`btn btn-primary ${uploaded ? "btn-success" : "btn-primary"}  rounded shadow d-flex`}
                        onValidationFail={(e) => setError(`${t('Max file Size is')} :  ${FILE_MAX_SIZE} Mb`)}
                        // style={{backgroundColor : 'red'}}
                        
                       
                    />
                }

                {
                    progress && <CircularProgress color="success" />
                }
                {error && <p className="text-danger"><CancelRounded className="text-danger" /> {error}</p>}
                {uploaded && <CheckBoxRounded className="text-success" />}
                {uploaded && <DeleteForever className="text-danger" onClick={() => { handleDelete(name) }} />}
            </div>
            {errors && errors[name] && <p className="text-danger"><InfoRounded className="text-danger" />{errors[name]?.message}</p>}
            {/* {uploaded && doc &&  <a target="_blank" href={config.app.api_url+"/documents/"+doc?.id} className="text-success">{t('Uploaded')} <i className="bi bi-download"/> </a>} */}
            {uploaded && <small className="text-success">{t('Uploaded')}</small>}
        </FormControl>
    </>
    )
}