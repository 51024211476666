import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate,useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { history } from '../../_helpers';
import { authActions } from '../../_store';
import { login, verify } from '../../actions/auth';
import FormHeader from '../../components/FormHeader';
import { CLEAR_MESSAGE } from '../../actions/types';
import { t } from 'i18next';
import SendCodeFormModal from './SendCodeFormModal';
import { Mtoast } from '../../components/utils/toast';

const Verify = () => {
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const { response } = useSelector(state => state);
    const [successful, setSuccessful] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [messageModal , setMessageModal] = React.useState('')

    useEffect(() => {
        // redirect to home if already logged in
        if (isLoggedIn) history.navigate('/login');
        dispatch({
            type: CLEAR_MESSAGE
        });
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
        code: Yup.string().required('Code is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email, code }) {
        return dispatch(verify({ email, code })).then(() => {
            setSuccessful(true);
            Mtoast('Email has been successfully verified  !')
            history.navigate("/login");

        })
            .catch(() => {
                setSuccessful(false);
            });
    }
    const [searchParams, setSearchParams] = useSearchParams();
    let  msg  =searchParams.get("msg")

    return (
        <>
            <Header />
            <div className="content p-0 m-md-3">
                <div className="container">

                    <FormHeader />
                    <div className="row justify-content-center">

                        <div className="col-md-6 border shadow my-5 bg-forms">
                            <div className="mb-4 text-center ">
                                <h3>{t('forms.emailVerification')}</h3>
                                <p className="mb-4">{t('forms.emailVerificationcode')}</p>
                                {message && (
                                    <div className="form-group">
                                        <div className={"alert alert-danger" }role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}

                                {
                                    messageModal && (<div className='alert alert-success text-center'>{messageModal}</div>)
                                }
                            </div>
                            <hr></hr>

                            <form onSubmit={handleSubmit(onSubmit)} className=' p-2 p-md-3'>
                          { msg === "successResetCode" && (  <div className='alert alert-success text-center shadow'> <i className='bi bi-info-circle'></i> {t('sendCOdeIndicator')}</div>)}
                             
                                <div className="form-group first  my-2">
                                    <label>{t('forms.email')} <span className='text-danger'>*</span> :  </label>
                                    <input type="text" {...register('email')} placeholder={t('forms.email')} name="email" className={errors.email?.message ? "form-control border-danger" :"form-control"} id="email" />
                                    <div className="text-danger">{errors.email?.message}</div>
                                </div>
                                <div className="form-group last mb-4 my-2">
                                    <label>Code <span className='text-danger'>*</span> :  </label>
                                    <input type="text" {...register('code')} placeholder='Code' name="code" className={errors.code?.message ? "form-control border-danger" :"form-control"} id="code" />
                                    <div className="text-danger">{errors.code?.message}</div>
                                </div>
                                {isSubmitting && <div id="preloader"></div>}

                                <div className='row justify-content-center'>
                                    <div className='col-8 text-center my-4'>
                                        <input type="submit" value="Valider" className={Object.values(errors).length ===0 ? "btn btn-block bg-warning text-white" : "btn btn-outline-danger disabled" }  />

                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>

                                <SendCodeFormModal setMessageModal={setMessageModal}/>
                                </div>
                            
                            </form>
                        </div>
                    </div>


                </div>
            </div>
            <Footer />
        </>
    )
}

export default Verify;