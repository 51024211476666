import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";

const DetailsNew = () => {
    const { id } = useParams();

    const [blog, setBlog] = useState([]);
    useEffect(() => {
        console.log(id);
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/${id}`).then(response => {
            setBlog(response.data.data)
        })
    }, []);

    return (
        <>
            <Header />
            <section className=" bg-grey">
                <div className="container">
                    {blog &&
                        (<div key={blog.id} className="row  shadow-sm">
                            <div className="col-md-6">
                                <div className="">
                                    <img src={`http://ework-admin.eycrecruitment.org/public/${blog.image}`} className="rounded mb-lg-3" alt="blog post"
                                        style={{
                                            width: "100%",
                                            height: "400px",
                                            borderRadius: '5px'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <h2>{blog.titre}</h2>
                                    <p>{blog.description}</p>
                                    {/* <Link to="#" className="default-btn">More About Us</Link> */}
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </section>
            <Footer />
        </>
    );
}

export default DetailsNew;