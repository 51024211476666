import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Header from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import dashboardRoutes from "../routes";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import axios from "axios";
import YupPassword from 'yup-password';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Mtoast } from "../components/utils/toast";
import { toast } from "react-hot-toast";
import Footer from "../components/Footer/Footer";
import { async } from "q";
import { t } from "i18next";



export default function UserRealProfile() {
    // const { register, handleSubmit, formState } = useForm(formOptions);
    // const { errors, isSubmitting } = formState;
    const [successful, setSuccessful] = useState(false);
    const [user, setuser] = useState({});
    YupPassword(Yup);

    const [image, setImage] = useState(sidebarImage);
    const [color, setColor] = useState("black");
    const [hasImage, setHasImage] = useState(true);
    const mainPanel = useRef(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    const API_URL = `${process.env.REACT_APP_API_URL}/`;
    const validationSchema = Yup.object().shape({
        name: Yup.string(),
        // email: Yup.string().email().required('Email Field is required'),
        old_password: Yup.string(),
        password: Yup.string().optional().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            {
                excludeEmptyString : true,
                message : "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            }
            
        ),
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [isfecEndded, setisfecEndded] = useState(true);

    const [message, setMessage] = useState('')
    const fectUpdateProfile = async (toSave) => {
        setisfecEndded(false)
        setMessage('')
        // setRes({})

        fetch(API_URL + `account/update`, {
            method: 'POST',
            headers: {
                'content-type': 'aplication/json'
            },
            body: JSON.stringify(toSave)

        })
            .then(response => {
                // if (!response.ok) {
                //     throw Error(`HTTP error: ${response.status}`);
                // }
                return response.json();
            })
            .then((res) => {
                console.log(res)
                // setRes(res)
                if (res.success === true) {
                    Mtoast(`Successfuly updated!`)
                    setTimeout(() => {
                        window.location.reload(false)
                    }, 2000)
                }
                else {
                    setMessage(res?.message)
                    toast.error(res?.message)
                }
                // navigate('/login')
            })
            .catch((err) => {
                console.log(err.message)
                toast.error('Failed to update your account !')
            })
            .finally(() => {
                setisfecEndded(true)
            })
            ;
    }


    const onSubmitHandler = (data) => {
        console.log({ ...data });
        if (!data.password && !data.old_password && !data.name) {
            toast('Nothing to update')
        } else {

            let toSave = { name: user?.name, email: user?.email, old_password: data.old_password, password: data.password }
            if (data.name) toSave.name = data?.name
            if (!data.password) { delete toSave.password }
            if (!data.old_password) delete toSave.old_password

            console.log('To save :', toSave)

            fectUpdateProfile(toSave)
        }
        reset()
    };

    useEffect(() => {
        axios
            .get(API_URL + "user" + "/" + currentUser?.user?.email, {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            })
            .then((response) => {
                setSuccessful(false);
                setuser(response.data.data);
                console.log(response.data.data);
            })
            .catch((e) => {
                console.log(e)
                setSuccessful(false);
                setuser(null);
            });
    }, [])

    return (<>
        <div className="wrapper">

            <Sidebar
                color={color}
                image={hasImage ? image : ""}
                routes={dashboardRoutes}
            />
            <div className="main-panel" ref={mainPanel}>
                <Header />
                <div className="content">
                    {/* {user.numeroContribuale != null && ( */}
                    <Container fluid>
                        <form onSubmit={handleSubmit(onSubmitHandler)} >
                            <Row className="justify-content-center">
                                <Col className="col-8">
                                    {message && <div className="alert alert-danger text-center">{message}</div>}
                                    <div className="form-group">
                                        <label for="name">{t('userRealProfile.cName')} :<strong> {user?.name}</strong></label>
                                        <input className="form-control shadow-sm" type="text" name="name" id="name" placeholder={t('userRealProfile.nName')} {...register('name')} />
                                        <p className="text-danger">{errors.name?.message}</p>
                                    </div>

                                    <div className="form-group">
                                        <label for="name">Email :</label>
                                        <input className="form-control shadow-sm" type="email" value={user?.email} readOnly />
                                        {/* <input className="form-control shadow-sm" type="email" name="email" id="email" /> */}
                                        <p className="text-danger">{errors.email?.message}</p>

                                    </div>
                                    <div className="form-group">
                                        <label for="old_password">{t('userRealProfile.oPassword')}  :</label>
                                        <input className="form-control shadow-sm" type="password" name="old_password" id="old_password" {...register("old_password")} placeholder={ t('userRealProfile.oPassword') } />
                                        <p className="text-danger">{errors.old_password?.message}</p>

                                    </div>
                                    <div className="form-group">
                                        <label for="password">{t('userRealProfile.nPassword')} :</label>
                                        <input className="form-control shadow-sm" type="password" name="password" id="password" {...register("password")} placeholder={t('userRealProfile.nPassword')}   />
                                        <div className='alert alert-warning my-2 rounded shadow text-center'> <i className="bi bi-info-circle fs-6"></i> {t('passwordIndication')}</div>
                                        <p className="text-danger">{errors.password?.message}</p>

                                    </div>

                                    <div className=" d-flex justify-content-center">

                                        <button type="submit" className="btn btn-outilined btn-success shadow">{t('tabForms.save')}</button>
                                    </div>
                                </Col>


                            </Row>
                        </form>
                    </Container>
                </div>
                <Footer />
            </div>
        </div>

    </>)
}