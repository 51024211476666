import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Head from '../../components/Head';
import Header from '../../components/Header';
import VisaSteps from '../../components/VisaSteps';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Importing whatsapp floating button component
// Documentation at https://www.npmjs.com/package/react-floating-whatsapp

import { FloatingWhatsApp } from 'react-floating-whatsapp';

import { Trans, useTranslation } from 'react-i18next';
import { Mtoast } from '../../components/utils/toast';
import Tour from '../../components/utils/Tour';



const Home = () => {
  

    return (
        <>
            <Header />
            <Head />
            <Tour/>
            <main id="main">
                <section id="services" className="services sections-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>
                                <Trans i18nKey="services.title" >
                                    Request and Pay for Work-Permit
                                </Trans>

                            </h2>
                            <p>
                                <Trans i18nKey="services.welcome">

                                    Welcome to the eWorkpermit platform. Foreigners seeking employment in the Republic of Cameroon must complete the online application form and submit online
                                </Trans>
                            </p>
                        </div>

                        <VisaSteps />

                    </div>
                </section>

                {/* whatsapp floating button */}
                {/* <FloatingWhatsApp
                    phoneNumber="+237 6 95 34 65 75"
                    accountName="MINEFOP"
                    avatar="assets/img/logo.jpeg"
                    allowEsc
                    allowClickAway
                    notification
                    notificationSound
                /> */}
            </main>
            <Footer />
        </>
    )
}

export default Home;