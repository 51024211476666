import React, { useEffect, useState } from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { Trans } from "react-i18next";

import { t } from "i18next"
import { DOCUMENTS, DOCUMENT_NUMBER } from "../../GLOBAL_VARIABLE";
import FileUploader from "../from/FileUploader";
const Tab3 = ({ setIsDocFull, isDocFull, prev, documents, next, data, set, errors, demandeVisaId, demandeVisa, setDemandeVisa }) => {
    // console.log("🚀 ~ Tab3 ~ documents:", documents)

    // const [files, setFiles] = React.useState(data?.documents);
    function check() {
        // console.log("🚀 ~ DOCUMENTS.map ~ documents:", documents)
        DOCUMENTS.map((doc) => {
            // console.log("🚀 ~ DOCUMENTS.map ~ documents[doc?.key]:", doc?.required)
            if (documents[doc?.key] == null || documents[doc?.key] == undefined || documents[doc?.key]?.length == 0) {
                // alert(true)
                setIsDocFull(true)
            } else {
                // alert(false)
                setIsDocFull(false)

            }
        })

    }
    useEffect(() => {
        // alert("change")
        // check()

    }, [data?.documents]);


    const [errorsFiles, seterrorsFiles] = useState(
        { phbp: "" },
        { phcv: "" },
        { partner1_cv: "" },
        { partner2_cv: "" },
        { partner3_cv: "" },
        { other_file: "" },

    );

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="m-0 p-0" md="12">
                        <Card className="strpied-tabled-with-hover  shadow border-0">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div className="alert alert-info w-50">
                                        <Trans i18nKey="tabForms.3.message">
                                            <p>
                                                Veuillez fournir tous les documents requis.
                                            </p>
                                            <p className="h4 text-dark">
                                                Contrat de travail, Passeport, Curriculum Vitae,
                                                Certificat médical, Casier judiciaire...
                                            </p>
                                            <p className="text-dark">
                                                Seuls les documents au format PDF sont autorisés (Taille maximale <b className="text-danger">10MB</b>)
                                            </p>
                                        </Trans>

                                    </div>
                                    <div className="w-50 ms-4">
                                        <Form>
                                            {demandeVisa?.step3 == 0 && errors.documents && <div className="alert alert-danger text-center">{errors.documents?.message}</div>}
                                            <div className="flex row-cols-2">

                                                <FileUploader
                                                    url={`/demandeVisas/${demandeVisaId}/stepThree`}
                                                    label={t('tabForms.3.contrat')}
                                                    name={"contract"}
                                                    documents={documents}
                                                    setDemandeVisa={setDemandeVisa}
                                                    // candidate_id={PROPS_DATA?.candidate_id}
                                                    required={true}

                                                    errors={errorsFiles}
                                                    delete_url={`/demandeVisas/${demandeVisaId}/stepThree/removeFiles/`}
                                                    setDocuments={set?.setDocuments}
                                                // // setphpbLoaded={setphpbLoaded}
                                                />
                                                {/* <Form.Group>
                                                <label>{t('tabForms.3.contrat')}  <span className='text-danger'>*</span></label>
                                                <Form.Control className="shadow-sm"
                                                    id="f-contract"
                                                    type="file"
                                                    onChange={(e) => handleFileContrat(e)}
                                                    accept=".pdf"
                                                ></Form.Control>
                                            </Form.Group> */}
                                                <FileUploader
                                                    url={`/demandeVisas/${demandeVisaId}/stepThree`}
                                                    delete_url={`/demandeVisas/${demandeVisaId}/stepThree/removeFiles/`}
                                                    label={t('tabForms.3.passeport')}
                                                    name={"passport"}
                                                    documents={data?.documents}
                                                    // candidate_id={PROPS_DATA?.candidate_id}
                                                    required={true}
                                                    errors={errorsFiles}
                                                    setDocuments={set?.setDocuments}
                                                    setDemandeVisa={setDemandeVisa}
                                                // // setphpbLoaded={setphpbLoaded}
                                                />
                                                {/* <Form.Group>
                                                <label>{t('tabForms.3.passeport')}  <span className='text-danger'>*</span></label>
                                                <Form.Control className="shadow-sm"
                                                    id="f-passport"
                                                    type="file"
                                                    onChange={(e) => handleFilePassport(e)}
                                                    accept=".pdf"
                                                ></Form.Control>
                                            </Form.Group> */}
                                                <FileUploader
                                                    url={`/demandeVisas/${demandeVisaId}/stepThree`}
                                                    label={t('tabForms.3.visa')}
                                                    name={"visa"}
                                                    documents={data?.documents}
                                                    // candidate_id={PROPS_DATA?.candidate_id}
                                                    required={false}
                                                    errors={errorsFiles}
                                                    delete_url={`/demandeVisas/${demandeVisaId}/stepThree/removeFiles/`}
                                                    setDocuments={set?.setDocuments}
                                                    // // setphpbLoaded={setphpbLoaded}
                                                    setDemandeVisa={setDemandeVisa}
                                                />
                                                {/* <Form.Group>
                                                <label>{t('tabForms.3.visa')}
                                                </label>
                                                <Form.Control className="shadow-sm"
                                                    id="f-visa"
                                                    type="file"
                                                    onChange={(e) => handleFileVisa(e)}
                                                    accept=".pdf"
                                                ></Form.Control>
                                            </Form.Group> */}

                                                <FileUploader
                                                    url={`/demandeVisas/${demandeVisaId}/stepThree`}
                                                    label={t('tabForms.3.cv')}
                                                    name={"cv"}
                                                    documents={data?.documents}
                                                    // candidate_id={PROPS_DATA?.candidate_id}
                                                    required={true}
                                                    errors={errorsFiles}
                                                    delete_url={`/demandeVisas/${demandeVisaId}/stepThree/removeFiles/`}
                                                    setDocuments={set?.setDocuments}
                                                    // // setphpbLoaded={setphpbLoaded}
                                                    setDemandeVisa={setDemandeVisa}
                                                />

                                                {/* <Form.Group>
                                                <label>{t('tabForms.3.cv')} <span className='text-danger'>*</span></label>
                                                <Form.Control className="shadow-sm"
                                                    id="f-cv"
                                                    type="file"
                                                    onChange={(e) => handleFileCv(e)}
                                                    accept=".pdf"
                                                ></Form.Control>
                                            </Form.Group> */}

                                                <FileUploader
                                                    url={`/demandeVisas/${demandeVisaId}/stepThree`}
                                                    label={t('tabForms.3.medical')}
                                                    name={"medical"}
                                                    documents={data?.documents}
                                                    // candidate_id={PROPS_DATA?.candidate_id}
                                                    required={false}
                                                    errors={errorsFiles}
                                                    delete_url={`/demandeVisas/${demandeVisaId}/stepThree/removeFiles/`}
                                                    setDocuments={set?.setDocuments}
                                                    setDemandeVisa={setDemandeVisa}
                                                // // setphpbLoaded={setphpbLoaded}
                                                />
                                                {/* <Form.Group>
                                                <label>{t('tabForms.3.medical')} <span className='text-danger'>*</span></label>
                                                <Form.Control className="shadow-sm"
                                                    id="f-medical"
                                                    type="file"
                                                    onChange={(e) => handleFileMedical(e)}
                                                    accept=".pdf"
                                                ></Form.Control>
                                            </Form.Group> */}
                                                <FileUploader
                                                    url={`/demandeVisas/${demandeVisaId}/stepThree`}
                                                    label={t('tabForms.3.criminal')}
                                                    name={"criminal"}
                                                    documents={data?.documents}
                                                    // candidate_id={PROPS_DATA?.candidate_id}
                                                    required={false}
                                                    errors={errorsFiles}
                                                    delete_url={`/demandeVisas/${demandeVisaId}/stepThree/removeFiles/`}
                                                    setDocuments={set?.setDocuments}
                                                    // // setphpbLoaded={setphpbLoaded}
                                                    setDemandeVisa={setDemandeVisa}
                                                />

                                                {/* <Form.Group>
                                                <label>{t('tabForms.3.criminal')} <span className='text-danger'>*</span></label>
                                                <Form.Control className="shadow-sm"
                                                    id="f-criminal"
                                                    type="file"
                                                    onChange={(e) => handleFileCriminal(e)}
                                                    accept=".pdf"
                                                ></Form.Control>
                                            </Form.Group> */}

                                            </div>

                                        </Form>

                                    </div>
                                </div>

                            </Card.Body>
                            <Card.Footer className="table-full-width table-responsive px-0">
                                {/* <Table className="table-hover table-striped">
                                    <thead>
                                        <tr className="fw-bold text-dark">
                                            <th className="border-0"> {t('tabForms.3.docName')} </th>
                                            <th className="border-0">{t('tabForms.3.action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <p className="d-none"> {s}</p>
                                        <TabList files={files} removeByName={removeByName} />
                                    </tbody>
                                </Table> */}
                                <div className=" d-flex justify-content-between mt-5 pl-2 px-3" md="2">
                                    <Button
                                        className="btn-fill pull-left  shadow border-0 shadow border-0"
                                        onClick={() => prev()}
                                        variant="success"
                                        style={{
                                            backgroundColor: "#008374"
                                        }}
                                    >
                                        {t('tabForms.prev')}
                                    </Button>

                                    <Button
                                        className="btn-fill pull-right  shadow border-0 shadow border-0"
                                        onClick={() => next(3)}
                                        variant="success"
                                        style={{
                                            backgroundColor: "#008374"
                                        }}
                                    // disabled={isDocFull}
                                    >
                                        {t("tabForms.save")} & {t("tabForms.next")}

                                    </Button>

                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

// function TabList({ files, removeByName, isDocFull}) {
//     return (<>
//         {files.map((doc) => {
//             return (<>
//                 <tr id={doc?.id}>
//                     <td>{doc?.name}</td>
//                     <td>
//                         <Button
//                             className="btn-fill pull-right  shadow border-0"
//                             onClick={() => removeByName(doc?.id)}
//                             variant="warning"
//                             disabled={isDocFull}
//                         >
//                             <i className="bi bi-trash"></i>
//                         </Button>
//                     </td>
//                 </tr>
//             </>)
//         })}
//     </>)
// }
export default Tab3;
